import Typography from "@mui/material/Typography";
import { DelegateDetails } from "./DelegateDetails";
import { DelegateInfoDetails } from "./DelegateInfo";
import { Divider } from "@mui/material";

export const DelegationSizeDetails = (props) => {
  const { trip, delegates, depRet } = props;

  const days =
    (new Date(depRet.date_of_return).getTime() -
      new Date(depRet.date_of_dep).getTime()) /
    (1000 * 3600 * 24);
  const days_rounded =
    days.toString().charAt(1) === "."
      ? parseInt(days.toString().charAt(0)) + 1
      : days;

  const exchange_rate = trip?.accomo_cost / trip?.gok_cost / days_rounded;

  const tot_cost = [
    // parseInt(trip.accomo_cost) +
    parseInt(trip.ticket_cost) + parseInt(trip.participant_cost),
  ];

  const tot_cost_kes = [
    parseInt(trip.accomo_cost) +
      parseInt(trip.ticket_cost) +
      parseInt(trip.participant_cost),
  ];

  const tot_cost_dollars = [parseInt(trip.accomo_cost)];

  // Pushed to total expenses in ksh
  const all_del_cost = parseInt(
    delegates.map((delegate) =>
      tot_cost.push(
        // parseInt(delegate.accomo_cost * exchange_rate) +
        parseInt(delegate.ticket_cost) + parseInt(delegate.participant_cost)
      )
    )
  );

  // Pushed to total expenses in dollars
  const all_del_cost2 = parseInt(
    delegates.map((delegate) =>
      tot_cost_dollars.push(
        parseInt(delegate.accomo_cost * exchange_rate)
        // parseInt(delegate.ticket_cost) + parseInt(delegate.participant_cost)
      )
    )
  );

  // console.log(
  //   "tot_cost_dollars",
  //   tot_cost_dollars.reduce((acc, current) => acc + current, 0)
  // );

  // Pushed to Grand total expenses in ksh
  const all_del_cost1 = parseInt(
    delegates.map((delegate) =>
      tot_cost_kes.push(
        parseInt(delegate.accomo_cost * exchange_rate) +
          parseInt(delegate.ticket_cost) +
          parseInt(delegate.participant_cost)
      )
    )
  );

  const sums = tot_cost.reduce((a, x, i) => [...a, x + (i && a[i - 1])], []);
  const sumsKES = tot_cost_kes.reduce(
    (a, x, i) => [...a, x + (i && a[i - 1])],
    []
  );
  const sums_dollars = tot_cost_dollars.reduce(
    (a, x, i) => [...a, x + (i && a[i - 1])],
    []
  );

  return (
    <div>
      <div className="py-14">
        <Typography
          component="h5"
          variant="p"
          className="text-md sm:text-lg font-semibold"
        >
          Costs and Size of Delegation
        </Typography>
      </div>

      <Typography
        variant="p"
        component="p"
        className="text-xs font-normal py-8"
      >
        SRC rates for {trip.country} in U.S ${trip.gok_cost}
      </Typography>

      <Typography component="p" variant="p" className="text-xs font-bold py-8">
        Are you traveling alone? If not indicate the number of officers/other
        persons accompanying you for the trip.
      </Typography>

      <Typography
        component="p"
        variant="p"
        className="pl-4 py-4 sm:pl-14 sm:py-8"
      >
        {delegates.length}
      </Typography>

      <Typography
        component="p"
        variant="body2"
        className="text-xs font-small py-8"
      >
        (This should include all ministerial/state corporation/agency officers
        attending the same event, meeting, conference, seminar, workshop etc)
      </Typography>
      <Divider className="py-2 my-2" />

      <div className="py-14">
        <Typography
          component="h5"
          variant="p"
          className="text-md sm:text-lg font-semibold"
        >
          Cost of the Trip and Sponsorship for APPLICANT:
        </Typography>
      </div>

      {trip.hops_checking_confirm === "not_confirmed" ? (
        <Typography
          component="p"
          variant="p"
          className="text-xs font-semibold py-6"
        >
          Subsistence/Accommodation in U.S $
          <span className="pl-10 text-xs font-normal">
            {parseInt(trip.accomo_cost).toLocaleString()}
          </span>
        </Typography>
      ) : (
        <Typography
          component="p"
          variant="p"
          className="text-xs font-semibold py-6"
        >
          Subsistence/Accommodation in KES
          <span className="pl-10 text-xs font-normal">
            {parseInt(trip.accomo_cost).toLocaleString()}
          </span>
        </Typography>
      )}

      <Typography
        component="p"
        variant="p"
        className="text-xs font-semibold py-6"
      >
        Proposed ticket/ top up cost in Ksh
        <span className="pl-10 text-xs font-normal">
          {parseInt(trip.ticket_cost).toLocaleString()}
        </span>
      </Typography>

      <Typography
        component="p"
        variant="p"
        className="text-xs font-semibold py-6"
      >
        Participation Fee (where applicable) in Ksh
        <span className="pl-10 text-xs font-normal">
          {parseInt(trip.participant_cost).toLocaleString()}
        </span>
      </Typography>

      <Typography
        component="p"
        variant="p"
        className="text-xs font-semibold py-6"
      >
        Number of Days:
        <span className="pl-10 text-xs font-normal">{days_rounded}</span>
      </Typography>

      {trip.hops_checking_confirm === "not_confirmed" ? (
        <div className="py-12">
          <Typography
            component="p"
            variant="p"
            className="text-md font-normal font-semibold"
          >
            Total participation fee & top up ticket cost in KSH.
            <span className="pl-10 text-md font-semibold">
              {
                // parseInt(trip.accomo_cost) +
                (
                  parseInt(trip.ticket_cost) + parseInt(trip.participant_cost)
                ).toLocaleString()
              }
            </span>
          </Typography>
          <Typography
            component="p"
            variant="p"
            className="text-md font-normal font-semibold"
          >
            Total Subsistence/Accommodation Cost in U.S $.
            <span className="pl-10 text-md font-semibold">
              {parseInt(trip.accomo_cost)
                // parseInt(trip.ticket_cost) +
                // parseInt(trip.participant_cost)
                .toLocaleString()}
            </span>
          </Typography>
        </div>
      ) : (
        <div className="py-12">
          <Typography
            component="p"
            variant="p"
            className="text-md font-normal font-semibold"
          >
            Total Cost in KES.
            <span className="pl-10 text-md font-semibold">
              {(
                parseInt(trip.accomo_cost) +
                parseInt(trip.ticket_cost) +
                parseInt(trip.participant_cost)
              ).toLocaleString()}
            </span>
          </Typography>
        </div>
      )}

      <Typography
        component="p"
        variant="p"
        className="text-xs font-semibold py-8"
      >
        Job Group/Grade equivalent:
      </Typography>

      {trip.user && (
        <>
          <Typography
            component="p"
            variant="p"
            className="pl-4 py-4 sm:pl-14 sm:py-8"
          >
            {trip.user.job_group}
          </Typography>
          <Divider className="py-2 my-2" />

          <Typography
            component="p"
            variant="p"
            className="text-xs font-semibold py-8"
          >
            Role that the applicant is playing during the visit:
          </Typography>

          <Typography
            component="p"
            variant="p"
            className="pl-4 py-4 sm:pl-14 sm:py-8"
          >
            {trip.user.designation}
          </Typography>
        </>
      )}

      <div className="py-14">
        <Typography
          component="h5"
          variant="p"
          className="text-md sm:text-lg font-semibold"
        >
          Cost of the Trip and Sponsorship for DELEGATION:
        </Typography>
      </div>

      {delegates &&
        delegates.map((delegate) => {
          return (
            <div key={delegate.delegate_id}>
              <div className="py-8">
                <DelegateInfoDetails delegate={delegate} />
              </div>

              <Divider className="py-2 my-2" />

              <div className="py-8">
                <DelegateDetails
                  exchange_rate={exchange_rate}
                  trip={trip}
                  days_rounded={days_rounded}
                  delegate={delegate}
                />
              </div>
            </div>
          );
        })}

      <div className="py-14">
        <Typography
          component="h5"
          variant="p"
          className="text-md sm:text-lg font-semibold"
        >
          Total Cost of the Trip and Sponsorship for Entire Delegation:
        </Typography>
      </div>

      {trip.hops_checking_confirm === "not_confirmed" ? (
        <div>
          <Typography
            component="p"
            variant="p"
            className="pl-4 py-4 text-md  font-bold"
          >
            Grand Total expenses: KSH.
            <span className="pl-6 text-md">
              {sums[sums.length - 1].toLocaleString()}
            </span>
          </Typography>
          <Typography
            component="p"
            variant="p"
            className="pl-4 py-4 text-md  font-bold"
          >
            Total Subsistence/Accommodation Cost in U.S $.
            <span className="pl-6 text-md font-semibold">
              {tot_cost_dollars
                .reduce((acc, current) => acc + current, 0)
                .toLocaleString()}
              {/* {sums_dollars[sums_dollars.length - 1].toLocaleString()} */}
            </span>
          </Typography>
        </div>
      ) : (
        <Typography
          component="p"
          variant="p"
          className="pl-4 py-4 text-md  font-bold"
        >
          Grand Total expenses: KSH.
          <span className="pl-6 text-md">
            {sumsKES[sumsKES.length - 1].toLocaleString()}
          </span>
        </Typography>
      )}
    </div>
  );
};
