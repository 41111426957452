import { Button, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import ApprovalIcon from "@mui/icons-material/Approval";
import format from "date-fns/format";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import NavLinkAdapter from "../../components/NavLinkAdapter";
import { backend_url } from "../../components/Constants";

export const FormHeaderDetail = (props) => {
  const { trip, cs_confirm, chief_confirm, chairperson_confirm } = props;

  return (
    <div className="mt-16">
      <Typography
        variant="p"
        component="h6"
        className="text-md sm:text-lg  font-semibold"
      >
        FOR OFFICIAL USE:
      </Typography>
      <Typography component="p" variant="body2" className="text-xs font-small">
        (To be checked before acceptance for processing)
      </Typography>

      <div className="grid grid-cols-12 gap-x-4 my-8 text-xs sm:text-sm font-small ">
        <div className="col-span-12 " />

        <Typography
          variant="p"
          className="col-span-8 self-center tracking-tight"
        >
          {" "}
          Date Application submitted:{" "}
        </Typography>
        <Typography
          variant="p"
          className="col-span-4 text-right text-xs font-semibold"
        >
          {format(new Date(trip.created_at), "dd-MMM-yyyy")}
          {/* {format(new Date(trip.program_from), 'dd-MMM-yyyy')} */}
        </Typography>

        <div className="col-span-12" />
        <Typography
          variant="p"
          className="col-span-10 self-center tracking-tight"
        >
          {" "}
          Time:{" "}
        </Typography>
        <Typography
          variant="p"
          className="col-span-2 text-right text-xs font-semibold"
        >
          {format(new Date(trip.created_at), "HH:mm")}
        </Typography>
      </div>
      <Divider sx={{ my: 2 }} />

      <Typography
        variant="p"
        component="h5"
        className="text-md sm:text-lg  font-semibold"
      >
        (ii) Annextures:
      </Typography>

      <div className=" my-2">
        <Typography
          variant="p"
          component="p"
          className="text-xs font-medium py-4"
        >
          Attached Documents
        </Typography>
        <div>
          <Button
            sx={{ m: 1 }}
            variant="contained"
            color="primary"
            component={NavLinkAdapter}
            to={`${backend_url}${trip.invitation_letter_up}`}
            target="_blank"
            size="small"
            startIcon={<InsertInvitationIcon fontSize="small" />}
          >
            <span className="mx-8 truncate">View Invitation Letter</span>
          </Button>

          <Button
            sx={{ m: 1 }}
            variant="contained"
            color="primary"
            component={NavLinkAdapter}
            to={`${backend_url}${trip.program_up}`}
            target="_blank"
            size="small"
            startIcon={<ReceiptLongIcon fontSize="small" />}
          >
            <span className="mx-8">View Program Letter</span>
          </Button>

          {trip.meeting_up && (
            <Button
              sx={{ m: 1 }}
              variant="contained"
              color="primary"
              component={NavLinkAdapter}
              to={`${backend_url}${trip.meeting_up}`}
              target="_blank"
              size="small"
              startIcon={<ReceiptLongIcon fontSize="small" />}
            >
              <span className="mx-8">View Gazette Notice</span>
            </Button>
          )}

          {trip.virtuality_upload && (
            <Button
              sx={{ m: 1 }}
              variant="contained"
              color="primary"
              component={NavLinkAdapter}
              to={`${backend_url}${trip.virtuality_upload}`}
              target="_blank"
              size="small"
              startIcon={<ReceiptLongIcon fontSize="small" />}
            >
              <span className="mx-8">View Virtual Letter</span>
            </Button>
          )}

          <Button
            sx={{ m: 1 }}
            variant="contained"
            color="primary"
            component={NavLinkAdapter}
            to={`${backend_url}${trip.account_up}`}
            target="_blank"
            size="small"
            startIcon={<ReceiptLongIcon fontSize="small" />}
          >
            <span className="mx-8">View Accountant Letter</span>
          </Button>

          <Button
            sx={{ m: 1 }}
            variant="contained"
            color="primary"
            component={NavLinkAdapter}
            to={`${backend_url}${trip.procument_up}`}
            target="_blank"
            size="small"
            startIcon={<ReceiptLongIcon fontSize="small" />}
          >
            <span className="mx-8">View Procurement Letter</span>
          </Button>
        </div>
      </div>

      <div className=" mb-4">
        <Typography
          variant="p"
          component="p"
          className="text-xs font-medium py-4"
        >
          Concurrence
        </Typography>
        <div>
          {/* {chairperson_confirm === undefined ? (
            <Button
              sx={{ m: 1 }}
              variant="contained"
              color={
                trip.chairperson_confirm === "accepted"
                  ? "success"
                  : trip.chairperson_confirm === "rejected"
                  ? "error"
                  : "secondary"
              }
              size="small"
              startIcon={
                trip.chairperson_confirm === "accepted" ? (
                  <CheckCircleOutlineIcon fontSize="small" />
                ) : trip.chairperson_confirm === "rejected" ? (
                  <DoDisturbIcon fontSize="small" />
                ) : (
                  <ApprovalIcon fontSize="small" />
                )
              }
            >
              <span className="mx-2">
                {" "}
                {trip.chairperson_confirm === "accepted"
                  ? "Chairperson confirmed"
                  : trip.chairperson_confirm === "rejected"
                  ? "Chairperson Not Concurred"
                  : "Waiting Chairperson concur"}
              </span>
            </Button>
          ) : (
            <Button
              sx={{ m: 1 }}
              variant="contained"
              color={
                chairperson_confirm === "accepted" ? "success" : "secondary"
              }
              size="small"
              startIcon={
                chairperson_confirm === "accepted" ? (
                  <CheckCircleOutlineIcon fontSize="small" />
                ) : (
                  <ApprovalIcon fontSize="small" />
                )
              }
            >
              <span className="mx-2">
                {" "}
                {chairperson_confirm === "accepted"
                  ? "Chairperson confirmed"
                  : chairperson_confirm === "rejected"
                  ? "Chairperson Not Concurred"
                  : " Waiting Chairperson confirm"}
              </span>
            </Button>
          )} */}

          {/* {cs_confirm === undefined ? (
            <Button
              sx={{ m: 1 }}
              variant="contained"
              color={
                trip.cs_confirm === "accepted"
                  ? "success"
                  : trip.cs_confirm === "rejected"
                  ? "error"
                  : "secondary"
              }
              size="small"
              startIcon={
                trip.cs_confirm === "accepted" ? (
                  <CheckCircleOutlineIcon fontSize="small" />
                ) : trip.cs_confirm === "rejected" ? (
                  <DoDisturbIcon fontSize="small" />
                ) : (
                  <ApprovalIcon fontSize="small" />
                )
              }
            >
              <span className="mx-2">
                {" "}
                {trip.cs_confirm === "accepted"
                  ? "CS confirmed"
                  : trip.cs_confirm === "rejected"
                  ? "CS Not Concurred"
                  : "Waiting CS confirm"}
              </span>
            </Button>
          ) : (
            <Button
              sx={{ m: 1 }}
              variant="contained"
              color={cs_confirm === "accepted" ? "success" : "secondary"}
              size="small"
              startIcon={
                cs_confirm === "accepted" ? (
                  <CheckCircleOutlineIcon fontSize="small" />
                ) : (
                  <ApprovalIcon fontSize="small" />
                )
              }
            >
              <span className="mx-2">
                {" "}
                {cs_confirm === "accepted"
                  ? "CS confirmed"
                  : cs_confirm === "rejected"
                  ? "CS Not Concurred"
                  : " Waiting CS confirm"}
              </span>
            </Button>
          )} */}

          {chief_confirm === undefined ? (
            <Button
              sx={{ m: 1 }}
              variant="contained"
              color={
                trip.chief_confirm === "rejected" ||
                trip.he_confirm === "rejected"
                  ? "error"
                  : trip.chief_confirm === "accepted"
                  ? "success"
                  : "secondary"
              }
              size="small"
              startIcon={
                trip.chief_confirm === "rejected" ||
                trip.he_confirm === "rejected" ? (
                  <DoDisturbIcon fontSize="small" />
                ) : trip.chief_confirm === "accepted" ? (
                  <CheckCircleOutlineIcon fontSize="small" />
                ) : (
                  <ApprovalIcon fontSize="small" />
                )
              }
            >
              <span className="mx-2">
                {" "}
                {trip.chief_confirm === "rejected" ||
                trip.he_confirm === "rejected"
                  ? "Application not approved"
                  : trip.chief_confirm === "accepted"
                  ? "Approved"
                  : "Awaiting Approval"}
              </span>
            </Button>
          ) : (
            <Button
              sx={{ m: 1 }}
              variant="contained"
              color={chief_confirm === "accepted" ? "success" : "secondary"}
              size="small"
              startIcon={
                chief_confirm === "accepted" ? (
                  <CheckCircleOutlineIcon fontSize="small" />
                ) : (
                  <ApprovalIcon fontSize="small" />
                )
              }
            >
              <span className="mx-2">
                {" "}
                {chief_confirm === "accepted"
                  ? "Approved"
                  : chief_confirm === "rejected"
                  ? "Application not approved"
                  : " Awaiting Approval"}
              </span>
            </Button>
          )}

          {chief_confirm !== undefined && (
            <>
              {trip.reviewer === false ? (
                <Button
                  sx={{ m: 1 }}
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<ApprovalIcon fontSize="small" />}
                >
                  <span className="mx-2">Awaiting Approval</span>
                </Button>
              ) : (
                <Button
                  sx={{ m: 1 }}
                  variant="contained"
                  color="success"
                  size="small"
                  startIcon={<CheckCircleOutlineIcon fontSize="small" />}
                >
                  <span className="mx-2">Approved to travel.</span>
                </Button>
              )}
            </>
          )}
        </div>
      </div>

      {/* <div className="items-left mb-4">
        <Typography
          variant="p"
          component="p"
          className="text-xs font-medium py-4"
        >
          Copied to Ministry of Foreign and Diaspora Affairs
        </Typography>

        <Button
          sx={{ m: 1 }}
          variant="contained"
          size="small"
          color="success"
          startIcon={<CheckCircleOutlineIcon fontSize="small" />}
        >
          <span className="mx-2">Yes</span>
        </Button>
      </div> */}
    </div>
  );
};
