import { marked } from 'marked';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const Terms = () => {

    const [state, setState] = useState('');
    useEffect(() => {
        const readmePath = require('./terms_and_conditions.md');

        fetch(readmePath)
            .then((response) => {
                return response.text();
            })
            .then((text) => {
                setState(marked.parse(text))
            });
    });

    return (
        <div className="flex flex-col items-center p-24 sm:p-40">
            <div className="flex flex-col w-full max-w-4xl">
                <div className="mt-16 sm:mt-32">
                    <Button
                        component={Link}
                        to={-1}
                        color="secondary"
                        startIcon={<ArrowBackIcon />}
                    >
                        Back
                    </Button>
                </div>
                <div className="mt-8 text-xl sm:text-2xl font-extrabold tracking-tight leading-tight">
                    Terms and conditions
                </div>

                <div className="mt-6 sm:mt-24  leading-tight tracking-tight" dangerouslySetInnerHTML={{ __html: state }} />
            </div>
        </div>
    );
}

export default Terms;
