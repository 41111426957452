import { motion } from 'framer-motion';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const container = {
  show: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const item = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 },
};

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  margin: 0,
  border: 'none!important',
  borderRadius: '8px!important',
  marginBottom: 24,
  '&:before': {
    display: 'none',
  },
  '&:first-of-type': {},
  '&:last-of-type': {
    marginBottom: 0,
  },
}));

function FaqList(props) {
  const { className } = props;

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      className={clsx('', className)}
    >
      <StyledAccordion
        component={motion.div}
        variants={item}
        classes={{
          root: 'FaqPage-panel shadow',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="flex items-center py-4">
            <QuestionMarkIcon olor="action" />
            <Typography variant='p' className="px-12 font-medium">
              What is a travel clearance system?
            </Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails className='sm:mx-24'>
          <Typography variant='p' className="pb-8 -mt-8">
            The travel clearance system is a foreign travel clearance system by the Chief of Staff and Head of the Public Service (COS-HOPS) to facilitate travel clearance for cabinet secretaries, chief administrative secretaries, principal secretaries, chairpersons, and chief executives of state corporations.
          </Typography>
          <br /> <br /> <Typography variant='p' className=" pb-8 -mt-8">
            The system is developed as per the requirements of <b> Circular No. OP/CAB.304/018-29th June 2023,</b> and in compliance with the ICT Act and other relevant laws and regulations in the country.
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        component={motion.div}
        variants={item}
        classes={{
          root: 'FaqPage-panel shadow',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="flex items-center py-4">
            <QuestionMarkIcon olor="action" />
            <Typography variant='p' className="px-12 font-medium">
              Why do I need to use the travel clearance system?
            </Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails className='sm:mx-24'>
          <Typography variant='p' className=" pb-8 -mt-8">
            The travel clearance system has been developed to enhance efficiency and create a seamless process when seeking clearance for foreign travel. This ensures that all foreign travel applications by senior government officials are in line with the requirements of Circular No. OP/CAB.304/018-29th June 2023.
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        component={motion.div}
        variants={item}
        classes={{
          root: 'FaqPage-panel shadow',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="flex items-center py-4">
            <QuestionMarkIcon olor="action" />
            <Typography variant='p' className="px-12 font-medium">
              What are the eligibility criteria for using the Foreign Travel Clearance System?
            </Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails className='sm:mx-24'>
          <Typography variant='p' className=" pb-8 -mt-8">
            The system caters for senior-government officials and their accompanying delegates who have been selected to represent their office in a foreign country in an official capacity. The main applicants are:
          </Typography>
          <ul>
            <Typography paragraph={false} className=" pb-8 -mt-8">
              <li>Cabinet Secretaries (CS)</li>
              <li>Chief Administrative Secretaries (CAS)</li>
              <li>Principal Secretaries (PS)</li>
              <li>Chairpersons and </li>
              <li>Chief Executives Officers (CEO)</li>
            </Typography>
          </ul>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        component={motion.div}
        variants={item}
        classes={{
          root: 'FaqPage-panel shadow',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="flex items-center py-4">
            <QuestionMarkIcon olor="action" />
            <Typography variant='p' className="px-12 font-medium">
              How do you register on the travel clearance system?
            </Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails className='sm:mx-24'>
          <Typography variant='p' className=" pb-8 -mt-8">
            To access the system, you have to go through a registration/self-onboarding process. You will need a computer or a device that is connected to internet. Navigate to <b> travelsystem.go.ke</b>, and click on the signup icon where you will be required to register as an individual.
          </Typography>
          <br /> <br /> <Typography variant='p' className=" pb-8 -mt-8">
            The following are the requirements for applicants:
          </Typography>
          <ol>
            <Typography paragraph={false} className=" pb-8 -mt-8">
              <li>Official email address (Gmail, Yahoo, Hotmail, and Outlook email accounts DO NOT apply). The following emails will be acceptable by the system:</li>
              <ul>
                <li>. go.ke – for government entities.</li>
                <li>. co.ke – for companies.</li>
                <li>. ac.ke – for institutions of higher learning.</li>
              </ul>
              <li>Ministry/State Department/ Institution the applicants hail from</li>
              <li>Designation</li>
              <li>Personal number</li>
              <li>Phone number</li>
              <li>A strong password</li>
              <li>Acceptance of the terms and conditions</li>
            </Typography>
          </ol>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        component={motion.div}
        variants={item}
        classes={{
          root: 'FaqPage-panel shadow',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="flex items-center py-4">
            <QuestionMarkIcon olor="action" />
            <Typography variant='p' className="px-12 font-medium">
              How do I log in and navigate through the Travel clearance system after registration?
            </Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails className='sm:mx-24'>
          <Typography variant='p' className=" pb-8 -mt-8">
            After successfully registering to the system, you'll be directed to the sign-in page where you will be required to enter your personal number and password. A one-time password (OTP) will be sent to your official phone number. Enter the OTP in the field provided and click sign in.
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        component={motion.div}
        variants={item}
        classes={{
          root: 'FaqPage-panel shadow',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="flex items-center py-4">
            <QuestionMarkIcon olor="action" />
            <Typography variant='p' className="px-12 font-medium">
              How secure is it?
            </Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails className='sm:mx-24'>
          <Typography variant='p' className=" pb-8 -mt-8">
            We have adopted comprehensive security measures to safeguard your use of the Foreign Travel Clearance system.
          </Typography>
          <ul>
            <Typography paragraph={false} className=" pb-8 -mt-8">
              <li>2-Factor Authentication – All log-ins require a 2-factor authentication process. This means that we will send a temporary one-time passcode (OTP) to your registered mobile number in order for you to access your account. This process ensures that only you, as the account holder, have access to your account.</li>
              <li>Automatic Logout – To prevent unauthorized access to your PC while it is unattended, we have implemented a time-out feature. Your login session will be terminated if the browser is left idle for a while.</li>
            </Typography>
          </ul>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        component={motion.div}
        variants={item}
        classes={{
          root: 'FaqPage-panel shadow',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="flex items-center py-4">
            <QuestionMarkIcon olor="action" />
            <Typography variant='p' className="px-12 font-medium">
              What are the dashboard features?
            </Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails className='sm:mx-24'>
          <ul>
            <Typography paragraph={false} className=" pb-8 -mt-8">
              <li>Profile icon: This displays information on the user and it includes: the name of the person, their profile picture, designation, ministry /state department/ institution, and their email address.</li>
              <li>Edit profile icon: It enables a user to make changes to their profile.</li>
              <li>Home icon: This icon enables a user to navigate back to the home dashboard and have access to the summary of all the applications, the number of trips made, the delegation, and the cities travelled previously.</li>
              <li>New travel application: This icon enables users to make a new travel application</li>
              <li>Search icon: It enables a user to search the various travel forms. </li>
              <li> Notification icon: It displays all the unread notifications sent. </li>
              <li> Sign-out icon: It enables a user to log out of the system </li>
              <li> Travels icon: It gives a summary of the dates of the previous trip(s), the current trip if any, and any pending applications.  </li>
              <li> Uploads icon: It displays all the attachments in .pdf or .jpg file format that have been uploaded by an applicant throughout the history of their use.  </li>
            </Typography>
          </ul>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        component={motion.div}
        variants={item}
        classes={{
          root: 'FaqPage-panel shadow',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="flex items-center py-4">
            <QuestionMarkIcon olor="action" />
            <Typography variant='p' className="px-12 font-medium">
              How do I make an application?
            </Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails className='sm:mx-24'>
          <Typography variant='p' className=" pb-8 -mt-8">
            Navigate to the new travel application icon, click on the icon, and fill in the details in the forms that will be provided on the applicant’s dashboard. Only COMPLETE forms shall prompt the user to Submit for approval. Any incomplete forms will be stored awaiting completion and submission the next time the applicant logs into the system again.
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        component={motion.div}
        variants={item}
        classes={{
          root: 'FaqPage-panel shadow',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="flex items-center py-4">
            <QuestionMarkIcon olor="action" />
            <Typography variant='p' className="px-12 font-medium">
              Is there a deadline for making an application?
            </Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails className='sm:mx-24'>
          <Typography variant='p' className=" pb-8 -mt-8">
            The travel clearance application shall be submitted on or before seven (7) working days before the date of travel.
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        component={motion.div}
        variants={item}
        classes={{
          root: 'FaqPage-panel shadow',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="flex items-center py-4">
            <QuestionMarkIcon olor="action" />
            <Typography variant='p' className="px-12 font-medium">
              What are the requirements for an application to be successful?
            </Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails className='sm:mx-24'>
          <ol>
            <Typography paragraph={false} className=" pb-8 -mt-8">
              <li> <b>Personal details: </b>  Name, Personal number, Ministry /state department/ Institution, passport number, </li>
              <li> <b>Uploads: </b> These are the attached documents that will be provided by the applicants to facilitate the approval process. These uploads are:
                <ul>
                  <li>Invitation letter to the event/meeting. </li>
                  <li>A program of the proposed meeting/event/activity etc., highlighting the dates when participation is required; </li>
                </ul>
                <b> Note: </b> Benchmarking foreign visit applications should be accompanied by a gazette notice of the task force or the working committee to which travel is related
              </li>
              <li> <b>Delegations list: </b> Full list of ministry/agency officials forming a part of the delegation and their personal details with respect to the required size of the delegation as stipulated in the circular:
                <ul>
                  <li>The Cabinet Secretary has a maximum of 3 delegates in a trip  </li>
                  <li> CAS/PS has a maximum of 2 delegates in a trip </li>
                  <li>  Chairpersons and CEOs have a maximum of 2 delegates in a trip</li>
                </ul>
              </li>
              <li> <b>Departure and Return Details: </b> The date and time of departure, mode of travel, and mode of travel (if it is by air indicate the class of the air ticket) + return reports </li>
            </Typography>
          </ol>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        component={motion.div}
        variants={item}
        classes={{
          root: 'FaqPage-panel shadow',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="flex items-center py-4">
            <QuestionMarkIcon olor="action" />
            <Typography variant='p' className="px-12 font-medium">
              Who is a delegate?
            </Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails className='sm:mx-24'>
          <Typography variant='p' className=" pb-8 -mt-8">
            A delegate is the most relevant technical person to assist the applicant in the meetings, deliberations, or presentations related to that particular foreign travel. Personal Assistants and security personnel will not be approved for travel, save for assistants to persons living with disabilities. <b> Note: </b>
          </Typography>
          <ul>
            <Typography paragraph={false} className=" pb-8 -mt-8">
              <li> Delegations headed by Cabinet Secretaries shall not exceed four persons including the Cabinet Secretary as Head of Delegation (1+3);</li>
              <li> Delegations headed by Chief Administrative Secretaries, Principal Secretaries, Chairpersons and Chief Executive Officers of State Corporations shall not exceed three persons, including the Head of Delegation (1+2).
              </li>
            </Typography>
          </ul>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        component={motion.div}
        variants={item}
        classes={{
          root: 'FaqPage-panel shadow',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="flex items-center py-4">
            <QuestionMarkIcon olor="action" />
            <Typography variant='p' className="px-12 font-medium">
              Who is an acting officer?
            </Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails className='sm:mx-24'>
          <Typography variant='p' className=" pb-8 -mt-8">
            This is the person who has been left in charge of the Ministry/ State corporation/ Institution and is to act on behalf of the applicant.
          </Typography>
          <br /> <br /> <Typography variant='p' className=" pb-8 -mt-8">
            <b> Note: </b> The acting officer cannot make an application for travel during the acting period. They must be in the country until the holder of the office is back in the country
            + The CS/PS  situation
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        component={motion.div}
        variants={item}
        classes={{
          root: 'FaqPage-panel shadow',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="flex items-center py-4">
            <QuestionMarkIcon olor="action" />
            <Typography variant='p' className="px-12 font-medium">
              What is a Sponsored Trip?
            </Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails className='sm:mx-24'>
          <Typography variant='p' className=" pb-8 -mt-8">
            A sponsored trip is an event/ meeting where all or part of the travel expenses are paid for by another entity other than the government of Kenya. The applicant will be required to provide details of the sponsors and the extent to which they will sponsor the trip.
            + Virtual meeting is given priority
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        component={motion.div}
        variants={item}
        classes={{
          root: 'FaqPage-panel shadow',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="flex items-center py-4">
            <QuestionMarkIcon olor="action" />
            <Typography variant='p' className="px-12 font-medium">
              What is the approval process?
            </Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails className='sm:mx-24'>
          <Typography variant='p' className=" pb-8 -mt-8">
            For a C. E. O; the Applicant (C. E. O.) fills out the form:
          </Typography>
          <ul>
            <Typography paragraph={false} className=" pb-8 -mt-8">
              <li> The Supply Chain office and Accounts office confirm the details on costing and airline tickets  </li>
              <li>  Chairperson approval </li>
              <li> CS approval  </li>
              <li>  HOPS approval </li>
            </Typography>
          </ul>

          <Typography variant='p' className=" pb-8 -mt-8">
            For a Board Chairperson; the Applicant (Chairperson) fills out the form:
          </Typography>
          <ul>
            <Typography paragraph={false} className=" pb-8 -mt-8">
              <li>The Supply Chain office and Accounts office confirm the details on costing and airline tickets </li>
              <li> CS approval  </li>
              <li>  HOPS approval </li>
            </Typography>
          </ul>

          <Typography variant='p' className=" pb-8 -mt-8">
            For a C.A.S.; the Applicant (C.A.S.) fills out the form:
          </Typography>
          <ul>
            <Typography paragraph={false} className=" pb-8 -mt-8">
              <li>The Supply Chain office and Accounts office confirm the details on costing and airline tickets </li>
              <li> CS approval  </li>
              <li>  HOPS approval </li>
            </Typography>
          </ul>

          <Typography variant='p' className=" pb-8 -mt-8">
            For a PS, Applicant (PS) fills out the form:
          </Typography>
          <ul>
            <Typography paragraph={false} className=" pb-8 -mt-8">
              <li>The Supply Chain office and Accounts office confirm the details on costing and airline tickets</li>
              <li> CS approval  </li>
              <li>  HOPS approval </li>
            </Typography>
          </ul>

          <Typography variant='p' className=" pb-8 -mt-8">
            For a CS, Applicant (CS) fills out the form:
          </Typography>
          <ul>
            <Typography paragraph={false} className=" pb-8 -mt-8">
              <li>The Supply Chain office and Accounts office confirm the details on costing and airline tickets</li>
              <li>  HOPS approval </li>
            </Typography>
          </ul>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        component={motion.div}
        variants={item}
        classes={{
          root: 'FaqPage-panel shadow',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="flex items-center py-4">
            <QuestionMarkIcon olor="action" />
            <Typography variant='p' className="px-12 font-medium">
              Can I track my application process?
            </Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails className='sm:mx-24'>
          <Typography variant='p' className=" pb-8 -mt-8">
            Yes, you can check the state of your application if it is concurred or not. If the case is the latter, reasons why will be given and you can reapply.
          </Typography>
          <br /> <br /> <Typography variant='p' className=" pb-8 -mt-8">
            The notification icon on the dashboard will notify the applicant of any updates at each step of the application process.
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        component={motion.div}
        variants={item}
        classes={{
          root: 'FaqPage-panel shadow',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="flex items-center py-4">
            <QuestionMarkIcon olor="action" />
            <Typography variant='p' className="px-12 font-medium">
              What are the maximum travel days?
            </Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails className='sm:mx-24'>
          <Typography variant='p' className=" pb-8 -mt-8">
            The travel duration should not exceed 7 days in a row (excluding travel days). The total count of travel days should not exceed 15 days in any Quarter of a financial year and not exceed 45 days in a financial year.
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        component={motion.div}
        variants={item}
        classes={{
          root: 'FaqPage-panel shadow',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="flex items-center py-4">
            <QuestionMarkIcon olor="action" />
            <Typography variant='p' className="px-12 font-medium">
              What happens after you have an approval?
            </Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails className='sm:mx-24'>
          <Typography variant='p' className=" pb-8 -mt-8">
            The system generates a trip ID and then you get a notification notifying you of the success of your application and your approval.
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

    </motion.div>
  );
}

export default FaqList;
