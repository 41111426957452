import Typography from "@mui/material/Typography";

export const DelegateDetails = (props) => {
  const { delegate, days_rounded, trip, exchange_rate } = props;

  return (
    delegate && (
      <div>
        {trip.hops_checking_confirm === "not_confirmed" ? (
          <Typography
            component="p"
            variant="p"
            className="text-xs font-semibold py-6"
          >
            Subsistence/Accommodation in U.S $
            <span className="pl-10 text-xs font-normal">
              {parseInt(delegate.accomo_cost).toLocaleString()}
            </span>
          </Typography>
        ) : (
          <Typography
            component="p"
            variant="p"
            className="text-xs font-semibold py-6"
          >
            Subsistence/Accommodation in KES
            <span className="pl-10 text-xs font-normal">
              {parseInt(delegate.accomo_cost * exchange_rate).toLocaleString()}
            </span>
          </Typography>
        )}

        <Typography
          component="p"
          variant="p"
          className="text-xs font-semibold py-6"
        >
          Proposed ticket/ top up cost in Ksh
          <span className="pl-10 text-xs font-normal">
            {parseInt(delegate.ticket_cost).toLocaleString()}
          </span>
        </Typography>

        <Typography
          component="p"
          variant="p"
          className="text-xs font-semibold py-6"
        >
          Participation Fee (where applicable) in Ksh
          <span className="pl-10 text-xs font-normal">
            {parseInt(delegate.participant_cost).toLocaleString()}
          </span>
        </Typography>

        <Typography
          component="p"
          variant="p"
          className="text-xs font-semibold py-6"
        >
          Number of Days:
          <span className="pl-10 text-xs font-normal">{days_rounded}</span>
        </Typography>

        {trip.hops_checking_confirm === "not_confirmed" ? (
          <div className="py-12">
            <Typography
              component="p"
              variant="p"
              className="text-md font-normal font-semibold"
            >
              Total participation fee & top up ticket cost in KSH.
              <span className="pl-10 text-md font-semibold">
                {
                  // parseInt(delegate.accomo_cost) +
                  (
                    parseInt(delegate.ticket_cost) +
                    parseInt(delegate.participant_cost)
                  ).toLocaleString()
                }
              </span>
            </Typography>
            <Typography
              component="p"
              variant="p"
              className="text-md font-normal font-semibold"
            >
              Total Subsistence/Accommodation Cost in U.S $.
              <span className="pl-10 text-md font-semibold">
                {parseInt(delegate.accomo_cost)
                  // parseInt(delegate.ticket_cost) +
                  // parseInt(delegate.participant_cost)
                  .toLocaleString()}
              </span>
            </Typography>
          </div>
        ) : (
          <div className="py-12">
            <Typography
              component="p"
              variant="p"
              className="text-md font-normal font-semibold"
            >
              Total Cost in KES.
              <span className="pl-10 text-md font-semibold">
                {(
                  parseInt(delegate.accomo_cost * exchange_rate) +
                  parseInt(delegate.ticket_cost) +
                  parseInt(delegate.participant_cost)
                ).toLocaleString()}
              </span>
            </Typography>
          </div>
        )}
      </div>
    )
  );
};
