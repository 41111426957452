import { useState, useEffect } from "react";
import {
  Paper,
  Divider,
  Box,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import NavLinkAdapter from "../../components/NavLinkAdapter";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";

import { FormHeaderDetail } from "../../forms/details/FormHeader";
import { PersonalDetails } from "../../forms/details/Personal";
import { TravelDetails } from "../../forms/details/Travel";
import { DepartureReturnDetails } from "../../forms/details/Departure_Return";
import { DelegationSizeDetails } from "../../forms/details/Delegation";
// import { ConcurrenceDetails } from '../../forms/details/Concurrence';
// import { ApprovalDetails } from '../../forms/details/Approval';
// import { ConfirmationDetails } from "../../forms/details/Confirmation";
import CheckingSummary from "./CheckingSummary";
import { HistandStats } from "../../forms/details/HistandStats";
import { backend_url } from "../../components/Constants";
import { Private } from "../../forms/details/Private";
import Loading from "../../components/Loading";
import axios from "axios";
import TravelSnackbar from "../../components/TravelSnackbar";
import ApprovedForm from "../../components/ApprovedForm";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CheckingConfirmation(props) {
  const { token, setOpen, isMobile, user } = props;
  const { id } = useParams();
  const [trip, setTrip] = useState([]);
  const [value, setValue] = useState(0);
  const [checking_confirm, setChecking_confirm] = useState(undefined);
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [hops_checking_confirm, setHops_checking_confirm] = useState(undefined);
  const [hops_checking_reasons, setHops_checking_reasons] = useState("");
  // const [hops_checking_recommendation, setHops_checking_recommendation] =
  //   useState("");

  const {
    cs_first_name,
    cs_last_name,
    trip_data,
    acting_data,
    delegate_data,
    depature_and_return_data,
    digitalsignature,
  } = trip;

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setValue(0);
    setOpen(false);
  }, [location, setOpen]);

  useEffect(() => {
    fetch(
      `${backend_url}/traveller/approvals/?` +
        new URLSearchParams({
          trip: id,
        }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setTrip(data);
        setChecking_confirm();
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [token, id]);

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();

    const CheckingInfo = {
      trip_id: id,
      hops_checking_reasons: hops_checking_reasons,
      // hops_checking_recommendation: hops_checking_recommendation,
      hops_checking_confirm: hops_checking_confirm,
      // cs_reasons: `I ${user.first_name} ${user.last_name} ${user.designation} of ${user.ministry} confirm
      //  that this form has has been reviewed. ${cs_reasons}.`,
    };

    let url = `${backend_url}/traveller/confirmation/`;
    axios
      .patch(url, CheckingInfo, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Confirmed Successfully!");
        setLoading(false);
        setHops_checking_reasons("");
        // setHops_checking_recommendation("");
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.response.data?.details
            ? err.response.data?.details
            : err.response.data?.error
        );
        setLoading(false);
      });
  };

  return (
    <div className="relative flex flex-col flex-auto items-center px-4 sm:px-8 container mx-auto">
      <Paper className="w-full px-2 sm:px-32 py-4 sm:py-32" elevation={1}>
        <div className="flex flex-col justify-center w-full mt-48 sm:pt-16 sm:mt-64 mb-16">
          <Button
            className="font-semibold"
            component={NavLinkAdapter}
            to="/"
            size="large"
            onClick={() => (isMobile ? setOpen(false) : setOpen(true))}
            color="primary"
            variant="contained"
          >
            <CloseIcon />
            <span className="mx-8">Close Application</span>
          </Button>
        </div>

        {openMessage === true && (
          <TravelSnackbar
            openMessage={openMessage}
            severity={severity}
            message={message}
            uploads={true}
          />
        )}

        {trip_data && (
          <div>
            {trip_data[0].trip_type === "private" ? (
              <div className="flex flex-col justify-center w-full pt-16 mt-12 mb-16">
                {/* <Button
                  className="font-semibold"
                  component={NavLinkAdapter}
                  to="/"
                  size="large"
                  onClick={() => (isMobile ? setOpen(false) : setOpen(true))}
                  color="primary"
                  variant="contained"
                >
                  <CloseIcon />
                  <span className="mx-8">Close Application</span>
                </Button> */}

                <Private editForm={true} trip={trip_data[0]} />

                <form
                  name="CheckingForm"
                  noValidate
                  className="flex flex-col justify-center w-full "
                  onSubmit={handleSubmit}
                >
                  {trip_data[0].hops_checking_confirm !== "accepted" && (
                    <>
                      {trip_data[0].chairperson_confirm === "accepted" && (
                        <>
                          {trip_data[0].chairperson_reason !== null &&
                            (trip_data[0].chairperson_confirm === "accepted" ||
                              trip_data[0].chairperson_confirm ===
                                "accepted") && (
                              <Typography
                                component="p"
                                variant="p"
                                className="text-xs font-bold py-12"
                              >
                                Chairperson comments:{" "}
                                <span className="pl-10 text-xs font-normal">
                                  {trip_data[0].chairperson_reason}
                                </span>
                              </Typography>
                            )}

                          {trip_data[0].ps_reasons !== null &&
                            (trip_data[0].ps_confirm === "accepted" ||
                              trip_data[0].ps_confirm === "rejected") && (
                              <Typography
                                component="p"
                                variant="p"
                                className="text-xs font-bold py-12"
                              >
                                PS comments:{" "}
                                <span className="pl-10 text-xs font-normal">
                                  {trip_data[0].ps_reasons}
                                </span>
                              </Typography>
                            )}

                          {trip_data[0].cs_reasons !== null &&
                            (trip_data[0].cs_confirm === "accepted" ||
                              trip_data[0].cs_confirm === "rejected") && (
                              <Typography
                                component="p"
                                variant="p"
                                className="text-xs font-bold py-12"
                              >
                                CS comments:{" "}
                                <span className="pl-10 text-xs font-normal">
                                  {trip_data[0].cs_reasons}
                                </span>
                              </Typography>
                            )}
                        </>
                      )}

                      <TextField
                        label="Comments"
                        id="Comments"
                        variant="outlined"
                        sx={{ my: 4 }}
                        value={hops_checking_reasons}
                        multiline
                        minRows={3}
                        fullWidth
                        onChange={(e) =>
                          setHops_checking_reasons(e.target.value)
                        }
                      />

                      {/* <TextField
                        label="Recommendations"
                        id="Recommendations"
                        variant="outlined"
                        sx={{ my: 4 }}
                        value={hops_checking_recommendation}
                        multiline
                        minRows={3}
                        fullWidth
                        onChange={(e) =>
                          setHops_checking_recommendation(e.target.value)
                        }
                      /> */}
                      <div className="flex items-center mt-24 mb-16 sm:mt-0 sm:mx-8 space-x-12">
                        <Button
                          className="whitespace-nowrap"
                          variant="contained"
                          color="error"
                          onClick={() => {
                            setHops_checking_confirm("rejected");
                            setOpenMessage(false);
                          }}
                          startIcon={<DoDisturbIcon />}
                          type="submit"
                        >
                          {loading === true ? (
                            <Loading />
                          ) : (
                            "REJECT CONFIRMATION"
                          )}
                        </Button>

                        <Button
                          className="whitespace-nowrap"
                          variant="contained"
                          color="success"
                          onClick={() => {
                            setHops_checking_confirm("accepted");
                            setOpenMessage(false);
                          }}
                          startIcon={<CheckCircleOutlineIcon />}
                          type="submit"
                        >
                          {loading === true ? <Loading /> : "MARK AS CONFIRMED"}
                        </Button>
                      </div>
                    </>
                  )}
                </form>
              </div>
            ) : (
              <>
                <Divider />

                <div>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      textColor="secondary"
                      indicatorColor="secondary"
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      {trip_data[0].reviewer === true && (
                        <Tab
                          className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                          disableRipple
                          label="Approval Letter"
                          {...a11yProps(0)}
                        />
                      )}

                      <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                        disableRipple
                        label="Travel Summary"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 0 + 1 : 0
                        )}
                      />

                      <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                        disableRipple
                        label="Annextures and Personal Details"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 1 + 1 : 1
                        )}
                      />
                      <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                        disableRipple
                        label="Travel Details"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 2 + 1 : 2
                        )}
                      />
                      <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                        disableRipple
                        label="Departure and Return Details"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 3 + 1 : 3
                        )}
                      />
                      <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                        disableRipple
                        label="Costs and Size of Delegation"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 4 + 1 : 4
                        )}
                      />

                      <Tab
                        label="Travel History and Statistics"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 5 + 1 : 5
                        )}
                      />
                    </Tabs>
                  </Box>

                  {trip_data[0].reviewer === true && (
                    <CustomTabPanel value={value} index={0}>
                      {depature_and_return_data[0] !== undefined && (
                        <ApprovedForm
                          cs_first_name={cs_first_name}
                          cs_last_name={cs_last_name}
                          digitalsignature={digitalsignature}
                          trip={trip_data[0]}
                          depRet={depature_and_return_data[0]}
                          delegates={delegate_data}
                        />
                      )}
                    </CustomTabPanel>
                  )}

                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 0 + 1 : 0}
                  >
                    {trip_data[0].checking_confirm !== "accepted" && (
                      <>
                        <CheckingSummary
                          token={token}
                          setOpen={setOpen}
                          id={id}
                          setChecking_confirm={setChecking_confirm}
                          checking_confirm={checking_confirm}
                          trip={trip_data[0]}
                          depRet={depature_and_return_data[0]}
                          delegate_data={delegate_data}
                        />
                      </>
                    )}
                  </CustomTabPanel>

                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 1 + 1 : 1}
                  >
                    <FormHeaderDetail
                      trip={trip_data[0]}
                      checking_confirm={checking_confirm}
                    />
                    <PersonalDetails
                      trip={trip_data[0]}
                      actingOfficer={acting_data[0]}
                    />
                  </CustomTabPanel>

                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 2 + 1 : 2}
                  >
                    <TravelDetails trip={trip_data[0]} />
                  </CustomTabPanel>

                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 3 + 1 : 3}
                  >
                    <DepartureReturnDetails
                      trip={trip_data[0]}
                      depRet={depature_and_return_data[0]}
                      token={token}
                    />
                  </CustomTabPanel>

                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 4 + 1 : 4}
                  >
                    <DelegationSizeDetails
                      trip={trip_data[0]}
                      delegates={delegate_data}
                      depRet={depature_and_return_data[0]}
                    />
                  </CustomTabPanel>

                  {/* <CustomTabPanel value={value} index={5}>
                <ConfirmationDetails
                  trip={trip_data[0]}
                  digitalsignature={digitalsignature}
                />
              </CustomTabPanel> */}

                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 5 + 1 : 5}
                  >
                    <HistandStats
                      user={user}
                      trip={trip_data[0]}
                      digitalsignature={digitalsignature}
                      token={token}
                    />
                  </CustomTabPanel>
                </div>
              </>
            )}

            {/* <ConfirmationDetails trip={trip} />

  <Divider sx={{ py: 2, my: 2 }} />

  <ConcurrenceDetails trip={trip} />

  <Divider sx={{ py: 2, my: 2 }} />

  <ApprovalDetails trip={trip} /> */}
          </div>
        )}

        <Divider sx={{ my: 10 }} />
      </Paper>
    </div>
  );
}
