import Typography from "@mui/material/Typography";

export const DelegateInfoDetails = (props) => {
  const { delegate } = props;

  return (
    delegate && (
      <div>
        <div>
          <Typography
            component="p"
            variant="p"
            className="text-xs font-bold py-6"
          >
            Delegate (name):{" "}
            <span className="pl-10 text-xs font-normal">
              {delegate.full_name}
            </span>
          </Typography>

          <Typography
            component="p"
            variant="p"
            className="text-xs font-bold py-6"
          >
            Personal No:{" "}
            <span className="pl-10 text-xs font-normal">
              {delegate.personal_no}
            </span>
          </Typography>

          <Typography
            component="p"
            variant="p"
            className="text-xs font-bold py-6"
          >
            Designation{" "}
            <span className="pl-10 text-xs font-normal">
              {delegate.designation === "Other"
                ? delegate.designation_new
                : delegate.designation}
            </span>
          </Typography>

          <Typography
            component="p"
            variant="p"
            className="text-xs font-bold py-6"
          >
            Passport No:{" "}
            <span className="pl-10 text-xs font-normal">
              {delegate.passport_no}
            </span>
          </Typography>

          <Typography
            component="p"
            variant="p"
            className="text-xs font-bold py-6"
          >
            Job Group/Grade equivalent:{" "}
            <span className="pl-10 text-xs font-normal">
              {delegate.job_group}
            </span>
          </Typography>

          <Typography
            component="p"
            variant="p"
            className="text-xs font-bold pt-6"
          >
            Role that the delegate is playing during the visit:
            <br />
            {/* <span className="text-xs font-normal">{delegate.role}</span> */}
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: delegate.role }} />
        </div>
      </div>
    )
  );
};
