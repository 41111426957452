import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import format from "date-fns/format";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InfoIcon from "@mui/icons-material/Info";
import { lighten } from "@mui/material/styles";
// import _ from "../../@lodash";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Loading from "../../components/Loading";
import TravelSnackbar from "../../components/TravelSnackbar";
import { countries, countries_with_Kenya } from "../../components/Countries";
import NavLinkAdapter from "../../components/NavLinkAdapter";
import { backend_url } from "../../components/Constants";
import DraftWysiwygEditor from "../editor/TextEditor";

export default function Part1(props) {
  const {
    user,
    token,
    trip_data,
    depature_and_return_data,
    reviewer,
    edit,
    edit1,
  } = props;
  const [meeting_up, setMeeting_up] = useState(null);
  const [invitation_letter_up, setInvitation_letter_up] = useState(null);
  const [program_up, setProgram_up] = useState(null);
  const [account_up, setAccount_up] = useState(null);
  const [virtuality_upload, setVirtuality_upload] = useState(null);
  const [procument_up, setProcument_up] = useState(null);

  const [similar, setSimilar] = useState(
    trip_data !== undefined
      ? trip_data.program_similar_country === null
        ? false
        : true
      : null
  );
  const [virtualityPresent, setVirtualityPresent] = useState(
    trip_data !== undefined
      ? trip_data.virtuality_reason === "" ||
        trip_data.virtuality_reason === null
        ? false
        : true
      : null
  );
  const [bench, setBench] = useState(false);
  const [program_type1, setProgram_type1] = useState(false);
  const [program_type2, setProgram_type2] = useState(
    trip_data !== undefined && trip_data.meeting_up ? true : false
  );
  // const [dollar_ksh, setDollarKsh] = useState(
  //   trip_data !== undefined && trip_data.sponsor_status === "not_sponsored"
  //     ? 0
  //     : null
  // );
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const countrys = countries && countries.map((countries) => countries.Country);
  const countrys_incl_Ke =
    countries_with_Kenya &&
    countries_with_Kenya.map((countries) => countries.Country);

  const countryVisiting = trip_data.country;
  const job_group = trip_data.user.job_group;
  const days =
    depature_and_return_data &&
    (new Date(depature_and_return_data.date_of_return).getTime() -
      new Date(depature_and_return_data.date_of_dep).getTime()) /
      (1000 * 3600 * 24);
  const days_rounded =
    days && days.toString().charAt(1) === "."
      ? parseInt(days.toString().charAt(0)) + 1
      : days;
  const src_rate = trip_data.gok_cost;
  // const per_diem =
  //   dollar_ksh !== null ? src_rate * days_rounded * dollar_ksh : "__";
  const per_diem = src_rate * days_rounded;
  const accomodation_cost =
    trip_data !== undefined ? trip_data.accomo_cost : null;

  const schema = yup.object().shape({
    reason_for_tr: yup.string().required("Please state the reason for travel"),
    program_type: yup
      .string()
      .oneOf(
        [
          "conference",
          "seminar",
          "workshop",
          "meeting",
          "benchmarking",
          "assignment",
          "goverment to government",
          "other",
        ],
        "Please select the program type"
      ),
    country: yup
      .string()
      .required("You must enter the country")
      .oneOf(countrys !== false ? countrys : [], "Please select the country"),
    host_country: yup
      .string()
      .required("You must enter the country")
      .oneOf(
        countrys_incl_Ke !== false ? countrys_incl_Ke : [],
        "Please select the country"
      ),
    program_similar_country:
      similar === true
        ? yup
            .string()
            .required("You must enter the country")
            .oneOf(
              countrys !== false ? countrys : [],
              "Please select the country"
            )
        : yup.string().notRequired(),
    program_title: yup.string().required("Please state the program title"),
    program_from: yup
      .date()
      .required("You must enter date of program")
      .default(() => new Date()),
    program_to: yup
      .date()
      .required("You must enter date of program")
      .default(() => new Date()),
    // similar: yup
    //   .string()
    //   .required("Please fill in this field")
    //   .oneOf(["true", "false"]),
    program_similar:
      similar === true
        ? yup.string().required("Please state the program title")
        : yup.string().notRequired(),
    prog_sm_from:
      similar === true
        ? yup
            .date()
            .required("Please state the dates")
            .default(() => new Date())
        : yup.date().notRequired(),
    prog_sm_to:
      similar === true
        ? yup
            .date()
            .required("Please state the dates")
            .default(() => new Date())
        : yup.date().notRequired(),
    program_other:
      program_type1 === true
        ? yup.string().required("Please state the name")
        : yup.string().notRequired(),
    host: yup.string().required("Please state the host"),
    host_zip_postal_code: yup
      .string()
      .required("Please state the zip or postal code"),
    host_city: yup.string().required("Please state the city"),
    sponsor:
      bench === true
        ? yup.string().required("Please state the sponsor")
        : yup.string().notRequired(),
    sponsor_status: yup
      .string()
      .required("Please state the trip sponsorship status")
      .oneOf(
        ["fully", "fully1", "not_sponsored"],
        "Please select the sponsorship status"
      ),
    object_meeting: yup.string().required("Please state the objectives"),
    exp_activity: yup.string().required("Please state the expected activity"),
    // virtualityPresent: yup.boolean().required("This field is required"),
    // virtualityPresent: yup.string().required().oneOf(["true", "false"]),
    virtuality_reason:
      virtualityPresent === true
        ? yup.string().required("This field is required")
        : yup.string().notRequired(),
    // accomo_cost: yup
    //   .number()
    //   .required("This field is required, if none type 0"),
    ticket_cost: yup
      .number()
      .required("This field is required, if none type 0"),
    participant_cost: yup
      .number()
      .required("This field is required, if none type 0"),
  });

  const defaultValues = {
    reason_for_tr: trip_data !== undefined ? trip_data.reason_for_tr : "",
    virtuality_reason:
      trip_data !== undefined ? trip_data.virtuality_reason : "",
    program_type: trip_data !== undefined ? trip_data.program_type : "",
    program_title: trip_data !== undefined ? trip_data.program_title : "",
    program_from: trip_data !== undefined ? dayjs(trip_data.program_from) : "",
    program_to: trip_data !== undefined ? dayjs(trip_data.program_to) : "",
    program_similar: trip_data !== undefined ? trip_data.program_similar : "",
    prog_sm_from: trip_data !== undefined ? dayjs(trip_data.prog_sm_from) : "",
    prog_sm_to: trip_data !== undefined ? dayjs(trip_data.prog_sm_to) : "",
    program_other:
      trip_data !== undefined
        ? trip_data.program_other === null
          ? ""
          : trip_data.program_other
        : "",
    country: trip_data !== undefined ? trip_data.country : "",
    host_country: trip_data !== undefined ? trip_data.host_country : "",
    program_similar_country:
      trip_data !== undefined ? trip_data.program_similar_country : "",
    host: trip_data !== undefined ? trip_data.host : "",
    host_city: trip_data !== undefined ? trip_data.host_city : "",
    host_zip_postal_code:
      trip_data !== undefined ? trip_data.host_zip_postal_code : "",
    sponsor: trip_data !== undefined ? trip_data.sponsor : "",
    sponsor_status: trip_data !== undefined ? trip_data.sponsor_status : "",
    object_meeting: trip_data !== undefined ? trip_data.object_meeting : "",
    exp_activity: trip_data !== undefined ? trip_data.exp_activity : "",
    // virtuality: trip_data !== undefined ? trip_data.virtuality : "",
    // accomo_cost: trip_data !== undefined ? trip_data.accomo_cost : "",
    ticket_cost: trip_data !== undefined ? trip_data.ticket_cost : "",
    participant_cost: trip_data !== undefined ? trip_data.participant_cost : "",
  };

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, errors } = formState;
  // const sevenDays = dayjs().set("hour", 168).startOf("hour");

  function submit({
    virtuality_reason,
    country,
    host_country,
    program_similar_country,
    reason_for_tr,
    program_type,
    program_title,
    program_from,
    program_to,
    program_similar,
    prog_sm_from,
    prog_sm_to,
    program_other,
    host,
    host_zip_postal_code,
    host_city,
    sponsor,
    sponsor_status,
    object_meeting,
    exp_activity,
    virtuality,
    // accomo_cost,
    ticket_cost,
    participant_cost,
  }) {
    const tripInfo = {
      invitation_letter_up,
      program_up,
      account_up,
      virtuality_upload,
      procument_up,
      virtuality_reason,
      country,
      host_country,
      program_similar_country,
      meeting_up: program_type2 === false ? null : meeting_up,
      reason_for_tr,
      program_type,
      program_title,
      program_from: format(new Date(program_from), "yyyy-MM-dd"),
      program_to: format(new Date(program_to), "yyyy-MM-dd"),
      program_similar,
      prog_sm_from: format(new Date(prog_sm_from), "yyyy-MM-dd"),
      prog_sm_to: format(new Date(prog_sm_to), "yyyy-MM-dd"),
      program_other: program_type1 === false ? "" : program_other,
      host,
      host_zip_postal_code,
      host_city,
      sponsor,
      sponsor_status,
      object_meeting,
      exp_activity,
      virtuality: virtualityPresent,
      accomo_cost:
        per_diem === ("__" || 0 || null || undefined)
          ? accomodation_cost
          : per_diem,
      ticket_cost,
      participant_cost,
      active_trip: true,
      id: trip_data.trip_id,
      trip_type: "public",
      reviewer: reviewer === true ? true : false,
      cs_confirm: user.designation === "CS" ? "accepted" : "not_confirmed",
      ps_confirm:
        user.designation === "CS"
          ? "accepted"
          : user.designation === "PS"
          ? "accepted"
          : "not_confirmed",
      chairperson_confirm:
        user.designation === "PS"
          ? "accepted"
          : user.designation === "CS"
          ? "accepted"
          : user.designation === "CHAIRPERSON"
          ? "accepted"
          : "not_confirmed",
    };
    axios
      .put(
        `${backend_url}/traveller/details/`,
        tripInfo,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Travel Details Submitted.");
        setLoading(false);
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.code === "ERR_NETWORK" && "Network error, please try again later."
        );
        // setMessage(err.response.data?.details ? err.response.data.details : err.response.data);
        setMessage(
          err.response.data.account_up
            ? err.response.data.account_up
            : err.response.data.invitation_letter_up
            ? err.response.data.invitation_letter_up
            : err.response.data.procument_up
            ? err.response.data.procument_up
            : err.response.data.program_up
            ? err.response.data.program_up
            : err.response.data?.details
            ? err.response.data.details
            : err.response.data
        );
        setLoading(false);
      });
  }

  return (
    <form
      name="tripForm"
      noValidate
      className="flex flex-col justify-center w-full mt-4"
      onSubmit={handleSubmit(submit)}
    >
      {openMessage === true && (
        <TravelSnackbar
          openMessage={openMessage}
          severity={severity}
          message={message}
        />
      )}

      <Typography
        component="p"
        variant="p"
        className="px-4 py-16 text-md md:text-lg font-semibold sm:font-bold"
      >
        PERSONAL DETAILS
      </Typography>

      <div className="sm:flex sm:-mx-4">
        <TextField
          label="Name of Officer Travelling:"
          id="officer_name"
          variant="filled"
          sx={{ m: 1 }}
          InputProps={{
            readOnly: true,
          }}
          defaultValue={
            reviewer === true ? trip_data.user.full_name : user.full_name
          }
          fullWidth
        />

        <TextField
          label="Personal No:"
          id="personal_no"
          variant="filled"
          sx={{ m: 1 }}
          InputProps={{
            readOnly: true,
          }}
          defaultValue={
            reviewer === true ? trip_data.user.personal_no : user.personal_no
          }
          fullWidth
        />
      </div>

      <div className="sm:flex sm:-mx-4">
        <TextField
          label="Designation:"
          id="designation"
          variant="filled"
          InputProps={{
            readOnly: true,
          }}
          sx={{ m: 1 }}
          defaultValue={
            reviewer === true ? trip_data.user.designation : user.designation
          }
          fullWidth
        />

        <TextField
          label="Ministry/Corporation:"
          id="ministry_corp"
          variant="filled"
          InputProps={{
            readOnly: true,
          }}
          sx={{ m: 1 }}
          defaultValue={
            reviewer === true ? trip_data.user.ministry : user.ministry
          }
          fullWidth
        />
      </div>

      <div className="sm:flex sm:-mx-4">
        <TextField
          label="Passport No:"
          id="passport_no"
          variant="filled"
          InputProps={{
            readOnly: true,
          }}
          sx={{ m: 1 }}
          // fullWidth
          defaultValue={
            reviewer === true
              ? trip_data.user.designation === "CS" ||
                trip_data.user.designation === "PS"
                ? trip_data.user.diplomatic_passport_no
                : trip_data.user.passport_no
              : trip_data.user.designation === "CS" ||
                trip_data.user.designation === "PS"
              ? trip_data.user.diplomatic_passport_no
              : user.passport_no
          }
        />
      </div>

      {/* Part 2 */}
      <Typography
        component="p"
        variant="p"
        className="px-4 py-16 text-md font-semibold md:text-lg md:font-bold"
      >
        TRAVEL DETAILS
      </Typography>

      <div>
        <Typography
          component="p"
          variant="p"
          className="p-8 sm:p-12 text-xs font-semibold"
        >
          If attending Conference/Seminar/Workshop, meeting/
          benchmarking/assignment/formal government to government engagement
          etc. Please indicate its title-
        </Typography>

        <Controller
          name="program_type"
          control={control}
          render={({ field }) => (
            <FormControl error={!!errors.program_type} required>
              <RadioGroup
                row
                aria-labelledby="radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="pt-4 pb-8 px-8"
                {...field}
              >
                <FormControlLabel
                  className="mx-8"
                  value="conference"
                  control={
                    <Radio
                      onClick={() => {
                        setProgram_type1(false);
                        setProgram_type2(false);
                      }}
                    />
                  }
                  label="Conference"
                />
                <FormControlLabel
                  className="mx-8"
                  value="seminar"
                  control={
                    <Radio
                      onClick={() => {
                        setProgram_type1(false);
                        setProgram_type2(false);
                      }}
                    />
                  }
                  label="Seminar"
                />
                <FormControlLabel
                  className="mx-8"
                  value="workshop"
                  control={
                    <Radio
                      onClick={() => {
                        setProgram_type1(false);
                        setProgram_type2(false);
                      }}
                    />
                  }
                  label="Workshop"
                />
                <FormControlLabel
                  className="mx-8"
                  value="meeting"
                  control={
                    <Radio
                      onClick={() => {
                        setProgram_type1(false);
                        setProgram_type2(false);
                      }}
                    />
                  }
                  label="Meeting"
                />

                {bench === true ? (
                  <FormControlLabel
                    className="mx-8"
                    value="benchmarking"
                    control={
                      <Radio
                        onClick={() => {
                          setProgram_type1(false);
                          setProgram_type2(true);
                        }}
                      />
                    }
                    label="Benchmarking"
                  />
                ) : (
                  <div></div>
                )}
                <FormControlLabel
                  className="mx-8"
                  value="assignment"
                  control={
                    <Radio
                      onClick={() => {
                        setProgram_type1(false);
                        setProgram_type2(false);
                      }}
                    />
                  }
                  label="Assignment"
                />
                <FormControlLabel
                  className="mx-8"
                  value="goverment to government"
                  control={
                    <Radio
                      onClick={() => {
                        setProgram_type1(false);
                        setProgram_type2(false);
                      }}
                    />
                  }
                  label="Formal government to government"
                />
                <FormControlLabel
                  className="mx-8"
                  value="other"
                  control={
                    <Radio
                      onClick={() => {
                        setProgram_type1(true);
                        setProgram_type2(false);
                      }}
                    />
                  }
                  label="Other"
                />
              </RadioGroup>
              <FormHelperText>
                <span className="text-red-500">
                  {" "}
                  {errors?.program_type?.message}
                </span>
              </FormHelperText>
            </FormControl>
          )}
        />

        <Controller
          name="program_title"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Title:"
              id="program_title"
              variant="outlined"
              sx={{ p: 1 }}
              error={!!errors.program_title}
              helperText={errors?.program_title?.message}
              multiline
              minRows={3}
              fullWidth
            />
          )}
        />

        {(trip_data.program_other !== null || trip_data.program_other !== "") &&
          program_type1 === true && (
            <Controller
              name="program_other"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Name of other:"
                  id="program_other"
                  variant="outlined"
                  sx={{ p: 1 }}
                  error={!!errors.program_other}
                  helperText={errors?.program_other?.message}
                  multiline
                  minRows={2}
                  required
                  fullWidth
                />
              )}
            />
          )}

        <Typography
          component="p"
          variant="p"
          className="p-8 sm:p-12 text-xs font-semibold"
        >
          Uploaded documents
        </Typography>

        <div className="sm:flex sm:-mx-4 mt-8">
          {/* <div className="flex flex-wrap -m-8 mt-8"> */}
          <Box
            component={NavLinkAdapter}
            to={`${backend_url}${trip_data.invitation_letter_up}`}
            target="_blank"
            sx={{ backgroundColor: "background.paper" }}
            className="flex flex-col relative w-full sm:w-160 h-auto m-8 p-16 shadow rounded-16 cursor-pointer no-underline"
          >
            <div className="flex flex-auto w-full items-center justify-center">
              <InfoIcon />
            </div>
            <div className="flex shrink flex-col justify-center text-center">
              <Typography className="truncate text-12 font-medium">
                {invitation_letter_up !== null
                  ? invitation_letter_up.name
                  : trip_data.invitation_letter_up}
              </Typography>
              <Typography
                className="truncate text-12 font-medium"
                color="text.secondary"
              >
                Invitation Letter Upload
              </Typography>
            </div>
          </Box>

          <Box
            component={NavLinkAdapter}
            to={`${backend_url}${trip_data.program_up}`}
            target="_blank"
            sx={{ backgroundColor: "background.paper" }}
            className="flex flex-col relative w-full sm:w-160 h-auto m-8 p-16 shadow rounded-16 cursor-pointer no-underline"
          >
            <div className="flex flex-auto w-full items-center justify-center">
              <InfoIcon />
            </div>
            <div className="flex shrink flex-col justify-center text-center">
              <Typography className="truncate text-12 font-medium">
                {program_up !== null ? program_up.name : trip_data.program_up}
              </Typography>
              <Typography
                className="truncate text-12 font-medium"
                color="text.secondary"
              >
                Program Upload
              </Typography>
            </div>
          </Box>

          {meeting_up && (
            <Box
              component={NavLinkAdapter}
              to={`${backend_url}${trip_data.meeting_up}`}
              sx={{ backgroundColor: "background.paper" }}
              className="flex flex-col relative w-full sm:w-160 h-auto m-8 p-16 shadow rounded-16 cursor-pointer no-underline"
            >
              <div className="flex flex-auto w-full items-center justify-center">
                <InfoIcon />
              </div>
              <div className="flex shrink flex-col justify-center text-center">
                <Typography className="truncate text-12 font-medium">
                  {meeting_up !== null ? meeting_up.name : trip_data.meeting_up}
                </Typography>
                <Typography
                  className="truncate text-12 font-medium"
                  color="text.secondary"
                >
                  Meeting/Benchmarking Upload
                </Typography>
              </div>
            </Box>
          )}
        </div>

        <Typography
          component="p"
          variant="p"
          className="p-8 sm:p-12 text-xs font-semibold"
        >
          Change uploaded documents
        </Typography>

        <div className="sm:flex sm:-mx-4 mt-8">
          <Box
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? lighten(theme.palette.background.default, 1)
                  : lighten(theme.palette.background.default, 0.02),
            }}
            component="label"
            htmlFor="button-file"
            className="productImageUpload flex items-center justify-center relative 
                         w-82 h-64 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
          >
            <input
              accept=".jpeg, .jpg, .png, .pdf,"
              className="hidden"
              id="button-file"
              type="file"
              name="image_url1"
              onChange={(e) => setInvitation_letter_up(e.target.files[0])}
            />
            <IconButton>
              <FileUploadIcon sx={{ color: "action" }} />
            </IconButton>

            <Typography
              variant="p"
              component="p"
              className="text-xs font-small  pl-8 pr-16"
            >
              {invitation_letter_up === null ? (
                <span> Invitation Letter Upload</span>
              ) : invitation_letter_up !== null ? (
                invitation_letter_up.name
              ) : (
                invitation_letter_up
              )}
            </Typography>
          </Box>

          <Box
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? lighten(theme.palette.background.default, 1)
                  : lighten(theme.palette.background.default, 0.02),
            }}
            component="label"
            htmlFor="button-file1"
            className="productImageUpload flex items-center justify-center relative  
                        w-82 h-64  rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
          >
            <input
              accept=".jpeg, .jpg, .png, .pdf,"
              className="hidden"
              id="button-file1"
              type="file"
              name="image_url1"
              onChange={(e) => setProgram_up(e.target.files[0])}
            />
            <IconButton>
              <FileUploadIcon sx={{ color: "action" }} />
            </IconButton>

            <Typography
              variant="p"
              component="p"
              className="text-xs font-small  pl-8 pr-16"
            >
              {program_up === null ? (
                <span> Program Upload</span>
              ) : program_up !== null ? (
                program_up.name
              ) : (
                program_up
              )}
            </Typography>
          </Box>

          {program_type2 === true && (
            <div className="sm:flex -mx-4">
              <Box
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? lighten(theme.palette.background.default, 1)
                      : lighten(theme.palette.background.default, 0.02),
                }}
                component="label"
                htmlFor="button-file2"
                className="productImageUpload flex items-center justify-center relative  w-82 h-64  rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
              >
                <input
                  accept=".jpeg, .jpg, .png, .pdf,"
                  className="hidden"
                  id="button-file2"
                  type="file"
                  name="image_url1"
                  onChange={(e) => setMeeting_up(e.target.files[0])}
                />
                <IconButton>
                  <FileUploadIcon sx={{ color: "action" }} />
                </IconButton>

                <Typography
                  variant="p"
                  component="p"
                  className="text-xs font-small  pl-8 pr-16"
                >
                  {meeting_up === null ? (
                    <span> Gazette notice for benchmarking Upload</span>
                  ) : (
                    meeting_up.name
                  )}
                </Typography>
              </Box>
            </div>
          )}
        </div>

        <div>
          <Typography
            component="p"
            variant="body2"
            className="pb-12 text-xs font-small"
          >
            (Annex programme. For benchmark visits, annex copy of Gazette Notice
            of Taskforce/Working Committee to which the travel is related)
          </Typography>
        </div>
      </div>

      <div>
        <DraftWysiwygEditor
          control={control}
          name="reason_for_tr"
          label="Reason(s) for travel:"
          errors={errors}
          defaultValue={trip_data !== undefined ? trip_data.reason_for_tr : ""}
        />
        {/* <Controller
          name="reason_for_tr"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Reason(s) for travel: "
              id="reason_for_tr"
              variant="outlined"
              sx={{ m: 1 }}
              error={!!errors.reason_for_tr}
              helperText={errors?.reason_for_tr?.message}
              multiline
              minRows={4}
              fullWidth
            />
          )}
        /> */}
      </div>

      <div>
        {countries && (
          <Controller
            name="country"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                sx={{ p: 1 }}
                id="country"
                fullWidth
                options={countrys}
                value={value}
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    helperText={errors?.country?.message}
                    error={!!errors.country}
                    {...params}
                    label="Country"
                  />
                )}
              />
            )}
          />
        )}
      </div>

      <div>
        <Typography
          component="p"
          variant="p"
          className="p-8 sm:p-12 text-xs font-semibold"
        >
          Sponsor(s) of the Meeting/ Engagement/ Conference/ Seminar/ Workshop,
          etc:
        </Typography>

        <Controller
          name="sponsor_status"
          control={control}
          render={({ field }) => (
            <FormControl
              sx={{ p: 1 }}
              fullWidth
              error={!!errors.sponsor_status}
            >
              <InputLabel id="sponsor_status">Sponsor Cost</InputLabel>
              <Select
                labelId="sponsor_status"
                id="sponsor_status"
                label="Sponsor Cost"
                {...field}
              >
                <MenuItem value={"fully"} onClick={() => setBench(true)}>
                  GOK Sponsored
                </MenuItem>
                <MenuItem
                  value={"not_sponsored"}
                  onClick={() => setBench(true)}
                >
                  Non-GOK/ Host Sponsored
                </MenuItem>
                {/* <MenuItem
                  value={"not_sponsored"}
                  onClick={() => setBench(false)}
                >
                  Not Sponsored
                </MenuItem> */}
              </Select>
            </FormControl>
          )}
        />

        {bench === true && (
          <Controller
            name="sponsor"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Sponsor Entity/Institute/Organization:"
                id="sponsor"
                variant="outlined"
                error={!!errors.sponsor}
                helperText={errors?.sponsor?.message}
                sx={{ p: 1 }}
                fullWidth
                multiline
                minRows={3}
              />
            )}
          />
        )}
      </div>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="sm:flex m-4">
          <Controller
            name="program_from"
            // minDate={sevenDays}
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                sx={{ py: 2 }}
                error={!!errors.program_from}
                helperText={errors?.program_from?.message}
                clearable
                // minDate={todayAtMid}
                slotProps={{
                  textField: {
                    id: "program_from",
                    label: "From date",
                    InputLabelProps: {
                      shrink: true,
                    },
                    fullWidth: true,
                    variant: "outlined",
                  },
                  actionBar: {
                    actions: ["clear", "today"],
                  },
                }}
              />
            )}
          />

          <span className="p-16"></span>
          <Controller
            name="program_to"
            // minDate={sevenDays}
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                sx={{ py: 2 }}
                error={!!errors.program_to}
                helperText={errors?.program_to?.message}
                // minDate={todayAtMid}
                clearable
                slotProps={{
                  textField: {
                    id: "program_to",
                    label: "To date",
                    InputLabelProps: {
                      shrink: true,
                    },
                    fullWidth: true,
                    variant: "outlined",
                  },
                  actionBar: {
                    actions: ["clear", "today"],
                  },
                }}
              />
            )}
          />
        </div>
      </LocalizationProvider>

      <div>
        <Typography
          component="p"
          variant="p"
          className="p-8 sm:p-12 text-xs font-semibold"
        >
          Please indicate if you have attended a similar or related conference
          on the same subject matter in the last five (5) years-
        </Typography>

        {similar !== (null || undefined) && (
          <Controller
            name="similar"
            control={control}
            render={({ field }) => (
              <FormControl error={!!errors.similar} required>
                <RadioGroup
                  row
                  aria-labelledby="radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="pt-4 pb-8 px-8"
                  defaultValue={`${similar}`}
                  {...field}
                >
                  <FormControlLabel
                    className="mx-8"
                    value="true"
                    control={
                      <Radio
                        onClick={() => {
                          setSimilar(true);
                        }}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    className="mx-8"
                    value="false"
                    control={
                      <Radio
                        onClick={() => {
                          setSimilar(false);
                        }}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
                <FormHelperText>
                  <span className="text-red-500">
                    {" "}
                    {errors?.similar?.message}
                  </span>
                </FormHelperText>
              </FormControl>
            )}
          />
        )}
      </div>

      <div>
        {similar === true && (
          <>
            <DraftWysiwygEditor
              control={control}
              name="program_similar"
              label="Title and describe more on what the conference was about"
              errors={errors}
              defaultValue={
                trip_data !== undefined ? trip_data.program_similar : ""
              }
            />

            {/* <Controller
              name="program_similar"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Title and describe more on what the conference was about"
                  id="program_similar"
                  variant="outlined"
                  sx={{ p: 1 }}
                  error={!!errors.program_similar}
                  helperText={errors?.program_similar?.message}
                  multiline
                  minRows={3}
                  fullWidth
                  required={similar === true ? true : false}
                />
              )}
            /> */}

            <div className="py-8 mx-4">
              {countries !== undefined && (
                <Controller
                  name="program_similar_country"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      sx={{ p: 1 }}
                      id="program_similar_country"
                      fullWidth
                      options={countrys}
                      value={value}
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          helperText={errors?.program_similar_country?.message}
                          error={!!errors.program_similar_country}
                          {...params}
                          label="Country"
                        />
                      )}
                    />
                  )}
                />
              )}
            </div>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="sm:flex m-4">
                <Controller
                  name="prog_sm_from"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      sx={{ py: 2 }}
                      clearable
                      slotProps={{
                        textField: {
                          id: "prog_sm_from",
                          label: "Similar program from date",
                          error: !!errors.prog_sm_from,
                          helperText: errors?.prog_sm_from?.message,
                          InputLabelProps: {
                            shrink: true,
                          },
                          fullWidth: true,
                          variant: "outlined",
                        },
                        actionBar: {
                          actions: ["clear", "today"],
                        },
                      }}
                    />
                  )}
                />

                <span className="p-16"></span>

                <Controller
                  name="prog_sm_to"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      sx={{ py: 2 }}
                      clearable
                      slotProps={{
                        textField: {
                          id: "prog_sm_to",
                          label: "Similar program end date",
                          error: !!errors.prog_sm_to,
                          helperText: errors?.prog_sm_to?.message,
                          InputLabelProps: {
                            shrink: true,
                          },
                          fullWidth: true,
                          variant: "outlined",
                        },
                        actionBar: {
                          actions: ["clear", "today"],
                        },
                      }}
                    />
                  )}
                />
              </div>
            </LocalizationProvider>
          </>
        )}
      </div>

      <div>
        <Typography
          component="p"
          variant="p"
          className="p-8 sm:p-12 text-xs font-semibold"
        >
          Person(s) Organization/ Institution hosting the Meeting/ Engagement/
          Conference/ Seminar/ Workshop, etc.
        </Typography>

        <Controller
          name="host"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Name of the host / organization/ institution:"
              id="host"
              variant="outlined"
              error={!!errors.host}
              helperText={errors?.host?.message}
              sx={{ m: 1 }}
              fullWidth
              multiline
              minRows={3}
            />
          )}
        />
      </div>

      <div className="sm:flex m-4">
        <Controller
          name="host_city"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="City of the host"
              id="host_city"
              variant="outlined"
              error={!!errors.host_city}
              helperText={errors?.host_city?.message}
              sx={{ p: 1 }}
              fullWidth
            />
          )}
        />

        <Controller
          name="host_zip_postal_code"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="ZIP/Postal code of the host"
              id="host_zip_postal_code"
              variant="outlined"
              error={!!errors.host_zip_postal_code}
              helperText={errors?.host_zip_postal_code?.message}
              sx={{ p: 1 }}
              fullWidth
            />
          )}
        />
      </div>

      <div className="py-8 mx-4">
        {countries !== undefined && (
          <Controller
            name="host_country"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                sx={{ p: 1 }}
                id="host_country"
                fullWidth
                options={countrys_incl_Ke}
                value={value}
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    helperText={errors?.host_country?.message}
                    error={!!errors.host_country}
                    {...params}
                    label="Country of the host"
                  />
                )}
              />
            )}
          />
        )}
      </div>

      <div>
        <Typography
          component="p"
          variant="p"
          className="p-8 sm:p-12 text-xs font-semibold"
        >
          Objective(s) of the Meeting/ Conference/ Seminar/ Workshop, etc:
        </Typography>

        <DraftWysiwygEditor
          control={control}
          name="object_meeting"
          label="Objectives:"
          errors={errors}
          defaultValue={trip_data !== undefined ? trip_data.object_meeting : ""}
        />

        {/* <Controller
          name="object_meeting"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Objectives:"
              id="object_meeting"
              variant="outlined"
              error={!!errors.object_meeting}
              helperText={errors?.object_meeting?.message}
              sx={{ m: 1 }}
              fullWidth
              multiline
              minRows={3}
            />
          )}
        /> */}
      </div>

      <div>
        <Typography
          component="p"
          variant="p"
          className="p-8 sm:p-12 text-xs font-semibold"
        >
          Expected activity/ event/ intervention to be executed upon return in
          the Ministry/ Department/ Corporation of the participant derived from
          the Meeting/ Engagement/ Conference/ Seminar/ Workshop, etc.
        </Typography>

        <DraftWysiwygEditor
          control={control}
          name="exp_activity"
          label="Expected Activity:"
          errors={errors}
          defaultValue={trip_data !== undefined ? trip_data.exp_activity : ""}
        />

        {/* <Controller
          name="exp_activity"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Expected Activity:"
              id="exp_activity"
              variant="outlined"
              sx={{ m: 1 }}
              error={!!errors.exp_activity}
              helperText={errors?.exp_activity?.message}
              fullWidth
              multiline
              minRows={3}
            />
          )}
        /> */}
      </div>

      <div>
        <Typography
          component="p"
          variant="p"
          className="p-8 sm:p-12 text-xs font-semibold"
        >
          Where the reason for travel is a conference, workshop, academic
          meeting, confirm that the option of virtual participation is
          unavailable, and that physical attendance is mandatory.
        </Typography>

        <Controller
          name="virtualityPresent"
          control={control}
          render={({ field }) => (
            <FormControl error={!!errors.virtualityPresent} required>
              <RadioGroup
                className="px-4"
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue={`${virtualityPresent}`}
                {...field}
              >
                <FormControlLabel
                  sx={{ p: 1 }}
                  value="true"
                  control={
                    <Radio
                      onClick={() => {
                        setVirtualityPresent(true);
                      }}
                    />
                  }
                  label="Virtual participation available"
                />
                <FormControlLabel
                  sx={{ p: 1 }}
                  value="false"
                  control={
                    <Radio
                      onClick={() => {
                        setVirtualityPresent(false);
                      }}
                    />
                  }
                  label="Virtual participation unavailable"
                />
              </RadioGroup>
              <FormHelperText>
                <span className="text-red-500">
                  {" "}
                  {errors?.virtualityPresent?.message}
                </span>
              </FormHelperText>
            </FormControl>
          )}
        />

        <div className="sm:flex flex-col sm:-mx-4 mt-8">
          {virtualityPresent === true && (
            <>
              <DraftWysiwygEditor
                control={control}
                name="virtuality_reason"
                label="Explain further on why you want to attend:"
                errors={errors}
                defaultValue={
                  trip_data !== undefined ? trip_data.virtuality_reason : ""
                }
              />

              {/* <Controller
                name="virtuality_reason"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Explain further on why you want to attend:"
                    id="virtuality_reason"
                    variant="outlined"
                    sx={{ p: 1 }}
                    error={!!errors.virtuality_reason}
                    helperText={errors?.virtuality_reason?.message}
                    fullWidth
                    multiline
                    minRows={3}
                  />
                )}
              /> */}

              <Box
                sx={{
                  marginTop: "24px",
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? lighten(theme.palette.background.default, 1)
                      : lighten(theme.palette.background.default, 0.02),
                }}
                component="label"
                htmlFor="button-file12"
                className="productImageUpload flex items-center justify-center relative 
                         w-82 h-64 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
              >
                <input
                  accept=".jpeg, .jpg, .png, .pdf,"
                  className="hidden"
                  id="button-file12"
                  type="file"
                  name="image_url1"
                  onChange={(e) => setVirtuality_upload(e.target.files[0])}
                />
                <IconButton>
                  <FileUploadIcon sx={{ color: "action" }} />
                </IconButton>

                <Typography
                  variant="p"
                  component="p"
                  className="text-xs font-small  pl-8 pr-16"
                >
                  {virtuality_upload === null ? (
                    <span> Virtual Letter Upload</span>
                  ) : virtuality_upload !== null ? (
                    virtuality_upload.name
                  ) : (
                    virtuality_upload
                  )}
                </Typography>
              </Box>
            </>
          )}
        </div>
      </div>

      <div className="sm:flex sm:-mx-4 mt-8">
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? lighten(theme.palette.background.default, 1)
                : lighten(theme.palette.background.default, 0.02),
          }}
          component="label"
          htmlFor="button-file"
          className="productImageUpload flex items-center justify-center relative 
                         w-82 h-64 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
        >
          <input
            accept=".jpeg, .jpg, .png, .pdf,"
            className="hidden"
            id="button-file"
            type="file"
            name="image_url1"
            onChange={(e) => setAccount_up(e.target.files[0])}
          />
          <IconButton>
            <FileUploadIcon sx={{ color: "action" }} />
          </IconButton>

          <Typography
            variant="p"
            component="p"
            className="text-xs font-small  pl-8 pr-16"
          >
            {account_up === null ? (
              <span> Accountant Letter Upload</span>
            ) : account_up !== null ? (
              account_up.name
            ) : (
              account_up
            )}
          </Typography>
        </Box>

        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? lighten(theme.palette.background.default, 1)
                : lighten(theme.palette.background.default, 0.02),
          }}
          component="label"
          htmlFor="button-file"
          className="productImageUpload flex items-center justify-center relative 
                         w-82 h-64 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
        >
          <input
            accept=".jpeg, .jpg, .png, .pdf,"
            className="hidden"
            id="button-file"
            type="file"
            name="image_url1"
            onChange={(e) => setProcument_up(e.target.files[0])}
          />
          <IconButton>
            <FileUploadIcon sx={{ color: "action" }} />
          </IconButton>

          <Typography
            variant="p"
            component="p"
            className="text-xs font-small  pl-8 pr-16"
          >
            {procument_up === null ? (
              <span> Procurement Letter Upload</span>
            ) : procument_up !== null ? (
              procument_up.name
            ) : (
              procument_up
            )}
          </Typography>
        </Box>
      </div>

      <div className="sm:flex sm:-mx-4 mt-8">
        <Box
          component={NavLinkAdapter}
          to={`${backend_url}${trip_data.virtuality_upload}`}
          target="_blank"
          sx={{ backgroundColor: "background.paper" }}
          className="flex flex-col relative w-full sm:w-160 h-auto m-8 p-16 shadow rounded-16 cursor-pointer no-underline"
        >
          <div className="flex flex-auto w-full items-center justify-center">
            <InfoIcon />
          </div>
          <div className="flex shrink flex-col justify-center text-center">
            <Typography className="truncate text-12 font-medium">
              {virtuality_upload !== null
                ? virtuality_upload.name
                : trip_data.virtuality_upload}
            </Typography>
            <Typography
              className="truncate text-12 font-medium"
              color="text.secondary"
            >
              Virtual Letter Upload
            </Typography>
          </div>
        </Box>

        <Box
          component={NavLinkAdapter}
          to={`${backend_url}${trip_data.account_up}`}
          target="_blank"
          sx={{ backgroundColor: "background.paper" }}
          className="flex flex-col relative w-full sm:w-160 h-auto m-8 p-16 shadow rounded-16 cursor-pointer no-underline"
        >
          <div className="flex flex-auto w-full items-center justify-center">
            <InfoIcon />
          </div>
          <div className="flex shrink flex-col justify-center text-center">
            <Typography className="truncate text-12 font-medium">
              {account_up !== null ? account_up.name : trip_data.account_up}
            </Typography>
            <Typography
              className="truncate text-12 font-medium"
              color="text.secondary"
            >
              Accountant Letter Upload
            </Typography>
          </div>
        </Box>

        <Box
          component={NavLinkAdapter}
          to={`${backend_url}${trip_data.procument_up}`}
          target="_blank"
          sx={{ backgroundColor: "background.paper" }}
          className="flex flex-col relative w-full sm:w-160 h-auto m-8 p-16 shadow rounded-16 cursor-pointer no-underline"
        >
          <div className="flex flex-auto w-full items-center justify-center">
            <InfoIcon />
          </div>
          <div className="flex shrink flex-col justify-center text-center">
            <Typography className="truncate text-12 font-medium">
              {procument_up !== null
                ? procument_up.name
                : trip_data.procument_up}
            </Typography>
            <Typography
              className="truncate text-12 font-medium"
              color="text.secondary"
            >
              Procurement Letter Upload
            </Typography>
          </div>
        </Box>
      </div>

      <Typography
        component="h5"
        variant="p"
        className="px-4 py-16 text-md font-semibold md:text-lg "
      >
        Cost of the Trip and Sponsorship for Applicant
      </Typography>

      <div>
        {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-12 w-full min-w-0 py-16 pb-32">
          <CBKRatesWidget />
          <TravelRatesWidget />
        </div> */}

        <TableContainer
          component={Paper}
          elevation={1}
          sx={{ paddingBottom: 0, marginBottom: 3 }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "primary.light" }}>
                <TableCell>U.S Dollar to Ksh as per today CBK rates</TableCell>
                <TableCell align="right">Number of days travelling</TableCell>
                <TableCell align="right">Country</TableCell>
                <TableCell align="right">Job group</TableCell>
                <TableCell align="right">
                  Subsistence/Accommodation (Per Diem) in &nbsp;(Ksh)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {/* <TableCell component="th" scope="row">
                  <TextField
                    value={dollar_ksh}
                    onChange={(e) => {
                      setDollarKsh(e.target.value);
                    }}
                    disabled={
                      trip_data !== undefined &&
                      trip_data.sponsor_status === "not_sponsored"
                        ? true
                        : false
                    }
                    label="U.S Dollar to Ksh rate"
                    id="dollar_ksh"
                    error={!!errors.dollar_ksh}
                    helperText={errors?.dollar_ksh?.message}
                    variant="outlined"
                    fullWidth
                  />
                </TableCell> */}
                <TableCell align="right">{days_rounded}</TableCell>
                <TableCell align="right">{countryVisiting}</TableCell>
                <TableCell align="right">{job_group}</TableCell>
                <TableCell align="right">
                  <b>
                    {per_diem === ("__" || 0 || null || undefined)
                      ? accomodation_cost
                      : per_diem.toLocaleString()}
                  </b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <div className="sm:flex m-4 py-24">
          <Controller
            name="ticket_cost"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={
                  trip_data !== undefined &&
                  trip_data.sponsor_status === "not_sponsored"
                    ? true
                    : false
                }
                label="Proposed ticket/ top up cost in Ksh"
                id="applicant_role"
                error={!!errors.ticket_cost}
                helperText={errors?.ticket_cost?.message}
                variant="standard"
                sx={{ p: 1 }}
                fullWidth
              />
            )}
          />

          <Controller
            name="participant_cost"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={
                  trip_data !== undefined &&
                  trip_data.sponsor_status === "not_sponsored"
                    ? true
                    : false
                }
                label="Participation Fee (where applicable) in Ksh:"
                id="participant_cost"
                error={!!errors.participant_cost}
                helperText={errors?.participant_cost?.message}
                variant="standard"
                sx={{ p: 1 }}
                fullWidth
              />
            )}
          />
        </div>
      </div>

      {window.location.hash === "#preview" ? (
        <Button
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 9999 }}
          variant="contained"
          color={"primary"}
          component={NavLinkAdapter}
          to="/form"
        >
          Continue with your application
        </Button>
      ) : (
        <Button
          sx={{
            width: "100%",
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9999,
          }}
          variant="contained"
          color={!isValid ? "error" : "success"}
          // color="success"
          // disabled={dollar_ksh === null ? true :false }
          disabled={
            loading === true
              ? true
              : edit === true
              ? true
              : edit1 === true
              ? true
              : false
          }
          onClick={() => {
            setOpenMessage(false);
            setLoading(
              Object.keys(errors).length === 0
                ? false
                : Object.keys(errors).length > 0
                ? false
                : true
            );
          }}
          type="submit"
        >
          {loading === true ? (
            <Loading />
          ) : !isValid ? (
            "Make sure all fields have been filled"
          ) : (
            "Submit and Finish application"
          )}
        </Button>
      )}
    </form>
  );
}
