import { useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import * as yup from "yup";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { yupResolver } from "@hookform/resolvers/yup";
// import _ from "../../@lodash";
import { lighten } from "@mui/material/styles";
import TravelSnackbar from "../../components/TravelSnackbar";
import Loading from "../../components/Loading";
import { backend_url } from "../../components/Constants";

const schema = yup.object().shape({
  quartely_up: yup
    .mixed()
    .required("Please upload a csv file with all approvals"),
});

const defaultValues = {
  quartely_up: null,
};

export default function PSQuarterly(props) {
  const { token } = props;
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  function downloadTemplate() {
    axios
      .get(`${backend_url}/traveller/internally_trips/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let fileName = "quarterly_returns_template.csv";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data], {
              type: "application/csv",
              encoding: "UTF-8",
            }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/csv",
              encoding: "UTF-8",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      });
  }

  function submit({ quartely_up }) {
    const uploadInfo = {
      quartely_up,
      //   user: trips[0].trip_id,
    };
    axios
      .post(
        `${backend_url}/traveller/internally_trips/`,
        uploadInfo,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Uploaded Successfully.");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(err.code === "ERR_NETWORK" && "Network error, please try again later.");
        setMessage(err.response.data?.details);
        setLoading(false);
      });
  }

  return (
    <div className="py-4 w-full max-w-3xl">
      <Typography
        component="h5"
        variant="p"
        className="px-4 pt-16 pb-8 text-lg font-bold"
      >
        Please Upload quarterly returns of all approvals within the organization
      </Typography>

      <form
        name="uploadInfo"
        noValidate
        className="flex flex-col justify-center w-full mt-32"
        onSubmit={handleSubmit(submit)}
      >
        {openMessage === true && (
          <TravelSnackbar
            openMessage={openMessage}
            severity={severity}
            message={message}
            uploads={true}
          />
        )}

        <Button
          variant="contained"
          color="secondary"
          onClick={() => downloadTemplate()}
          // component={NavLinkAdapter}
          // to={`${template}#`}
          target="_blank"
          size="small"
          startIcon={<InsertInvitationIcon fontSize="small" />}
        >
          <span className="mx-8 truncate">Download CSV Template</span>
        </Button>

        <div className="sm:flex -mx-4">
          <Controller
            control={control}
            name="quartely_up"
            render={({ field: { onChange, value } }) => (
              <Box
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? lighten(theme.palette.background.paper, 0.4)
                      : lighten(theme.palette.background.paper, 0.02),
                }}
                component="label"
                htmlFor="button_quartely_up"
                className="flex items-center justify-center relative w-full h-64 rounded-16 mx-12 my-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
              >
                <input
                  accept=".csv,"
                  className="hidden"
                  id="button_quartely_up"
                  type="file"
                  onChange={(e) => onChange(e.target.files[0])}
                />
                <IconButton>
                  <FileUploadIcon sx={{ color: "action" }} />
                </IconButton>

                <Typography
                  variant="p"
                  component="p"
                  className="text-xs font-small pl-8 pr-16"
                >
                  {value === null ? (
                    <span> Quarterly Returns Upload</span>
                  ) : (
                    value.name
                  )}
                </Typography>
                <FormHelperText>
                  {" "}
                  <span className="flex flex-col text-red-500">
                    {errors?.quartely_up?.message}
                  </span>
                </FormHelperText>
              </Box>
            )}
          />
        </div>

        <Button
          sx={{ my: 2 }}
          variant="contained"
          color="success"
          //   disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={() => {
            setOpenMessage(false);
            setLoading(Object.keys(errors).length > 0 ? false : true);
          }}
          type="submit"
        >
          {loading === true ? <Loading /> : Object.keys(errors).length > 0 ? "Complete All Fields" : Object.keys(errors).length > 0 ? (setLoading(false)) : Object.keys(errors).length > 0 ? (setLoading(false)) : "Submit"}
        </Button>
      </form>

      {/* <Typography
        component="h5"
        variant="p"
        className="px-4 pt-16 pb-8 text-lg font-bold"
      >
        Previously uploaded quarterly returns
      </Typography>

      <Typography
        component="p"
        variant="p"
        className="px-4 pt-16 pb-8 text-sm font-bold"
      >
        Currently there is none
      </Typography> */}
    </div>
  );
}
