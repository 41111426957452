import { motion } from 'framer-motion';
import SummaryWidget from '../../components/widgets/SummaryWidget';
import OverdueWidget from '../../components/widgets/OverdueWidget';
import IssuesWidget from '../../components/widgets/IssuesWidget';
import FeaturesWidget from '../../components/widgets/FeaturesWidget';
import PreviousTripWidget from '../../components/widgets/PreviousTripWidget';
import CurrentTripWidget from '../../components/widgets/CurrentTripWidget';
import CostsWidget from '../../components/widgets/CostsWidget';

function MFAHome() {
    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };

    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };

    return (
        <>
            <motion.div
                className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-24 w-full min-w-0 pt-16"
                variants={container}
                initial="hidden"
                animate="show"
            >
                <motion.div variants={item}>
                    <SummaryWidget />
                </motion.div>
                <motion.div variants={item}>
                    <OverdueWidget />
                </motion.div>
                <motion.div variants={item}>
                    <IssuesWidget />
                </motion.div>
                <motion.div variants={item}>
                    <FeaturesWidget />
                </motion.div>
            </motion.div>

            <motion.div className="w-full" variants={container} initial="hidden" animate="show">
                <div className="grid grid-cols-1 xl:grid-cols-1 gap-24 w-full mt-24">
                    <div className="grid gap-24 sm:grid-flow-col xl:grid-flow-row">
                        <motion.div variants={item} className="flex flex-col flex-auto">
                            <PreviousTripWidget />
                        </motion.div>

                        <motion.div variants={item} className="flex flex-col flex-auto">
                            <CurrentTripWidget />
                        </motion.div>
                    </div>
                </div>

                <div className="grid grid-cols-1 xl:grid-cols-3 gap-32 w-full mt-24">
                    <motion.div variants={item} className="flex flex-col flex-auto">
                        <CostsWidget />
                    </motion.div>
                </div>
            </motion.div>

        </>
    );
}

export default MFAHome;
