import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { Box } from '@mui/system';
import { lighten } from '@mui/material/styles';
import Card from '@mui/material/Card';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FaqList from './FaqList';
import NavLinkAdapter from '../components/NavLinkAdapter';

function FAQHome() {

    return (
        <div className="flex flex-col flex-auto min-w-0">
            <Box
                className="relative pt-48 pb-96 px-16 sm:pt-80 sm:pb-112 sm:px-64 overflow-hidden"
                sx={{
                    backgroundColor: 'primary.dark',
                    color: (theme) => theme.palette.getContrastText(theme.palette.primary.main),
                }}
            >
                <div className="flex flex-col items-center justify-center  mx-auto w-full">
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0 } }}>
                        <Typography variant='p' className="mt-4 text-24 sm:text-32 font-extrabold tracking-tight leading-tight text-center">
                            How can we help you today?
                        </Typography>
                    </motion.div>
                    <div className="flex flex-1 items-center px-16 mx-8 rounded-full h-44 w-full max-w-320 sm:max-w-480 mt-24 sm:mt-80" ></div>
                </div>


                <svg
                    className="absolute inset-0 pointer-events-none"
                    viewBox="0 0 960 540"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMidYMax slice"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g
                        className="text-gray-700 opacity-25"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="100"
                    >
                        <circle r="234" cx="196" cy="23" />
                        <circle r="234" cx="790" cy="491" />
                    </g>
                </svg>
            </Box>

            <div className="flex flex-col items-center px-24 sm:px-40">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-32 md:gap-y-0 md:gap-x-24 w-full max-w-sm md:max-w-4xl -mt-64 sm:-mt-96">
                    <Card
                        role="button"
                        className="relative flex flex-col rounded-2xl shadow hover:shadow-lg overflow-hidden transition-shadow ease-in-out duration-150"
                    >
                        <div className="flex flex-col flex-auto items-center justify-center p-32 text-center">
                            <div className="text-2xl font-semibold">FAQs</div>
                            <div className="md:max-w-160 mt-4" color="text.secondary">
                                Frequently asked questions and answers
                            </div>
                        </div>
                        <Box
                            className="flex items-center justify-center py-16 px-32"
                            sx={{
                                backgroundColor: (theme) =>
                                    theme.palette.mode === 'light'
                                        ? lighten(theme.palette.background.default, 1)
                                        : lighten(theme.palette.background.default, 0.02),
                            }}
                        >
                            <Typography variant='p' color="secondary" className="mx-8">
                                FAQs
                            </Typography>
                            <ChevronRightIcon fontSize='large' color="secondary" />
                        </Box>
                    </Card>

                    <Card
                        component={NavLinkAdapter}
                        to="/terms-and-conditions"
                        role="button"
                        className="relative flex flex-col rounded-2xl shadow hover:shadow-lg overflow-hidden transition-shadow ease-in-out duration-150"
                    >
                        <div className="flex flex-col flex-auto items-center justify-center p-32 text-center">
                            <div className="text-2xl font-semibold">Terms and Conditions</div>
                            <div className="md:max-w-160 mt-4" color="text.secondary">
                                Policies/Terms and conditions
                            </div>
                        </div>
                        <Box
                            className="flex items-center justify-center py-16 px-32"
                            sx={{
                                backgroundColor: (theme) =>
                                    theme.palette.mode === 'light'
                                        ? lighten(theme.palette.background.default, 1)
                                        : lighten(theme.palette.background.default, 0.02),
                            }}
                        >
                            <Typography variant='p' color="secondary" className="mx-8">
                                Check terms and conditions
                            </Typography>
                            <ChevronRightIcon fontSize='large' color="secondary" />
                        </Box>
                    </Card>
                </div>
            </div>

            <Typography variant='p' className="pt-64 px-16 text-2xl sm:text-4xl font-extrabold leading-tight tracking-tight text-center">
                Most frequently asked questions
            </Typography>
            <Typography variant='p' className="mt-8 px-16 text-lg text-center" color="text.secondary">
                Here are the most frequently asked questions you may check before getting started
            </Typography>

            <div className="flex flex-col w-full px-16 items-center my-48">
                <FaqList className="w-full max-w-3xl" />
            </div>
        </div>
    );
}

export default FAQHome;
