import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function TravelSnackbar(props) {
  const { openMessage, severity, message, isMobile } =
    props;
  const [open, setOpen] = React.useState(openMessage);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return isMobile !== true ? (
    // confirmation === true ?
    //     <Box sx={{ display: 'flex', justifyContent: 'center', }}>
    //         <Snackbar sx={{ marginLeft: 30, marginTop: 10 }} open={open} onClose={handleClose}
    //             anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    //         >
    //             <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
    //                 {message}
    //             </Alert>
    //         </Snackbar>
    //     </Box>
    //     :
    // uploads === true ? (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Snackbar
          sx={{ marginTop: 15 }}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Box>
    // ) : (
    //   <Box sx={{ display: "flex", justifyContent: "center" }}>
    //     <Snackbar
    //       open={open}
    //       onClose={handleClose}
    //       anchorOrigin={{ vertical: "top", horizontal: "center" }}
    //     >
    //       <Alert
    //         onClose={handleClose}
    //         severity={severity}
    //         sx={{ width: "100%" }}
    //       >
    //         {message}
    //       </Alert>
    //     </Snackbar>
    //   </Box>
    // )
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
