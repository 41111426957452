import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Container, Paper } from "@mui/material";
import Part1 from "./new/Part1";
import Part3 from "./new/Part3";
import Part4A from "./new/Part4A";
import Part2A from "./new/Part2A";
import NavLinkAdapter from "../components/NavLinkAdapter";
import { useLocation, useNavigate } from "react-router-dom";
import Part4B from "./new/Part4B";
import { backend_url } from "../components/Constants";
import axios from "axios";
import TravelSnackbar from "../components/TravelSnackbar";
import { useForm } from "react-hook-form";
import Loading from "../components/Loading";

const steps = ["Part 1", "Part 2", "Part 3", "Part 4"];

export default function TravelForm(props) {
  const {
    token,
    trip_id,
    user,
    returnStep,
    trip_data,
    delegate_data,
    setOpen,
    depature_and_return_data,
  } = props;
  const [activeStep, setActiveStep] = useState(returnStep);
  const [skipped, setSkipped] = useState(new Set());
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setOpen(false);
  }, [location, setOpen]);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const { handleSubmit } = useForm({
    mode: "onChange",
    // defaultValues,
    // resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  function submitTrip() {
    axios
      .delete(
        `${backend_url}/traveller/details/?` +
          new URLSearchParams({
            trip: trip_id,
          }),
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Trip Deleted Successfully!");
        setLoading(false);
        handleBack();
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(err.code === "ERR_NETWORK" && "Network error, please try again later.");
        // setMessage(err.response.data);
        setMessage(
          err.response.data?.error
            ? err.response.data?.details
            : err.response.data
        );
        setLoading(false);
      });
  }

  function submitDepRet() {
    axios
      .delete(
        `${backend_url}/traveller/return_details/?` +
          new URLSearchParams({
            trip: trip_id,
          }),
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Trip Deleted Successfully!");
        setLoading(false);
        handleBack();
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(err.code === "ERR_NETWORK" && "Network error, please try again later.");
        // setMessage(err.response.data);
        setMessage(
          err.response.data?.error
            ? err.response.data?.details
            : err.response.data
        );
        setLoading(false);
      });
  }

  function submitActingDelegates() {
    axios
      .delete(
        `${backend_url}/traveller/delegates/?` +
          new URLSearchParams({
            trip: trip_id,
          }),
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Trip Deleted Successfully!");
        setLoading(false);
        window.location.reload();
        // handleBack();
        // navigate("/");
      })
      .catch((err) => {
        console.log(err);
        window.location.reload();
        setOpenMessage(true);
        setSeverity("success");
        setMessage(err.code === "ERR_NETWORK" && "Network error, please try again later.");
        setMessage("Trip Deleted Successfully!");
        // setSeverity("error");
        // // setMessage(err.response.data);
        // setMessage(
        //   err.response.data?.error
        //     ? err.response.data?.details
        //     : err.response.data
        // );
        setLoading(false);
      });
  }

  return (
    <div>
      <Paper elevation={0}>
        <Container component="main" maxWidth="lg">
          <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 pt-16 sm:pt-32 sm:pb-32">
            <div className="flex flex-auto items-center min-w-0">
              <div className="flex flex-col min-w-0 px-2 sm:px-16 pt-32 sm:pb-16 pb-4">
                <Typography
                  variant="p"
                  component="h5"
                  className="text-md md:text-lg font-semibold  "
                >
                  TRAVEL CLEARANCE FORM FOR CABINET SECRETARIES, CHIEF
                  ADMINISTRATIVE SECRETARIES, PRINCIPAL SECRETARIES,
                  CHAIRPERSONS AND CHIEF EXECUTIVES OF STATE CORPORATIONS
                </Typography>
              </div>
            </div>
          </div>
        </Container>
      </Paper>

      <Container component="main" maxWidth="lg">
        <Paper className="px-2 sm:px-24 py-8 my-12" elevation={1}>
          {openMessage === true && (
            <TravelSnackbar
              openMessage={openMessage}
              severity={severity}
              message={message}
            />
          )}

          <div className="p-8">
            <div className="grid grid-cols-12 gap-x-4 mx-4 mt-4 ">
              <div className="col-span-6 self-center tracking-tight">
                {activeStep !== 0 && (
                  <Button
                    variant="contained"
                    color="secondary"
                    component={NavLinkAdapter}
                    to={`/edit-form/${trip_id}#preview`}
                  >
                    Preview Application
                  </Button>
                )}
              </div>

              <div className="col-span-2 " />

              <div className="col-span-4 self-right">
                {activeStep === 1 && (
                  <form
                    name="deleteTrip"
                    noValidate
                    className="flex flex-col justify-center w-full "
                    onSubmit={handleSubmit(submitTrip)}
                  >
                    <Button variant="contained" color="error" type="submit">
                      {loading === true ? <Loading /> : "Cancel Application"}
                    </Button>
                  </form>
                )}

                {activeStep === 2 && (
                  <form
                    name="deleteTrip"
                    noValidate
                    className="flex flex-col justify-center w-full "
                    onSubmit={handleSubmit(submitDepRet)}
                  >
                    <Button variant="contained" color="error" type="submit">
                      {loading === true ? <Loading /> : "Cancel Application"}
                    </Button>
                  </form>
                )}

                {activeStep === 3 && (
                  <form
                    name="deleteTrip"
                    noValidate
                    className="flex flex-col justify-center w-full "
                    onSubmit={handleSubmit(submitActingDelegates)}
                  >
                    <Button variant="contained" color="error" type="submit">
                      {loading === true ? <Loading /> : "Cancel Application"}
                    </Button>
                  </form>
                )}

                {activeStep === 4 && (
                  <form
                    name="deleteTrip"
                    noValidate
                    className="flex flex-col justify-center w-full "
                    onSubmit={handleSubmit(submitActingDelegates)}
                  >
                    <Button variant="contained" color="error" type="submit">
                      {loading === true ? <Loading /> : "Cancel Application"}
                    </Button>
                  </form>
                )}
              </div>
            </div>
          </div>

          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step
                  key={label}
                  {...stepProps}
                  sx={{
                    "& .MuiStepLabel-root, & .MuiStepContent-root": {
                      cursor: "pointer!important",
                    },
                    "& .MuiStepContent-root": {
                      color: "text.secondary",
                      fontSize: 13,
                    },
                  }}
                >
                  <StepLabel
                    {...labelProps}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "background.paper",
                        "& .MuiStepIcon-text": {
                          fill: "text.secondary",
                        },
                        "&.Mui-completed": {
                          color: "secondary.main",
                          "& .MuiStepIcon-text ": {
                            fill: "secondary.contrastText",
                          },
                        },
                        "&.Mui-active": {
                          color: "secondary.main",
                          "& .MuiStepIcon-text ": {
                            fill: "secondary.contrastText",
                          },
                        },
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography
                component="p"
                variant="p"
                className="text-lg font-bold py-32"
              >
                All steps completed - edit application
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  sm: { pt: 2, mt: 6 },
                  py: 2,
                }}
              >
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                  variant="contained"
                >
                  Back (Add more delegates)
                </Button>

                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  component={NavLinkAdapter}
                  to={`/edit-form/${trip_id}`}
                  sx={{ pr: 1 }}
                  onClick={handleReset}
                  color="success"
                  variant="contained"
                >
                  View and Edit Your Travel Application
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 && (
                <div>
                  {user !== undefined && (
                    <>
                      <Part1
                        trip_id={trip_id}
                        activeStep={activeStep}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        steps={steps}
                        user={user}
                        token={token}
                      />
                    </>
                  )}
                </div>
              )}

              {activeStep === 1 && (
                <div>
                  <Part3
                    activeStep={activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    steps={steps}
                    user={user}
                    token={token}
                  />
                </div>
              )}

              {activeStep === 2 && (
                <div>
                  <Part2A
                    activeStep={activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    steps={steps}
                    user={user}
                    token={token}
                  />
                </div>
              )}
              {activeStep === 3 && (
                <div>
                  {trip_data[0].accomo_cost === 0 ||
                  trip_data[0].accomo_cost === null ||
                  trip_data[0].accomo_cost === "__" ||
                  trip_data[0].accomo_cost === "" ? (
                    <Part4A
                      activeStep={activeStep}
                      handleBack={handleBack}
                      handleNext={handleNext}
                      steps={steps}
                      user={user}
                      token={token}
                      trip_data={trip_data[0]}
                      delegate_data={delegate_data}
                      depature_and_return_data={depature_and_return_data[0]}
                    />
                  ) : (
                    <Part4B
                      activeStep={activeStep}
                      handleBack={handleBack}
                      handleNext={handleNext}
                      steps={steps}
                      user={user}
                      token={token}
                      trip_data={trip_data[0]}
                      delegate_data={delegate_data}
                      depature_and_return_data={depature_and_return_data[0]}
                    />
                  )}
                </div>
              )}
            </React.Fragment>
          )}
        </Paper>
      </Container>
    </div>
  );
}
