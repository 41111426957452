import React, { useEffect, useState } from "react";
import * as yup from "yup";
// import _ from "../@lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Paper,
  Container,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
import format from "date-fns/format";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { lighten } from "@mui/material/styles";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Loading from "../components/Loading";
import TravelSnackbar from "../components/TravelSnackbar";
import { countries } from "../components/Countries";
import { useLocation } from "react-router-dom";
import { backend_url } from "../components/Constants";

export default function PrivateVisit(props) {
  const { user, token, setOpen } = props;
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const countrys = countries && countries.map((countries) => countries.Country);

  const schema = yup.object().shape({
    reason_for_tr: yup.string().required("This field is required"),
    country: yup
      .string()
      .required("You must enter the country")
      .oneOf(countrys !== false ? countrys : [], "Please select the country"),
    program_from: yup
      .date()
      .required("You must enter date of program")
      .default(() => new Date()),
    program_to: yup
      .date()
      .required("You must enter date of program")
      .default(() => new Date()),
    leave_upload: yup.mixed().required("Please upload your leave letter"),
  });

  const defaultValues = {
    leave_upload: null,
    reason_for_tr: "",
    country: "",
    program_from: null,
    program_to: null,
  };

  const todayAtMid = dayjs().set("hour", 0).startOf("hour");

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    // isValid, dirtyFields,
    errors,
  } = formState;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setOpen(false);
  }, [location, setOpen]);

  function submit({
    leave_upload,
    reason_for_tr,
    program_from,
    program_to,
    country,
  }) {
    const tripInfo = {
      leave_upload,
      reason_for_tr,
      program_from: format(new Date(program_from), "yyyy-MM-dd"),
      program_to: format(new Date(program_to), "yyyy-MM-dd"),
      country,
      trip_type: "private",
      active_trip: true,
      cs_confirm: user.designation === "CS" ? "accepted" : "not_confirmed",
      chairperson_confirm:
        user.designation === "PS"
          ? "accepted"
          : user.designation === "CS"
          ? "accepted"
          : user.designation === "CHAIRPERSON"
          ? "accepted"
          : "not_confirmed",
    };
    axios
      .put(
        `${backend_url}/traveller/details/`,
        tripInfo,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Private Travel Submitted.");
        setLoading(false);
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(err.code === "ERR_NETWORK" && "Network error, please try again later.");
        setMessage(err.response.data?.details ? err.response.data.details : err.response.data.leave_upload);
        setLoading(false);
      });
  }

  return (
    <div>
      <Paper elevation={0}>
        <Container component="main" maxWidth="lg">
          <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 pt-16 sm:pt-32 sm:pb-32">
            <div className="flex flex-auto items-center min-w-0">
              <div className="flex flex-col items-center min-w-0 px-2 sm:px-16 pt-32 sm:pb-16 pb-4">
                <Typography
                  variant="p"
                  component="h5"
                  className="text-md md:text-lg text-center font-semibold  "
                >
                  APPLY FOR A Private Travel
                </Typography>
              </div>
            </div>
          </div>
        </Container>
      </Paper>

      <Container component="main" maxWidth="lg">
        <Paper className="px-2 sm:px-24 py-8 my-12" elevation={1}>
          <form
            name="tripForm"
            noValidate
            className="flex flex-col justify-center w-full"
            onSubmit={handleSubmit(submit)}
          >
            {openMessage === true && (
              <TravelSnackbar
                openMessage={openMessage}
                severity={severity}
                message={message}
              />
            )}

            <Typography
              component="h5"
              variant="p"
              className="px-4 py-16 text-md md:text-lg font-semibold sm:font-bold"
            >
              TO BE FILLED BY THE APPLICANT: <br />
              PART I - PERSONAL DETAILS
            </Typography>

            <div className="sm:flex sm:-mx-4">
              <TextField
                label="Name of Officer Travelling:"
                id="officer_name"
                variant="filled"
                sx={{ p: 1 }}
                InputProps={{
                  readOnly: true,
                }}
                defaultValue={user.full_name}
                fullWidth
              />

              <TextField
                label="Personal No:"
                id="personal_no"
                variant="filled"
                sx={{ p: 1 }}
                InputProps={{
                  readOnly: true,
                }}
                defaultValue={user.personal_no}
                fullWidth
              />
            </div>

            <div className="sm:flex sm:-mx-4">
              <TextField
                label="Designation:"
                id="designation"
                variant="filled"
                InputProps={{
                  readOnly: true,
                }}
                sx={{ p: 1 }}
                defaultValue={user.designation}
                fullWidth
              />

              <TextField
                label="Ministry/Corporation:"
                id="ministry_corp"
                variant="filled"
                InputProps={{
                  readOnly: true,
                }}
                sx={{ p: 1 }}
                defaultValue={user.ministry}
                fullWidth
              />
            </div>

            <div className="sm:flex sm:-mx-4">
              <TextField
                label="Passport No:"
                id="passport_no"
                variant="filled"
                InputProps={{
                  readOnly: true,
                }}
                sx={{ p: 1 }}
                // fullWidth
                defaultValue={user.passport_no}
              />
            </div>

            {/* Part 2 */}
            <Typography
              component="h5"
              variant="p"
              className="px-4 py-16 text-md font-semibold md:text-lg md:font-bold"
            >
              PART II - Private Travel DETAILS
            </Typography>

            <div>
              <Controller
                name="reason_for_tr"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Activity going to do/ Title: "
                    id="reason_for_tr"
                    variant="outlined"
                    sx={{ p: 1 }}
                    error={!!errors.reason_for_tr}
                    helperText={errors?.reason_for_tr?.message}
                    multiline
                    minRows={4}
                    fullWidth
                  />
                )}
              />
            </div>

            <div>
              {countries && (
                <Controller
                  name="country"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      sx={{ p: 1 }}
                      id="country"
                      fullWidth
                      options={countrys}
                      value={value}
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          helperText={errors?.country?.message}
                          error={!!errors.country}
                          {...params}
                          label="Country"
                        />
                      )}
                    />
                  )}
                />
              )}
            </div>

            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="sm:flex m-4">
                  <Controller
                    name="program_from"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        sx={{ p: 2 }}
                        clearable
                        minDate={todayAtMid}
                        slotProps={{
                          textField: {
                            id: "program_from",
                            label: "From date",
                            error: !!errors.program_from,
                            helperText: errors?.program_from?.message,
                            InputLabelProps: {
                              shrink: true,
                            },
                            fullWidth: true,
                            variant: "outlined",
                          },
                          actionBar: {
                            actions: ["clear", "today"],
                          },
                        }}
                      />
                    )}
                  />

                  <span className="p-16"></span>
                  <Controller
                    name="program_to"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        sx={{ p: 2 }}
                        minDate={todayAtMid}
                        clearable
                        slotProps={{
                          textField: {
                            id: "program_to",
                            label: "To date",
                            error: !!errors.program_to,
                            helperText: errors?.program_to?.message,
                            InputLabelProps: {
                              shrink: true,
                            },
                            fullWidth: true,
                            variant: "outlined",
                          },
                          actionBar: {
                            actions: ["clear", "today"],
                          },
                        }}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>

              <div className="sm:flex sm:-mx-4 mt-8">
                <Controller
                  control={control}
                  name="leave_upload"
                  render={({ field: { onChange, value } }) => (
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? lighten(theme.palette.background.default, 1)
                            : lighten(theme.palette.background.default, 0.02),
                      }}
                      component="label"
                      htmlFor="button_leave_upload"
                      className="flex items-center justify-center relative w-full h-64 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
                    >
                      <input
                        accept=".jpeg, .jpg, .png, .pdf,"
                        className="hidden"
                        id="button_leave_upload"
                        type="file"
                        onChange={(e) => onChange(e.target.files[0])}
                      />
                      <IconButton>
                        <FileUploadIcon sx={{ color: "action" }} />
                      </IconButton>

                      <Typography
                        variant="p"
                        component="p"
                        className="text-xs font-small pl-8 pr-16"
                      >
                        {value === null ? (
                          <span> Leave Upload</span>
                        ) : (
                          value.name
                        )}
                      </Typography>
                      <FormHelperText>
                        <span className="text-red-500">
                          {" "}
                          {errors?.leave_upload?.message}
                        </span>
                      </FormHelperText>
                    </Box>
                  )}
                />
              </div>
            </div>

            <Button
              sx={{ mt: 4 }}
              variant="contained"
              color="success"
              // disabled={_.isEmpty(dirtyFields) || !isValid}
              onClick={() => {
                setOpenMessage(false);
                setLoading(Object.keys(errors).length > 0 ? false : true);
              }}
              type="submit"
            >
              {loading === true ? <Loading /> : Object.keys(errors).length > 0 ? "Complete All Fields" : Object.keys(errors).length > 0 ? (setLoading(false)) : "Submit"}
            </Button>
          </form>
        </Paper>
      </Container>
    </div>
  );
}
