import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import SummaryWidget from '../../components/widgets/SummaryWidget';
import OverdueWidget from '../../components/widgets/OverdueWidget';
import IssuesWidget from '../../components/widgets/IssuesWidget';
import FeaturesWidget from '../../components/widgets/FeaturesWidget';
import PreviousTripWidget from '../../components/widgets/PreviousTripWidget';
// import CurrentTripWidget from '../../components/widgets/CurrentTripWidget';
import TravelRatesWidget from '../../components/widgets/TravelRatesWidget';
import FrequentCountries from '../../components/widgets/FrequentCountriesWidget';
import { backend_url } from '../../components/Constants';

function ChairpersonHome(props) {
    const { token } = props;
    const [stats, setStats] = useState([]);
    const { previous_trip_cost, frequently_visted_country, days_this_year, days_this_quarter, this_year_trips } = stats;

    useEffect(() => {
        fetch(`${backend_url}/traveller/stats/`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            })
            .then((res) => res.json())
            .then((data) => {
                setStats(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [token]);

    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };

    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };

    return (
        <>
          <motion.div className="w-full" variants={container} initial="hidden" animate="show">
                <div className="grid grid-cols-1 xl:grid-cols-1 gap-24 w-full mt-24">
                    <motion.div variants={item} className="flex flex-col flex-auto">
                        <TravelRatesWidget />
                    </motion.div>
                </div>
            </motion.div>
            <motion.div
                className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-24 w-full min-w-0 pt-16"
                variants={container}
                initial="hidden"
                animate="show"
            >
                <motion.div variants={item}>
                    <SummaryWidget this_year_trips={this_year_trips} />
                </motion.div>
                <motion.div variants={item}>
                    <OverdueWidget days_this_year={days_this_year} />
                </motion.div>
                <motion.div variants={item}>
                    <IssuesWidget days_this_quarter={days_this_quarter} />
                </motion.div>
                <motion.div variants={item}>
                    <FeaturesWidget days_this_quarter={days_this_quarter} />
                </motion.div>
            </motion.div>

            <motion.div className="w-full" variants={container} initial="hidden" animate="show">
                <div className="grid grid-cols-1 xl:grid-cols-1 gap-24 w-full mt-24">
                    <div className="grid gap-24 sm:grid-flow-col xl:grid-flow-row">
                    <motion.div variants={item} className="flex flex-col flex-auto">
                            {frequently_visted_country &&
                            <FrequentCountries frequently_visited_country={frequently_visted_country} />
                        }
                        </motion.div>

                        <motion.div variants={item} className="flex flex-col flex-auto">
                            <PreviousTripWidget previous_trip_cost={previous_trip_cost} />
                            {/* <CurrentTripWidget /> */}
                        </motion.div>

                        
                    </div>
                </div>

                {/* <div className="grid grid-cols-1 xl:grid-cols-3 gap-32 w-full mt-24">
                    <motion.div variants={item} className="flex flex-col flex-auto">
                        {frequently_visted_country &&
                            <FrequentCountries frequently_visited_country={frequently_visted_country} />
                        }
                    </motion.div>
                </div> */}

                {/* <div className="grid grid-cols-1 xl:grid-cols-3 gap-32 w-full mt-24">
                    <motion.div variants={item} className="flex flex-col flex-auto">
                        <CostsWidget />
                    </motion.div>
                </div> */}
            </motion.div>

        </>
    );
}

export default ChairpersonHome;
