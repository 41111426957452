import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";

function CSSidebarContent() {
  return (
    <div className="flex flex-col flex-auto ">
      <Container component="main" maxWidth="xl">
        <Outlet />
      </Container>
    </div>
  );
}

export default CSSidebarContent;