import { useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { styled } from "@mui/material/styles";
import format from "date-fns/format";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { lighten } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TravelSnackbar from "../../components/TravelSnackbar";
import Loading from "../../components/Loading";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { backend_url } from "../../components/Constants";
import { useNavigate } from "react-router-dom";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function PSSummary(props) {
  const {
    token,
    id,
    setPSConfirm,
    ps_confirm,
    trip,
    depRet,
    delegate_data,
    user,
  } = props;
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const delLength = delegate_data.length;
  const days =
    (new Date(depRet.date_of_return).getTime() -
      new Date(depRet.date_of_dep).getTime()) /
    (1000 * 3600 * 24);
  const days_rounded =
    days.toString().charAt(1) === "."
      ? parseInt(days.toString().charAt(0)) + 1
      : days;

  const updatedAt = trip.updated_at;

  function calculateTimeDifference(updatedAt) {
    // Parse the updatedAt string into a Date object
    const updatedAtDate = new Date(updatedAt);

    // Get the current time in UTC
    const now = new Date();
    const utcNow = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    );

    // Calculate the difference in milliseconds
    const differenceInMs = utcNow.getTime() - updatedAtDate.getTime();

    // Convert the difference to hours
    const differenceInHours = differenceInMs / (1000 * 60 * 60);

    // Return the difference in hours (rounded to 2 decimal places for precision)
    return differenceInHours.toFixed(2);
  }

  const differenceInHours = calculateTimeDifference(updatedAt);

  const schema = yup.object().shape({
    ps_reasons: yup.string().required("This field is required"),
    // ps_upload:
    //   differenceInHours >= 24
    //     ? ps_confirm === "accepted"
    //       ? yup
    //           .mixed()
    //           .required("Please upload the justification for late concurring.")
    //       : yup.mixed().notRequired()
    //     : yup.mixed().notRequired(),
  });

  const defaultValues = {
    // ps_upload: null,
    ps_reasons: "",
  };

  const exchange_rate = trip?.accomo_cost / trip?.gok_cost / days_rounded;

  const tot_cost = [
    // parseInt(trip.accomo_cost) +
    parseInt(trip.ticket_cost) + parseInt(trip.participant_cost),
  ];

  const tot_cost_kes = [
    parseInt(trip.accomo_cost) +
      parseInt(trip.ticket_cost) +
      parseInt(trip.participant_cost),
  ];

  const tot_cost_dollars = [parseInt(trip.accomo_cost)];

  // Pushed to total expenses in ksh
  const all_del_cost = parseInt(
    delegate_data.map((delegate) =>
      tot_cost.push(
        // parseInt(delegate.accomo_cost * exchange_rate) +
        parseInt(delegate.ticket_cost) + parseInt(delegate.participant_cost)
      )
    )
  );

  // Pushed to total expenses in dollars
  const all_del_cost2 = parseInt(
    delegate_data.map((delegate) =>
      tot_cost_dollars.push(
        parseInt(delegate.accomo_cost * exchange_rate)
        // parseInt(delegate.ticket_cost) + parseInt(delegate.participant_cost)
      )
    )
  );

  // Pushed to Grand total expenses in ksh
  const all_del_cost1 = parseInt(
    delegate_data.map((delegate) =>
      tot_cost_kes.push(
        parseInt(delegate.accomo_cost * exchange_rate) +
          parseInt(delegate.ticket_cost) +
          parseInt(delegate.participant_cost)
      )
    )
  );

  const sums = tot_cost.reduce((a, x, i) => [...a, x + (i && a[i - 1])], []);
  const sumsKES = tot_cost_kes.reduce(
    (a, x, i) => [...a, x + (i && a[i - 1])],
    []
  );

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    // isValid, dirtyFields,
    errors,
  } = formState;

  const navigate = useNavigate();
  function submit({ ps_reasons }) {
    const checkingInfo = {
      trip_id: id,
      ps_reasons,
      // ps_upload,
      ps_confirm: ps_confirm,
      // active_trip: ps_confirm === "return_application" ? false : true,
    };

    let url = `${backend_url}/traveller/confirmation/`;
    axios
      .patch(url, checkingInfo, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Confirmed Successfully!");
        setLoading(false);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.code === "ERR_NETWORK" && "Network error, please try again later."
        );
        setMessage(err.response.data?.details);
        setLoading(false);
      });
  }

  return (
    <div className="py-4 w-full max-w-3xl">
      <Typography
        component="h5"
        variant="p"
        className="px-4 py-16 text-lg font-bold"
      >
        TRAVEL CLEARANCE REQUEST SUMMARY FOR TRIP {id}
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableBody>
            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Application Ref: No
              </StyledTableCell>
              <StyledTableCell align="left">{id}</StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Date of application:
              </StyledTableCell>
              <StyledTableCell align="left">
                {format(new Date(trip.created_at), "dd-MMM-yyyy")}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Date Received:
              </StyledTableCell>
              <StyledTableCell align="left">
                {format(new Date(trip.updated_at), "dd-MMM-yyyy")}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Applicant:
              </StyledTableCell>
              <StyledTableCell align="left">
                {`${
                  trip.user.salutation !== undefined && trip.user.salutation
                }. ${trip.user.first_name}  ${trip.user.last_name}`}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Designation:
              </StyledTableCell>
              <StyledTableCell align="left">
                {trip.user.designation}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                MDA:
              </StyledTableCell>
              <StyledTableCell align="left">
                {trip.user.ministry}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Days Requested:
              </StyledTableCell>
              <StyledTableCell align="left">{`${days_rounded} days`}</StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Dates:
              </StyledTableCell>
              <StyledTableCell align="left">{`${format(
                new Date(depRet.date_of_dep),
                "dd-MMM-yyyy"
              )} -  ${format(
                new Date(depRet.date_of_return),
                "dd-MMM-yyyy"
              )}`}</StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                No. of Days Remaining on Applicants Docket:
              </StyledTableCell>
              <StyledTableCell align="left">
                {15 - trip.days_this_quarter}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Purpose for travel:
              </StyledTableCell>
              <StyledTableCell align="left">
                {/* {trip.reason_for_tr} */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: trip.reason_for_tr,
                  }}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Destination:
              </StyledTableCell>
              <StyledTableCell align="left">{trip.country}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Delegation Nos:
              </StyledTableCell>
              <StyledTableCell align="left">{delLength}</StyledTableCell>
            </StyledTableRow>
            {trip.hops_checking_confirm === "not_confirmed" ? (
              // <StyledTableRow>
              //   <StyledTableCell align="left" component="th" scope="row">
              //     Total Cost:
              //   </StyledTableCell>
              //   <StyledTableCell align="left">
              //     Participation Fee & Ticket Cost(KES):{" "}
              //     {sumsDollar[sumsDollar.length - 1].toLocaleString()} -
              //     Accomodation Cost($): {trip.accomo_cost}
              //   </StyledTableCell>
              // </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" component="th" scope="row">
                  Total Cost:
                </StyledTableCell>
                <StyledTableCell align="left">
                  Participation Fee & Ticket Cost(KES):{" "}
                  {/* {sumsDollar[sumsDollar.length - 1].toLocaleString()} - */}
                  {sums[sums.length - 1].toLocaleString()}
                  Accomodation Cost($):{" "}
                  {tot_cost_dollars
                    .reduce((acc, current) => acc + current, 0)
                    .toLocaleString()}
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              // <StyledTableRow>
              //   <StyledTableCell align="left" component="th" scope="row">
              //     Total Cost(KES):
              //   </StyledTableCell>
              //   <StyledTableCell align="left">
              //     {sumsKES[sumsKES.length - 1].toLocaleString()}
              //   </StyledTableCell>
              // </StyledTableRow>
              // <StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" component="th" scope="row">
                  Total Cost(KES):
                </StyledTableCell>
                <StyledTableCell align="left">
                  {sumsKES[sumsKES.length - 1].toLocaleString()}
                </StyledTableCell>
              </StyledTableRow>
            )}
            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Sponsor:
              </StyledTableCell>
              <StyledTableCell align="left">{trip.sponsor}</StyledTableCell>
            </StyledTableRow>
            {trip.chairperson_reason !== null &&
              (trip.chairperson_confirm === "accepted" ||
                trip.chairperson_confirm === "rejected") && (
                <StyledTableRow>
                  <StyledTableCell align="left" component="th" scope="row">
                    Chairperson Comments:
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {trip.chairperson_reason}
                  </StyledTableCell>
                </StyledTableRow>
              )}
            {/* {(trip.ps_confirm === "accepted" ||
              trip.ps_confirm === "rejected") && (
              <StyledTableRow>
                <StyledTableCell align="left" component="th" scope="row">
                  CS Comments:
                </StyledTableCell>
                <StyledTableCell align="left">
                  {trip.ps_reasons}
                </StyledTableCell>
              </StyledTableRow>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>

      {user && user.email !== trip.user.email && (
        <form
          name="checkingForm"
          noValidate
          className="flex flex-col justify-center w-full mt-4 sm:pt-4 sm:mt-8 mb-16"
          onSubmit={handleSubmit(submit)}
        >
          {openMessage === true && (
            <TravelSnackbar
              openMessage={openMessage}
              severity={severity}
              message={message}
              uploads={true}
            />
          )}

          <Controller
            name="ps_reasons"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Reasons for Concurring/Not Concurring and Return Application:"
                id="ps_reasons"
                variant="outlined"
                sx={{ p: 1 }}
                error={!!errors.ps_reasons}
                helperText={errors?.ps_reasons?.message}
                fullWidth
                multiline
                minRows={3}
              />
            )}
          />

          {/* {differenceInHours >= 24 && (
            <div>
              <Typography
                component="p"
                variant="p"
                className="p-8 sm:p-12 text-xs font-semibold"
              >
                This application is within the 7-day threshold. Please upload a
                justification for the delay on your part
              </Typography>

              <Controller
                control={control}
                name="ps_upload"
                render={({ field: { onChange, value } }) => (
                  <Box
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? lighten(theme.palette.background.default, 1)
                          : lighten(theme.palette.background.default, 0.02),
                    }}
                    component="label"
                    htmlFor="button_ps_upload"
                    className="flex items-center justify-center relative w-82 h-64 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
                  >
                    <input
                      accept=".jpeg, .jpg, .png, .pdf,"
                      className="hidden"
                      id="button_ps_upload"
                      type="file"
                      onChange={(e) => onChange(e.target.files[0])}
                    />
                    <IconButton>
                      <FileUploadIcon sx={{ color: "action" }} />
                    </IconButton>

                    <Typography
                      variant="p"
                      component="p"
                      className="text-xs font-small pl-8 pr-16"
                    >
                      {value === null ? (
                        <span> Justification Late Letter Upload</span>
                      ) : (
                        value.name
                      )}
                    </Typography>
                    <FormHelperText>
                      {" "}
                      <span className="flex flex-col text-red-500">
                        {errors?.ps_upload?.message}
                      </span>
                    </FormHelperText>
                  </Box>
                )}
              />
            </div>
          )} */}

          <div className="flex items-center mt-24 sm:mt-0 sm:mx-8 space-x-12">
            <Button
              className="whitespace-nowrap"
              variant="contained"
              color="primary"
              onClick={() => {
                setPSConfirm("return_application");
                setOpenMessage(false);
              }}
              startIcon={<AssignmentReturnIcon />}
              type="submit"
            >
              {loading === true ? <Loading /> : "Return Application"}
            </Button>

            <Button
              className="whitespace-nowrap"
              variant="contained"
              color="error"
              onClick={() => {
                setPSConfirm("rejected");
                setOpenMessage(false);
              }}
              startIcon={<DoDisturbIcon />}
              type="submit"
            >
              {loading === true ? <Loading /> : "Not Concurred"}
            </Button>

            <Button
              className="whitespace-nowrap"
              variant="contained"
              color="success"
              onClick={() => {
                setPSConfirm("accepted");
                setOpenMessage(false);
              }}
              startIcon={<CheckCircleOutlineIcon />}
              type="submit"
            >
              {loading === true ? <Loading /> : "Concurred"}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
}
