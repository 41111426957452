import { useState, useEffect } from "react";
import TravelForm from "./Form";
import { backend_url } from "../components/Constants";

export default function FormConfig(props) {
  const { token, user, trip_id, setOpen } = props;
  const [trip, setTrip] = useState([]);
  const { trip_data, acting_data, delegate_data, depature_and_return_data } =
    trip;

  useEffect(() => {
    fetch(
      `${backend_url}/traveller/approvals/?` +
        new URLSearchParams({
          trip: trip_id,
        }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setTrip(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [token, trip_id]);

  return (
    trip &&
    trip_data && (
      <>
        {trip_data[0].active_trip === false ? (
          trip_data.length === 0 ? (
            <TravelForm
              setOpen={setOpen}
              trip_id={trip_id}
              returnStep={0}
              user={user}
              token={token}
              trip_data={trip_data}
              delegate_data={delegate_data}
              depature_and_return_data={depature_and_return_data}
            />
          ) : depature_and_return_data.length === 0 ? (
            <TravelForm
              setOpen={setOpen}
              trip_id={trip_id}
              returnStep={1}
              user={user}
              token={token}
              trip_data={trip_data}
              delegate_data={delegate_data}
              depature_and_return_data={depature_and_return_data}
            />
          ) : user.designation !== "CHAIRPERSON" && acting_data.length === 0 ? (
            <TravelForm
              setOpen={setOpen}
              trip_id={trip_id}
              returnStep={2}
              user={user}
              token={token}
              trip_data={trip_data}
              delegate_data={delegate_data}
              depature_and_return_data={depature_and_return_data}
            />
          ) : delegate_data.length === 0 ? (
            <TravelForm
              setOpen={setOpen}
              trip_id={trip_id}
              returnStep={3}
              user={user}
              token={token}
              trip_data={trip_data}
              delegate_data={delegate_data}
              depature_and_return_data={depature_and_return_data}
            />
          ) : (
            delegate_data.length >= 1 && (
              <TravelForm
                setOpen={setOpen}
                trip_id={trip_id}
                returnStep={4}
                user={user}
                token={token}
                trip_data={trip_data}
                delegate_data={delegate_data}
                depature_and_return_data={depature_and_return_data}
              />
            )
          )
        ) : (
          <TravelForm
            setOpen={setOpen}
            trip_id={trip_id}
            returnStep={0}
            user={user}
            token={token}
            trip_data={trip_data}
            delegate_data={delegate_data}
            depature_and_return_data={depature_and_return_data}
          />
        )}
      </>
    )
  );
}
