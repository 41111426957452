import { useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { lighten } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import TravelSnackbar from "../../components/TravelSnackbar";
import Loading from "../../components/Loading";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { backend_url } from "../../components/Constants";
import { useNavigate } from "react-router-dom";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";

export default function Appeal(props) {
  const { token, id, setAppealOpen, appeal_open, handleClose } = props;
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    appeal_text: yup.string().required("This field is required"),
    appeal_upload: yup.mixed().required("Please upload an appeal letter."),
  });

  const defaultValues = {
    appeal_upload: null,
    appeal_text: "",
  };

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    // isValid, dirtyFields,
    errors,
  } = formState;

  const navigate = useNavigate();
  function submit({ appeal_text, appeal_upload }) {
    const checkingInfo = {
      trip_id: id,
      appeal_text,
      appeal_upload,
      active_trip: true,
      //   ps_confirm: ps_confirm,
    };

    let url = `${backend_url}/traveller/appeal/`;
    axios
      .patch(url, checkingInfo, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Appealed Successfully!");
        setAppealOpen(false);
        setLoading(false);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.code === "ERR_NETWORK" && "Network error, please try again later."
        );
        setMessage(err.response.data?.details);
        setLoading(false);
      });
  }

  return (
    <Dialog open={appeal_open} onClose={handleClose} fullWidth>
      <div className="py-4 w-full max-w-3xl">
        <DialogTitle>
          <Typography
            component="h5"
            variant="p"
            className="px-4 py-16 text-lg font-bold"
          >
            Make an appeal for this trip
          </Typography>
        </DialogTitle>

        <DialogContent>
          <form
            name="appealForm"
            noValidate
            className="flex flex-col justify-center w-full mt-4 sm:pt-4 sm:mt-8 mb-16"
            onSubmit={handleSubmit(submit)}
          >
            {openMessage === true && (
              <TravelSnackbar
                openMessage={openMessage}
                severity={severity}
                message={message}
                uploads={true}
              />
            )}

            <Controller
              name="appeal_text"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Appeal reason:"
                  id="appeal_text"
                  variant="outlined"
                  sx={{ p: 1 }}
                  error={!!errors.appeal_text}
                  helperText={errors?.appeal_text?.message}
                  fullWidth
                  multiline
                  minRows={3}
                />
              )}
            />

            <div>
              <Typography
                component="p"
                variant="p"
                className="p-8 sm:p-12 text-xs font-semibold"
              >
                Make an appeal for your travel application.
              </Typography>

              <Controller
                control={control}
                name="appeal_upload"
                render={({ field: { onChange, value } }) => (
                  <Box
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? lighten(theme.palette.background.default, 1)
                          : lighten(theme.palette.background.default, 0.02),
                    }}
                    component="label"
                    htmlFor="button_appeal_upload"
                    className="flex items-center justify-center relative w-82 h-64 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
                  >
                    <input
                      accept=".jpeg, .jpg, .png, .pdf,"
                      className="hidden"
                      id="button_appeal_upload"
                      type="file"
                      onChange={(e) => onChange(e.target.files[0])}
                    />
                    <IconButton>
                      <FileUploadIcon sx={{ color: "action" }} />
                    </IconButton>

                    <Typography
                      variant="p"
                      component="p"
                      className="text-xs font-small pl-8 pr-16"
                    >
                      {value === null ? (
                        <span> Appeal Letter Upload</span>
                      ) : (
                        value.name
                      )}
                    </Typography>
                    <FormHelperText>
                      {" "}
                      <span className="flex flex-col text-red-500">
                        {errors?.appeal_upload?.message}
                      </span>
                    </FormHelperText>
                  </Box>
                )}
              />
            </div>

            <div className="flex items-center mt-24 sm:mt-0 sm:mx-8 space-x-12">
              <Button
                className="whitespace-nowrap"
                variant="contained"
                color="error"
                onClick={() => {
                  setOpenMessage(false);
                  handleClose();
                }}
                startIcon={<AssignmentReturnIcon />}
              >
                {loading === true ? <Loading /> : "Cancel"}
              </Button>

              <Button
                className="whitespace-nowrap"
                variant="contained"
                color="success"
                onClick={() => {
                  setOpenMessage(false);
                }}
                startIcon={<CheckCircleOutlineIcon />}
                type="submit"
              >
                {loading === true ? <Loading /> : "Submit Appeal"}
              </Button>
            </div>
          </form>
        </DialogContent>
      </div>
    </Dialog>
  );
}
