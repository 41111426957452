import React, { useEffect, useState } from "react";
import format from "date-fns/format";
import Button from "@mui/material/Button";
import { Container, Paper, Typography } from "@mui/material";
import "../styles/approval.css";
import logo1 from "../assets/coat.png";
import { backend_url } from "./Constants";
import { Link, useParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function VerifyQr() {
  const [verify, setVerify] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    fetch(
      `${backend_url}/traveller/qr/?` +
        new URLSearchParams({
          trip: id,
        })
    )
      .then((res) => res.json())
      .then((data) => {
        setVerify(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    verify &&
    verify !== undefined && (
      <Container component="main" maxWidth="lg">
        <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-16 mt-56">
          <Paper className="flex w-full sm:w-auto min-h-full sm:min-h-auto md:w-full md:max-w-6xl rounded-0 sm:rounded-2xl sm:shadow overflow-hidden">
            <div className="w-full sm:w-auto py-32 px-16 ltr:border-r-1 rtl:border-l-1">
              <div className="w-full max-w-320">
                <div className="grid grid-cols-12 gap-x-0 my-0 ">
                  <div className="col-span-2 self-left text-left justify-left" />
                  <div className="col-span-8 self-left text-left justify-left">
                    <div className="flex flex-col pb-2 max-w-xs">
                      <img className="w-full sm:w-64" src={logo1} alt="logo" />
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-12 gap-x-0 my-0 ">
                  <div className="col-span-0 self-left text-left justify-left" />
                  <div className="col-span-12 self-left text-left justify-left">
                    <div className="flex flex-col pb-2 max-w-xs">
                      <Typography variant="p" className="text-left  font-bold">
                        EXECUTIVE OFFICE OF THE PRESIDENT
                      </Typography>
                      <Typography variant="p" className="text-left font-bold">
                        CHIEF OF STAFF AND HEAD OF THE PUBLIC SERVICE
                      </Typography>
                    </div>
                  </div>
                </div>

                <br />

                <div className="flex flex-row flex-auto">
                  <div className="text-6xl w-96 ">
                    <CheckCircleOutlineIcon
                      fontSize="inherit"
                      className=" text-green-500 dark:text-green-400"
                    />
                  </div>

                  <div className=" -ml-48 mt-12">
                    <Button
                      variant="text"
                      color="success"
                      className=""
                      aria-label="Sign in"
                      type="submit"
                      size="large"
                    >
                      Verified to travel
                    </Button>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-12 gap-x-4 mx-4 ">
                <div className="col-span-12 self-center tracking-tight">
                  <div className="flex flex-col">
                    <Typography variant="p" className="text-left p-1 ">
                      Clearance has been granted for officer with their
                      delegate/s to officially travel outside the country:
                    </Typography>

                    <Typography variant="p" className="text-left p-1  ">
                      <span style={{ fontWeight: "bold" }}>ID Number:</span>{" "}
                      {` ${verify.id_number}`}
                    </Typography>
                    <Typography variant="p" className="text-left p-1  ">
                      <span style={{ fontWeight: "bold" }}>
                        Passport Number:
                      </span>{" "}
                      {` ${verify.passport_no}`}
                    </Typography>

                    <Typography variant="p" className="text-left p-1  ">
                      <span style={{ fontWeight: "bold" }}>Hon.</span>{" "}
                      {`Hon. ${verify.first_name} ${verify.last_name}`}
                    </Typography>
                    {verify.designation !== undefined && (
                      <Typography variant="p" className="text-left p-1  ">
                        <span style={{ fontWeight: "bold" }}>Designation:</span>{" "}
                        {` ${verify.designation
                          .toLowerCase()
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}`}
                      </Typography>
                    )}
                    <Typography
                      variant="p"
                      className="capitalize text-left p-1  "
                    >
                      <span style={{ fontWeight: "bold" }}>Ministry:</span>{" "}
                      {verify.ministry !== undefined &&
                        verify.ministry
                          .toLowerCase()
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                    </Typography>
                    <Typography variant="p" className="text-left p-1  ">
                      <span style={{ fontWeight: "bold" }}>
                        Country travelling to:
                      </span>{" "}
                      {` ${verify.country}`}
                    </Typography>
                    {verify.date_of_dep && (
                      <Typography variant="p">
                        <span style={{ fontWeight: "bold" }}>From:</span>
                        {` ${format(
                          new Date(verify.date_of_dep),
                          "dd-MMM-yyyy"
                        )}`}
                      </Typography>
                    )}

                    {verify.date_of_return && (
                      <Typography variant="p">
                        <span style={{ fontWeight: "bold" }}>To:</span>
                        {` ${format(
                          new Date(verify.date_of_return),
                          "dd-MMM-yyyy"
                        )}`}
                      </Typography>
                    )}

                    <Typography variant="p" className="text-left p-1  ">
                      <span style={{ fontWeight: "bold" }}>
                        Reason for travel:
                      </span>{" "}
                      {` ${verify.reason_for_tr}`}
                    </Typography>

                    <Typography
                      variant="body2"
                      className="text-left p-1 pt-12 "
                    >
                      <span style={{ fontWeight: "bold" }}>Signature:</span>{" "}
                      {` ${verify.signed}`}
                    </Typography>
                  </div>
                </div>

                <div className="col-span-0 " />

                <div className="col-span-0 self-right text-right justify-end" />
              </div>

              <div className="pt-12 text-xs font-small ">
                <Link className="" to="/">
                  <Button variant="contained" color="primary">Home</Button>
                </Link>
              </div>
            </div>
          </Paper>
        </div>
      </Container>
    )
  );
}
