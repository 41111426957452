import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '../Navbar';
import { DrawerHeader } from './DrawerHeader';
import { DrawerList } from './DrawerList';
import { Hidden } from '@mui/material';

const drawerWidth = 280;

export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        // padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        paddingLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            paddingLeft: `${drawerWidth}px`,
        }),
    }),
);

export default function DrawerLeft(props) {
    const { window, handleDrawerToggle, user, open } = props;
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box
            sx={{
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    backgroundColor: 'primary.light',
                },
            }}
        >
            <AppBar user={user} open={open} handleDrawerToggle={handleDrawerToggle} />

            <Hidden lgDown>
                <Drawer
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    container={container}
                    variant="persistent"
                    anchor="left"
                    onClose={handleDrawerToggle}
                    open={open}
                >
                    <DrawerHeader user={user} open={open} handleDrawerToggle={handleDrawerToggle} />
                    <DrawerList user={user} />
                </Drawer>
            </Hidden>

            <Hidden lgUp>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader user={user} open={open} handleDrawerToggle={handleDrawerToggle} />
                    <DrawerList user={user} />
                </Drawer>
            </Hidden>

            {props.children}
        </Box>
    );
}
