import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Box, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

// Convert Draft.js editor state to HTML
const convertEditorStateToHTML = (editorState) => {
  const contentState = editorState.getCurrentContent();
  return draftToHtml(convertToRaw(contentState));
};

// Convert HTML to Draft.js editor state
const convertHTMLToEditorState = (html) => {
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  return EditorState.createWithContent(contentState);
};

// Reusable Draft WYSIWYG Editor component
const DraftWysiwygEditor = ({
  control,
  name,
  label,
  errors,
  defaultValue = "",
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (defaultValue) {
      setEditorState(convertHTMLToEditorState(defaultValue));
    }
  }, [defaultValue]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={convertEditorStateToHTML(editorState)}
      render={({ field: { onChange, onBlur, value } }) => (
        <Box
          sx={{ p: 1, border: "1px solid rgba(0, 0, 0, 0.3)", borderRadius: 1 }}
        >
          <Editor
            placeholder={label}
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={(newState) => {
              setEditorState(newState);
              onChange(convertEditorStateToHTML(newState)); // Store HTML in form state
            }}
            onBlur={onBlur}
            // ref={ref}
          />
          {errors[name] && (
            <FormHelperText error>{errors[name].message}</FormHelperText>
          )}
        </Box>
      )}
    />
  );
};

export default DraftWysiwygEditor;
