import {
  Paper,
  Divider,
  Box,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { FormHeaderDetail } from "../../forms/details/FormHeader";
import { PersonalDetails } from "../../forms/details/Personal";
import { TravelDetails } from "../../forms/details/Travel";
import { DepartureReturnDetails } from "../../forms/details/Departure_Return";
import { DelegationSizeDetails } from "../../forms/details/Delegation";
import NavLinkAdapter from "../../components/NavLinkAdapter";
import { HistandStats } from "../../forms/details/HistandStats";
import { backend_url } from "../../components/Constants";
import HESummary from "./HESummary";
import axios from "axios";
import Loading from "../../components/Loading";
import TravelSnackbar from "../../components/TravelSnackbar";
import { Private } from "../../forms/details/Private";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function HEConfirmation(props) {
  const { token, setOpen, user } = props;
  const { id } = useParams();
  const [trip, setTrip] = useState([]);
  const [value, setValue] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  const {
    trip_data,
    acting_data,
    delegate_data,
    depature_and_return_data,
    digitalsignature,
  } = trip;

  const [he_confirm, setHE_confirm] = useState(undefined);
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [chief_reason, setChief_reason] = useState("");

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setValue(0);
    setOpen(false);
  }, [location, setOpen]);

  useEffect(() => {
    fetch(
      `${backend_url}/traveller/approvals/?` +
        new URLSearchParams({
          trip: id,
        }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setTrip(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [token, id]);

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();

    const CSInfo = {
      trip_id: id,
      he_confirm: he_confirm,
      he_reason: chief_reason,
      reviewer: he_confirm === "accepted" ? true : false,
    };

    let url = `${backend_url}/traveller/confirmation/`;
    axios
      .patch(url, CSInfo, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Confirmed Successfully!");
        setLoading(false);
        setChief_reason("");
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.response.data?.details
            ? err.response.data?.details
            : err.response.data?.error
        );
        setLoading(false);
      });
  };

  return (
    <div className="relative flex flex-col flex-auto items-center px-4 sm:px-8 container mx-auto">
      <Paper className="w-full px-24 sm:px-32 py-32" elevation={1}>
        {trip_data && trip_data.length > 0 && (
          <div>
            <div className="flex flex-col justify-center w-full pt-16 mt-64 mb-16">
              <Button
                className="font-semibold"
                component={NavLinkAdapter}
                to="/"
                size="large"
                color="primary"
                onClick={() => (isMobile ? setOpen(false) : setOpen(true))}
                variant="contained"
              >
                <CloseIcon />
                <span className="mx-8">Close Application</span>
              </Button>
            </div>

            {openMessage === true && (
              <TravelSnackbar
                openMessage={openMessage}
                severity={severity}
                message={message}
                uploads={true}
              />
            )}

            {trip_data[0].trip_type === "private" ? (
              <div className="flex flex-col justify-center w-full pt-16 mt-12 mb-16">
                <Private editForm={true} trip={trip_data[0]} />

                <form
                  name="ConfirmationForm"
                  noValidate
                  className="flex flex-col justify-center w-full "
                  onSubmit={handleSubmit}
                >
                  {trip_data[0].he_confirm !== "accepted" && (
                    <>
                      {trip_data[0].chairperson_confirm === "accepted" && (
                        <>
                          {trip_data[0].chairperson_reason !== null &&
                            (trip_data[0].chairperson_confirm === "accepted" ||
                              trip_data[0].chairperson_confirm ===
                                "accepted") && (
                              <Typography
                                component="p"
                                variant="p"
                                className="text-xs font-bold py-12"
                              >
                                Chairperson comments:{" "}
                                <span className="pl-10 text-xs font-normal">
                                  {trip_data[0].chairperson_reason}
                                </span>
                              </Typography>
                            )}

                          {trip_data[0].ps_reasons !== null &&
                            (trip_data[0].ps_confirm === "accepted" ||
                              trip_data[0].ps_confirm === "rejected") && (
                              <Typography
                                component="p"
                                variant="p"
                                className="text-xs font-bold py-12"
                              >
                                PS comments:{" "}
                                <span className="pl-10 text-xs font-normal">
                                  {trip_data[0].ps_reasons}
                                </span>
                              </Typography>
                            )}

                          {trip_data[0].cs_reasons !== null &&
                            (trip_data[0].cs_confirm === "accepted" ||
                              trip_data[0].cs_confirm === "rejected") && (
                              <Typography
                                component="p"
                                variant="p"
                                className="text-xs font-bold py-12"
                              >
                                CS comments:{" "}
                                <span className="pl-10 text-xs font-normal">
                                  {trip_data[0].cs_reasons}
                                </span>
                              </Typography>
                            )}

                          {trip_data[0].hops_checking_reasons !== null &&
                            (trip_data[0].hops_checking_confirm ===
                              "accepted" ||
                              trip_data[0].hops_checking_confirm ===
                                "rejected") && (
                              <>
                                <Typography
                                  component="p"
                                  variant="p"
                                  className="text-xs font-bold py-12"
                                >
                                  HOPS reviewer comments:{" "}
                                  {/* <span className="pl-10 text-xs font-normal">
                                    {trip_data[0].hops_checking_reasons}
                                  </span> */}
                                </Typography>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: trip_data[0].hops_checking_reasons,
                                  }}
                                />
                              </>
                            )}

                          {trip_data[0].hops_checking_recommendation &&
                            (trip_data[0].hops_checking_confirm ===
                              "accepted" ||
                              trip_data[0].hops_checking_confirm ===
                                "rejected") && (
                              <Typography
                                component="p"
                                variant="p"
                                className="text-xs font-bold py-12"
                              >
                                HOPS reviewer recommendations:{" "}
                                <span className="pl-10 text-xs font-normal">
                                  {trip_data[0].hops_checking_recommendation}
                                </span>
                              </Typography>
                            )}
                        </>
                      )}

                      <TextField
                        label="Comments"
                        id="Comments"
                        variant="outlined"
                        sx={{ my: 4 }}
                        value={chief_reason}
                        multiline
                        minRows={3}
                        fullWidth
                        onChange={(e) => setChief_reason(e.target.value)}
                      />
                      <div className="flex items-center mt-24 mb-16 sm:mt-0 sm:mx-8 space-x-12">
                        <Button
                          className="whitespace-nowrap"
                          variant="contained"
                          color="error"
                          onClick={() => {
                            setHE_confirm("rejected");
                            setOpenMessage(false);
                          }}
                          startIcon={<DoDisturbIcon />}
                          type="submit"
                        >
                          {loading === true ? <Loading /> : "Reject"}
                        </Button>

                        <Button
                          className="whitespace-nowrap"
                          variant="contained"
                          color="success"
                          onClick={() => {
                            setHE_confirm("accepted");
                            setOpenMessage(false);
                          }}
                          startIcon={<CheckCircleOutlineIcon />}
                          type="submit"
                        >
                          {loading === true ? <Loading /> : "Approve"}
                        </Button>
                      </div>
                    </>
                  )}
                </form>
              </div>
            ) : (
              <>
                <Divider />

                <div>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      textColor="secondary"
                      indicatorColor="secondary"
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab label="Travel Summary" {...a11yProps(0)} />
                      <Tab
                        label="Annextures and Personal Details"
                        {...a11yProps(1)}
                      />
                      <Tab label="Travel Details" {...a11yProps(2)} />
                      <Tab
                        label="Departure and Return Details"
                        {...a11yProps(3)}
                      />
                      <Tab
                        label="Costs and Size of Delegation"
                        {...a11yProps(4)}
                      />
                      {/* <Tab label="Confirmation Details" {...a11yProps(5)} /> */}
                      <Tab
                        label="Travel History and Statistics"
                        {...a11yProps(5)}
                      />
                    </Tabs>
                  </Box>

                  <CustomTabPanel value={value} index={0}>
                    {trip_data[0].he_confirm !== "accepted" && (
                      <>
                        <HESummary
                          token={token}
                          setOpen={setOpen}
                          id={id}
                          setHE_confirm={setHE_confirm}
                          he_confirm={he_confirm}
                          trip={trip_data[0]}
                          depRet={depature_and_return_data[0]}
                          delegate_data={delegate_data}
                        />
                      </>
                    )}
                  </CustomTabPanel>

                  <CustomTabPanel value={value} index={1}>
                    <FormHeaderDetail
                      trip={trip_data[0]}
                      he_confirm={he_confirm}
                    />
                    <PersonalDetails
                      trip={trip_data[0]}
                      actingOfficer={acting_data[0]}
                    />
                  </CustomTabPanel>

                  <CustomTabPanel value={value} index={2}>
                    <TravelDetails trip={trip_data[0]} />
                  </CustomTabPanel>

                  <CustomTabPanel value={value} index={3}>
                    <DepartureReturnDetails
                      trip={trip_data[0]}
                      depRet={depature_and_return_data[0]}
                      token={token}
                    />
                  </CustomTabPanel>

                  <CustomTabPanel value={value} index={4}>
                    <DelegationSizeDetails
                      trip={trip_data[0]}
                      depRet={depature_and_return_data[0]}
                      delegates={delegate_data}
                    />
                  </CustomTabPanel>

                  {/* <CustomTabPanel value={value} index={5}>
                <ConfirmationDetails
                  trip={trip_data[0]}
                  digitalsignature={digitalsignature}
                />
              </CustomTabPanel> */}

                  <CustomTabPanel value={value} index={5}>
                    <HistandStats
                      token={token}
                      user={user}
                      trip={trip_data[0]}
                      digitalsignature={digitalsignature}
                    />
                  </CustomTabPanel>
                </div>
              </>
            )}

            {/* <Divider sx={{ py: 2, my: 2 }} />

  <ConcurrenceDetails trip={trip} />

  <Divider sx={{ py: 2, my: 2 }} />

  <ApprovalDetails trip={trip} /> */}
          </div>
        )}

        <Divider sx={{ my: 10 }} />
      </Paper>
    </div>
  );
}
