import React from "react";
import TableContainer from "@mui/material/TableContainer";
import jsPDF from "jspdf";
import format from "date-fns/format";
import Button from "@mui/material/Button";
import html2canvas from "html2canvas";
import { QRCodeCanvas } from "qrcode.react";
import { Divider, Typography } from "@mui/material";
import "../styles/approval.css";
import logo1 from "../assets/coat.png";

export default function ApprovedForm(props) {
  const {
    privateTrip,
    cs_first_name,
    cs_last_name,
    digitalsignature,
    trip,
    delegates,
    depRet,
  } = props;

  const days =
    (new Date(depRet.date_of_return).getTime() -
      new Date(depRet.date_of_dep).getTime()) /
    (1000 * 3600 * 24);
  const days_rounded =
    days.toString().charAt(1) === "."
      ? parseInt(days.toString().charAt(0)) + 1
      : days;

  const exchange_rate = trip?.accomo_cost / trip?.gok_cost / days_rounded;

  function printDocument() {
    const input = document.getElementById("pdfdiv");

    if (!input) {
      console.error("Element with id 'pdfdiv' not found.");
      return;
    }

    try {
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      const options = {
        callback: function (pdf) {
          pdf.save("approval_letter.pdf");
        },
        margin: [20, 1, 20, 1],
        html2canvas: {
          scale: 0.2, // Higher scale for better quality
          allowTaint: true,
          dpi: 300,
          letterRendering: true,
          logging: false,
        },
        x: 0,
        y: 0,
        width: 200, // Match imgWidth from the original function
        windowWidth: 1024, // Match the original width for consistency
      };

      pdf.html(input, options);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  }

  return (
    <div className="approval-font min-w-lg px-32 py-32">
      <TableContainer id="pdfdiv" className="txt ">
        <div className="px-48 ">
          <div className="grid grid-cols-12 gap-x-4 mt-16 ">
            <div className="col-span-2 self-left text-left justify-left" />
            <div className="col-span-7 self-center text-center justify-center">
              <img src={logo1} className="font-bold h-64" alt="logo" />
            </div>
            <div className="col-span-3 self-right text-right justify-right" />
          </div>

          <div className="grid grid-cols-12 gap-x-4 my-16 ">
            <div className="col-span-3 self-left text-left justify-left" />
            <div className="col-span-6 self-center text-center justify-center">
              <div className="flex flex-col pb-12 max-w-xs">
                <Typography variant="p" className="text-center  font-bold">
                  EXECUTIVE OFFICE OF THE PRESIDENT
                </Typography>
                <Typography variant="p" className="text-center font-bold">
                  CHIEF OF STAFF AND HEAD OF THE PUBLIC SERVICE
                </Typography>
              </div>
            </div>

            <div className="col-span-3 self-right text-right justify-right" />
          </div>

          <div className="grid grid-cols-12 gap-x-4 mx-4 ">
            <div className="col-span-6 self-center tracking-tight">
              <div className="flex flex-col">
                <Typography variant="p" className="font-bold">
                  REF No. OP/CAB:{trip.trip_id}
                </Typography>
                <Typography variant="p" className="text-left  ">
                  {`${cs_first_name} ${cs_last_name}`}
                </Typography>
                <Typography variant="p" className="text-left  ">
                  Cabinet Secretary
                </Typography>
                <Typography variant="p" className="text-left  ">
                  {trip.user.ministry
                    .toLowerCase()
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </Typography>
                <Typography variant="p" className="text-left  underline">
                  NAIROBI.
                </Typography>
              </div>
            </div>

            <div className="col-span-2 " />

            <div className="col-span-4 self-left text-center justify-end">
              <Typography variant="p" className="font-bold">
                {format(new Date(trip.updated_at), "dd-MMM-yyyy")}
              </Typography>
            </div>
          </div>

          <div className="flex flex-col pt-12 pb-4">
            <Typography variant="p" className="text-left  ">
              Dear CS,
            </Typography>
          </div>

          <div className="flex flex-col py-4">
            <Typography variant="p" className="text-left   font-bold">
              RE: TRAVEL CLEARANCE
            </Typography>
            <Divider sx={{ py: 2 }} />
          </div>

          <div className="flex flex-col py-4">
            <Typography variant="p" className="text-left  ">
              Reference is made to your travel application Ref. No. OP/CAB:
              {`${trip.trip_id} `}
              dated {format(new Date(trip.created_at), "dd-MMM-yyyy")} on the
              above subject.{" "}
            </Typography>
          </div>

          <div className="py-4">
            <Typography variant="p" className="">
              {privateTrip
                ? `Clearance has been granted for ${trip.user.first_name} ${
                    trip.user.last_name
                  }, ${trip.user.designation}, ${
                    trip.user.designation === "CEO"
                      ? trip.user.institutions
                          .toLowerCase()
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")
                      : trip.user.designation === "CHAIRPERSON"
                      ? trip.user.institutions
                          .toLowerCase()
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")
                      : trip.user.designation === "PS"
                      ? trip.user.State_departments.toLowerCase()
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")
                      : trip.user.designation === "CS" &&
                        trip.user.ministry
                          .toLowerCase()
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")
                  } to travel to ${trip.country} from ${format(
                    new Date(trip.program_from),
                    "dd-MMM-yyyy"
                  )} to ${format(
                    new Date(trip.program_to),
                    "dd-MMM-yyyy"
                  )} for a private visit.`
                : `Clearance has been granted
              for the following officers to travel outside the country
              officially from  `}
            </Typography>
            {!privateTrip && (
              <>
                <Typography variant="p" className="font-bold">
                  {`${format(new Date(depRet.date_of_dep), "dd-MMM-yyyy")} to
              ${format(new Date(depRet.date_of_return), "dd-MMM-yyyy")}.  `}
                </Typography>
                {/* <Typography variant="p" className="">
                  {` to attend
              the `}
                </Typography>
                <Typography variant="p" className="font-bold ">
                  {`${trip.reason_for_tr}.`}
                </Typography> */}
              </>
            )}
          </div>

          {!privateTrip && (
            <table className="travel-table">
              <thead>
                <tr>
                  <th className="approval-font">S.No</th>
                  <th className="approval-font">Name</th>
                  <th className="approval-font">Duration</th>
                  <th className="approval-font">Country</th>
                  <th className="approval-font">Purpose of Travel</th>
                  <th className="approval-font">Travel Expenses (KES)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="approval-font">1</td>
                  <td className="approval-font">
                    {" "}
                    {`${
                      trip.user.salutation !== undefined && trip.user.salutation
                    }. ${trip.user.first_name} ${trip.user.last_name}`}
                  </td>
                  <td className="approval-font">
                    {`${format(new Date(depRet.date_of_dep), "dd-MMM-yyyy")} to
              ${format(new Date(depRet.date_of_return), "dd-MMM-yyyy")}  `}
                  </td>
                  <td className="approval-font"> {trip.country}</td>
                  <td className="approval-font">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: trip.reason_for_tr,
                      }}
                    />
                    {/* {trip.reason_for_tr} */}
                  </td>
                  <td className="approval-font">
                    {(
                      parseInt(trip.accomo_cost) +
                      parseInt(trip.ticket_cost) +
                      parseInt(trip.participant_cost)
                    ).toLocaleString()}
                  </td>
                </tr>
                {!privateTrip &&
                  delegates.map((delegate, index) => (
                    <tr key={delegate.id} className="approval-font">
                      <td className="approval-font">
                        {" "}
                        {index === 0 ? index + 2 : index + 2}
                      </td>
                      <td className="approval-font"> {delegate.full_name}</td>
                      <td className="approval-font">
                        {" "}
                        {format(new Date(depRet.date_of_dep), "dd-MMM-yyyy")} -
                        {format(new Date(depRet.date_of_return), "dd-MMM-yyyy")}
                      </td>
                      <td className="approval-font">{trip.country}</td>
                      <td className="approval-font">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: trip.reason_for_tr,
                          }}
                        />
                        {/* {trip.reason_for_tr} */}
                      </td>
                      <td className="approval-font">
                        {(
                          parseInt(delegate.accomo_cost * exchange_rate) +
                          parseInt(delegate.ticket_cost) +
                          parseInt(delegate.participant_cost)
                        ).toLocaleString()}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}

          <div className="flex flex-col py-16">
            {privateTrip ? (
              <Typography variant="p" className="text-left  ">
                It is noted that the <b> trip is self-sponsored.</b>
              </Typography>
            ) : (
              <Typography variant="p" className="text-left  ">
                It is noted that {trip.sponsor} will meet the travel expenses
                relating to the trip.
              </Typography>
            )}
          </div>

          <div className="flex flex-col pt-4">
            <Typography variant="p" className="text-left  ">
              Yours Sincerely
            </Typography>
          </div>

          <div className="flex flex-col py-2">
            <Typography variant="p" className="text-left  ">
              Felix K. Koskei
            </Typography>
            <Typography variant="p" className="text-left   font-bold">
              CHIEF OF STAFF AND HEAD OF THE PUBLIC SERVICE
            </Typography>
          </div>

          <div className="grid grid-cols-12 gap-x-4 mt-32">
            <div className="col-span-2 self-left text-left justify-left" />
            <div className="col-span-8 self-center text-center justify-center">
              <QRCodeCanvas
                size={128}
                value={`https://fotims.headofpublicservice.go.ke/verify/${trip.trip_id}`}
              />
            </div>

            <div className="col-span-2 self-right text-right justify-right" />
          </div>

          <div className="grid grid-cols-12 gap-x-4 mt-8 ">
            <div className="col-span-2 self-left text-left justify-left" />
            <div className="col-span-8 self-center text-center justify-center">
              <Typography variant="caption" className=" font-10">
                {digitalsignature}
              </Typography>
            </div>

            <div className="col-span-2 self-right text-right justify-right" />
          </div>
        </div>
      </TableContainer>

      <div className="grid grid-cols-12 gap-x-4 mt-16 ">
        <div className="col-span-4 self-left text-left justify-left" />
        <div className="col-span-4 self-center text-center justify-center">
          <div className="flex flex-col py-12 max-w-xs">
            <Button onClick={printDocument} variant="contained" color="primary">
              Download Approval Letter Pdf
            </Button>
          </div>
        </div>

        <div className="col-span-4 self-right text-right justify-right" />
      </div>
    </div>
  );
}
