import { useState, useEffect } from "react";
import FormConfig from "./FormConfig";
import TravelForm from "./Form";
import { backend_url } from "../components/Constants";

export default function TravelFormConfig(props) {
  const { token, user, setOpen } = props;
  const [trip, setTrip] = useState([]);

  useEffect(() => {
    fetch(`${backend_url}/traveller/details/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTrip(data.results[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  return trip && trip.trip_type === "public" ? (
    trip.trip_id ? (
      <FormConfig
        setOpen={setOpen}
        trip_id={trip.trip_id}
        token={token}
        user={user}
      />
    ) : (
      <TravelForm setOpen={setOpen} returnStep={0} user={user} token={token} />
    )
  ) : (
    <TravelForm setOpen={setOpen} returnStep={0} user={user} token={token} />
  );
}
