import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import * as yup from "yup";
// import _ from "../../@lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import TravelSnackbar from "../../components/TravelSnackbar";
import Loading from "../../components/Loading";
import { backend_url } from "../../components/Constants";

export default function Part4A(props) {
  const { token, trip_data, depature_and_return_data } = props;
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingIcon, setLoadingIcon] = useState(false);
  // const [dollar_ksh, setDollarKsh] = useState(
  //   trip_data.sponsor_status === "not_sponsored" ? 0 : null
  // );

  const country = trip_data.country;
  const job_group = trip_data.user.job_group;
  const days =
    depature_and_return_data &&
    (new Date(depature_and_return_data.date_of_return).getTime() -
      new Date(depature_and_return_data.date_of_dep).getTime()) /
      (1000 * 3600 * 24);
  const days_rounded =
    days && days.toString().charAt(1) === "."
      ? parseInt(days.toString().charAt(0)) + 1
      : days;
  const src_rate = trip_data.gok_cost;
  // const per_diem =
  //   dollar_ksh !== null ? src_rate * days_rounded * dollar_ksh : "__";
  const per_diem = src_rate * days_rounded;

  const schema = yup.object().shape({
    // dollar_ksh: yup.string().required("This field is required"),
    ticket_cost_participant: yup.number().required("This field is required"),
    participant_cost_participant: yup
      .number()
      .required("This field is required"),
  });

  const defaultValues = {
    ticket_cost_participant: 0,
    participant_cost_participant: 0,
  };

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    // isValid, dirtyFields,
    errors,
  } = formState;

  function submitTrip({
    ticket_cost_participant,
    participant_cost_participant,
  }) {
    const tripInfo = {
      country: trip_data.country,
      reason_for_tr: trip_data.reason_for_tr,
      program_type: trip_data.program_type,
      program_title: trip_data.program_title,
      program_from: trip_data.program_from,
      program_to: trip_data.program_to,
      program_similar: trip_data.program_similar,
      prog_sm_from: trip_data.prog_sm_from,
      prog_sm_to: trip_data.prog_sm_to,
      program_other: trip_data.program_other,
      host: trip_data.host,
      host_zip_postal_code: trip_data.host_zip_postal_code,
      host_city: trip_data.host_city,
      host_country: trip_data.host_country,
      sponsor: trip_data.sponsor,
      sponsor_status: trip_data.sponsor_status,
      object_meeting: trip_data.object_meeting,
      exp_activity: trip_data.exp_activity,
      virtuality: trip_data.virtuality,
      accomo_cost: per_diem,
      ticket_cost: ticket_cost_participant,
      participant_cost: participant_cost_participant,
      id: trip_data.trip_id,
      trip_type: "public",
    };
    axios
      .put(
        `${backend_url}/traveller/details/`,
        tripInfo,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Travel Details Submitted.");
        setLoading(false);
        setLoadingIcon(false);
        window.location.reload();
        // window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.code === "ERR_NETWORK" && "Network error, please try again later."
        );
        setMessage(err.response.data?.details);
        setLoading(false);
        setLoadingIcon(false);
      });
  }
  return (
    <form
      name="delegateInfo"
      noValidate
      className="flex flex-col justify-center w-full mt-8 sm:mt-32"
      onSubmit={handleSubmit(submitTrip)}
    >
      {openMessage === true && (
        <TravelSnackbar
          openMessage={openMessage}
          severity={severity}
          message={message}
        />
      )}

      <Typography
        component="h5"
        variant="p"
        className="px-4 py-16 text-md font-semibold md:text-lg "
      >
        Cost of the Trip and Sponsorship for Applicant
      </Typography>
      {trip_data.sponsor_status === "not_sponsored" && (
        <Typography
          component="h6"
          variant="p"
          className="px-4 text-md font-semibold md:text-lg text-red-400 "
        >
          Your trip is sponsored by a Non-GOK/Host
        </Typography>
      )}

      {/* <Typography
        component="p"
        variant="p"
        className="p-8 sm:p-12 text-xs font-semibold"
      >
        Use the CBK Rates to check the exchange rate of the U.S dollar to the
        KES, which will then be used in calculating the subsitence cost from the
        SRC rates per job group of the country you are visiting multiplied by
        the number of travel days.
      </Typography> */}

      <div>
        <div className="sm:flex m-4 py-24">
          <Controller
            name="ticket_cost_participant"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Proposed ticket/ top up cost in Ksh"
                disabled={
                  trip_data.sponsor_status === "not_sponsored" ? true : false
                }
                id="applicant_role"
                error={!!errors.ticket_cost_participant}
                helperText={errors?.ticket_cost_participant?.message}
                variant="standard"
                sx={{ p: 1 }}
                fullWidth
              />
            )}
          />

          <Controller
            name="participant_cost_participant"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={
                  trip_data.sponsor_status === "not_sponsored" ? true : false
                }
                label="Participation Fee (where applicable) in Ksh:"
                id="participant_cost_participant"
                error={!!errors.participant_cost_participant}
                helperText={errors?.participant_cost_participant?.message}
                variant="standard"
                sx={{ p: 1 }}
                fullWidth
              />
            )}
          />
        </div>

        <TableContainer
          component={Paper}
          elevation={1}
          sx={{ paddingBottom: 0, marginBottom: 3 }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "primary.light" }}>
                {/* <TableCell>U.S Dollar to Ksh as per today CBK rates</TableCell> */}
                <TableCell align="right">Number of days travelling</TableCell>
                <TableCell align="right">Country</TableCell>
                <TableCell align="right">Job group</TableCell>
                <TableCell align="right">
                  Subsistence/Accommodation (Per Diem) in &nbsp;(U.S $)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {/* <TableCell component="th" scope="row">
                  <TextField
                    disabled={
                      trip_data.sponsor_status === "not_sponsored"
                        ? true
                        : false
                    }
                    value={dollar_ksh}
                    onChange={(e) => {
                      setDollarKsh(e.target.value);
                    }}
                    label="U.S Dollar to Ksh rate"
                    id="dollar_ksh"
                    error={!!errors.dollar_ksh}
                    helperText={errors?.dollar_ksh?.message}
                    variant="outlined"
                    fullWidth
                  />
                </TableCell> */}
                <TableCell align="right">{days_rounded}</TableCell>
                <TableCell align="right">{country}</TableCell>
                <TableCell align="right">{job_group}</TableCell>
                <TableCell align="right">
                  <b> {per_diem.toLocaleString()}</b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-12 w-full min-w-0 py-16 pb-32">
          <CBKRatesWidget />
          <TravelRatesWidget />
        </div> */}
      </div>

      <Button
        sx={{ mt: 6 }}
        variant="contained"
        color="success"
        disabled={loading === true ? true : false}
        onClick={() => {
          setOpenMessage(false);
          setLoadingIcon(Object.keys(errors).length > 0 ? false : true);
          setLoading(
            Object.keys(errors).length === 0
              ? false
              : Object.keys(errors).length > 0
              ? false
              : true
          );
        }}
        type="submit"
      >
        {loadingIcon === true && Object.keys(errors).length <= 0 && <Loading />}
        {loading === true ? (
          <Loading />
        ) : Object.keys(errors).length > 0 ? (
          "Complete All Fields"
        ) : (
          "Next"
        )}
      </Button>
    </form>
  );
}
