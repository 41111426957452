import React, { useState } from "react";
import * as yup from "yup";
import _ from "../../@lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import format from "date-fns/format";
import TravelSnackbar from "../../components/TravelSnackbar";
import Loading from "../../components/Loading";
import { backend_url } from "../../components/Constants";

export default function Part3(props) {
  const { token, depature_and_return_data, reviewer, setEdit1 } = props;
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(
    depature_and_return_data !== undefined
      ? depature_and_return_data.mode_of_tran_dep === "air"
        ? true
        : false
      : null
  );

  const schema = yup.object().shape({
    date_of_dep: yup
      .date()
      .required("You must enter date of departure")
      .default(() => new Date()),
    date_of_return: yup
      .date()
      .required("You must enter date of return")
      .default(() => new Date()),
    mode_of_tran_dep: yup
      .string()
      .required("You must key in mode of transport during departure"),
    // mode_of_tran_return: yup
    //   .string()
    //   .required("You must enter mode of transport during return"),
    commutor_name: yup.string().required("You must enter commutor name"),
    air_ticket_class:
      mode === true
        ? yup
            .string()
            .required()
            .oneOf(
              ["Economy", "Premium Economy", "Business", "First Classs"],
              "You must enter the air ticket class"
            )
        : yup.string().notRequired(),
  });

  const defaultValues = {
    date_of_dep:
      depature_and_return_data !== undefined
        ? dayjs(depature_and_return_data.date_of_dep)
        : "",
    date_of_return:
      depature_and_return_data !== undefined
        ? dayjs(depature_and_return_data.date_of_return)
        : "",
    mode_of_tran_dep:
      depature_and_return_data !== undefined
        ? depature_and_return_data.mode_of_tran_dep
        : "",
    // mode_of_tran_return:
    //   depature_and_return_data !== undefined
    //     ? depature_and_return_data.mode_of_tran_return
    //     : "",
    commutor_name:
      depature_and_return_data !== undefined
        ? depature_and_return_data.commutor_name
        : "",
    air_ticket_class:
      depature_and_return_data !== undefined
        ? depature_and_return_data.air_ticket_class
        : "",
  };

  function addBusinessDays(startDate, daysToAdd) {
    const endDate = new Date(startDate);
    let addedDays = 0;

    while (addedDays < daysToAdd) {
      endDate.setDate(endDate.getDate() + 1);

      if (endDate.getDay() !== 0 && endDate.getDay() !== 6) {
        // Check if it's not Sunday (0) or Saturday (6)
        addedDays++;
      }
    }

    return endDate;
  }

  // Get the current date
  const today = new Date();

  // Add 7 business days to the current date
  const sevenDays = dayjs(addBusinessDays(today, 8));

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  React.useEffect(() => {
    (_.isEmpty(dirtyFields) || !isValid) === false
      ? setEdit1(true)
      : setEdit1(false);
  });

  function submit({
    date_of_dep,
    date_of_return,
    mode_of_tran_dep,
    // mode_of_tran_return,
    commutor_name,
    air_ticket_class,
  }) {
    const depRetDetails = {
      date_of_dep: format(new Date(date_of_dep), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      date_of_return: format(
        new Date(date_of_return),
        "yyyy-MM-dd'T'HH:mm:ss'Z'"
      ),
      mode_of_tran_dep,
      mode_of_tran_return: mode_of_tran_dep,
      commutor_name,
      air_ticket_class,
      trip: depature_and_return_data.trip.trip_id,
      user: depature_and_return_data.user.user_id,
      id: depature_and_return_data.dep_return_id,
      reviewer: reviewer === true ? true : false,
    };
    axios
      .put(
        `${backend_url}/traveller/return_details/`,
        depRetDetails,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Departure Return Details Updated. Go to the next step!");
        setLoading(false);
        setEdit1(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        // setMessage(err.response.data);
        setMessage(err.code === "ERR_NETWORK" && "Network error, please try again later.");
        setMessage(err.response.data?.details);
        setLoading(false);
      });
  }

  return (
    <form
      name="depRetDetails"
      noValidate
      className="flex flex-col justify-center w-full mt-8 sm:mt-32"
      onSubmit={handleSubmit(submit)}
    >
      {openMessage === true && (
        <TravelSnackbar
          openMessage={openMessage}
          severity={severity}
          message={message}
        />
      )}

      <Typography
        component="h5"
        variant="p"
        className="px-4 py-16 text-md font-semibold md:text-lg md:font-bold"
      >
        DEPARTURE AND RETURN DETAILS
      </Typography>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="sm:flex m-4">
          <div className="w-full">
            <div>
              <Typography
                component="p"
                variant="p"
                className="p-4 text-xs font-semibold"
              >
                Date and time of departure:
              </Typography>
            </div>
            <Controller
              name="date_of_dep"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  sx={{ py: 2 }}
                  minDate={sevenDays}
                  clearable
                  slotProps={{
                    textField: {
                      id: "from-date",
                      label: "Date and time",
                      error: errors.date_of_dep,
                      helperText: errors?.date_of_dep?.message,
                      InputLabelProps: {
                        shrink: true,
                      },
                      fullWidth: true,
                      variant: "standard",
                    },
                    actionBar: {
                      actions: ["clear", "today"],
                    },
                  }}
                />
              )}
            />
          </div>

          <span className="p-16"></span>

          <div className="w-full">
            <div>
              <Typography
                component="p"
                variant="p"
                className="p-4 text-xs font-semibold"
              >
                Date and time of return:
              </Typography>
            </div>

            <Controller
              name="date_of_return"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  sx={{ py: 2 }}
                  minDate={sevenDays}
                  clearable
                  slotProps={{
                    textField: {
                      id: "to-date",
                      label: "Date and time",
                      error: errors.date_of_return,
                      helperText: errors?.date_of_return?.message,
                      InputLabelProps: {
                        shrink: true,
                      },
                      fullWidth: true,
                      variant: "standard",
                    },
                    actionBar: {
                      actions: ["clear", "today"],
                    },
                  }}
                />
              )}
            />

            <span className="p-16"></span>
          </div>
        </div>
      </LocalizationProvider>

      <div className="flex w-full">
        <Controller
          name="mode_of_tran_dep"
          control={control}
          render={({ field }) => (
            <FormControl error={!!errors.mode_of_tran_dep} required>
              <FormLabel id="demo-radio-buttons-group-label">
                Mode of travel departure
              </FormLabel>
              <RadioGroup
                className="px-4"
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                {...field}
              >
                <FormControlLabel
                  sx={{ p: 1 }}
                  value="air"
                  control={
                    <Radio
                      onClick={() => {
                        setMode(true);
                      }}
                    />
                  }
                  label="air"
                />
                <FormControlLabel
                  sx={{ p: 1 }}
                  value="road"
                  control={
                    <Radio
                      onClick={() => {
                        setMode(false);
                      }}
                    />
                  }
                  label="road"
                />
              </RadioGroup>
              <FormHelperText>
                <span className="text-red-500">
                  {" "}
                  {errors?.mode_of_tran_dep?.message}
                </span>
              </FormHelperText>
            </FormControl>
          )}
        />
      </div>

      <div className="sm:flex m-4">
        {mode === true && (
          <Controller
            name="commutor_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Airline"
                id="commutor_name"
                variant="standard"
                sx={{ m: 1 }}
                fullWidth
                error={!!errors.commutor_name}
                helperText={errors?.commutor_name?.message}
              />
            )}
          />
        )}

        {mode === true && (
          <Controller
            name="air_ticket_class"
            control={control}
            render={({ field }) => (
              <FormControl
                sx={{ pt: 1 }}
                fullWidth
                error={!!errors.air_ticket_class}
              >
                <InputLabel id="air_ticket_class">Air Ticket Class</InputLabel>
                <Select
                  labelId="air_ticket_class"
                  id="air_ticket_class"
                  label="Air Ticket Class"
                  variant="standard"
                  {...field}
                >
                  <MenuItem value={"Economy"}>Economy</MenuItem>
                  <MenuItem value={"Premium Economy"}>Premium Economy</MenuItem>
                  <MenuItem value={"Business"}>Business</MenuItem>
                  <MenuItem value={"First Classs"}>First Classs</MenuItem>
                </Select>
                <FormHelperText>
                  {errors?.air_ticket_class?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        )}
      </div>

      {window.location.hash === "#preview" ? (
        <div></div>
      ) : (
        <Button
          sx={{ mt: 8 }}
          variant="contained"
          color="success"
          disabled={
            loading === true ? true : _.isEmpty(dirtyFields) || !isValid
          }
          onClick={() => {
            setOpenMessage(false);
            setEdit1(false);
            setLoading(
              Object.keys(errors).length === 0
                ? false
                : Object.keys(errors).length > 0
                ? false
                : true
            );
          }}
          type="submit"
        >
          {loading === true ? (
            <Loading />
          ) : Object.keys(errors).length > 0 ? (
            "Complete All Fields"
          ) : (
            "Update departure and return details"
          )}
        </Button>
      )}
    </form>
  );
}
