import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
// import _ from "../@lodash";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import kenya_flag from "../assets/kenya_flag.png";
import {
  Avatar,
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import TravelSnackbar from "./TravelSnackbar";
import Loading from "./Loading";
import { backend_url } from "./Constants";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { lighten } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const UpdateProfile = (props) => {
  const { handleClose, open, token, user } = props;
  const [diplomat, setDiplomat] = React.useState(
    (user && user.designation === "PS") || user.designation === "CS"
      ? true
      : false
  );
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const schema = yup.object().shape({
    passport_no:
      diplomat === true
        ? yup.string().notRequired()
        : yup
            .string()
            .required("You must enter your passport number")
            .min(4, "Too short - should be 4 chars minimum")
            .nullable(true),
    diplomatic_passport_no:
      diplomat === true
        ? yup
            .string()
            .required("You must enter your passport number")
            .min(4, "Too short - should be 4 chars minimum")
            .nullable(true)
        : yup.string().notRequired(),
    profile_photo: yup.mixed().notRequired(),
    id_number: yup
      .string()
      .required("You must enter your ID number")
      .min(4, "Too short - should be 4 chars minimum")
      .nullable(true),
    personal_no: yup
      .string()
      .required("You must enter your ID number")
      .min(4, "Too short - should be 4 chars minimum")
      .nullable(true),
    telephone_no: yup
      .number()
      .required("You must enter your telephone number")
      .min(4, "Number is too short - should be 4 chars minimum")
      .nullable(true),
    password: yup
      .string()
      .required("Please enter your password.")
      .min(8, "Password is too short - should be 8 chars minimum."),
    re_password: yup
      .string()
      .required("Passwords must match")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const defaultValues = {
    passport_no: user !== undefined ? user.passport_no : "",
    diplomatic_passport_no:
      user !== undefined ? user.diplomatic_passport_no : "",
    id_number: user !== undefined ? user.id_number : "",
    personal_no: user !== undefined ? user.personal_no : "",
    telephone_no: user !== undefined ? user.telephone_no : "",
    profile_photo: null,
    password: "",
    re_password: "",
  };

  const [openMessage, setOpenMessage] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    // isValid, dirtyFields,
    errors,
  } = formState;

  function submit({
    telephone_no,
    id_number,
    profile_photo,
    password,
    re_password,
    passport_no,
    diplomatic_passport_no,
    personal_no,
  }) {
    const userInfo = {
      telephone_no,
      profile_photo,
      id_number,
      password,
      re_password,
      passport_no,
      diplomatic_passport_no,
      personal_no,
    };
    axios
      .patch(
        `${backend_url}/auth/user/update_info/`,
        userInfo,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Updated Successfully!");
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.response.data?.details
            ? err.response.data?.details
            : err.response.data?.error
        );
        setLoading(false);
      });
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        {openMessage === true && (
          <TravelSnackbar
            openMessage={openMessage}
            severity={severity}
            message={message}
          />
        )}

        <DialogTitle>
          <Typography variant="p" className="text-lg font-semibold">
            Update Profile Information
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Keep your profile up to date.</DialogContentText>

          {user !== undefined && (
            <form
              name="registerForm"
              noValidate
              className="flex flex-col justify-center w-full mt-16 sm:mt-32"
              onSubmit={handleSubmit(submit)}
            >
              {/* <Controller
                control={control}
                name="profile_photo"
                render={({ field: { onChange, value } }) => (
                  <Box
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? lighten(theme.palette.background.default, 1)
                          : lighten(theme.palette.background.default, 0.02),
                    }}
                    component="label"
                    htmlFor="button_profile_photo"
                    className="flex items-center justify-center relative w-82 h-64 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
                  >
                    <input
                      accept=".jpeg, .jpg, .png, .PNG,"
                      className="hidden"
                      id="button_profile_photo"
                      type="file"
                      onChange={(e) => onChange(e.target.files[0])}
                    />
                    <IconButton>
                      <FileUploadIcon sx={{ color: "action" }} />
                    </IconButton>

                    <Typography
                      variant="p"
                      component="p"
                      className="text-xs font-small pl-8 pr-16"
                    >
                      {value === null ? (
                        <span> Update Profile Photo</span>
                      ) : (
                        value.name
                      )}
                    </Typography>
                    <FormHelperText>
                      {" "}
                      <span className="flex flex-col text-red-500">
                        {errors?.profile_photo?.message}
                      </span>
                    </FormHelperText>
                  </Box>
                )}
              /> */}

              <Controller
                control={control}
                name="profile_photo"
                render={({ field: { onChange, value } }) => (
                  <Box
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? lighten(theme.palette.background.default, 1)
                          : lighten(theme.palette.background.default, 0.02),
                      borderRadius: "50%",
                      width: 150, // Set the width and height to make the box circular
                      height: 150,
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                      cursor: "pointer",
                      boxShadow: 3,
                      "&:hover": {
                        boxShadow: 6,
                      },
                    }}
                    component="label"
                    htmlFor="button_profile_photo"
                    className="mx-12 mb-24"
                  >
                    <input
                      accept=".jpeg, .jpg, .png, .PNG,"
                      className="hidden"
                      id="button_profile_photo"
                      type="file"
                      onChange={(e) => onChange(e.target.files[0])}
                    />
                    {value ? (
                      <Avatar
                        src={URL.createObjectURL(value)}
                        sx={{ width: "100%", height: "100%" }}
                      />
                    ) : (
                      <>
                        <IconButton sx={{ color: "action" }}>
                          <FileUploadIcon />
                        </IconButton>
                        <Typography
                          variant="caption"
                          component="span"
                          sx={{ mt: 1 }} // Margin top to create space between the icon and text
                        >
                          Update Profile Photo
                        </Typography>
                      </>
                    )}
                    <FormHelperText
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      <span className="flex flex-col text-red-500">
                        {errors?.profile_photo?.message}
                      </span>
                    </FormHelperText>
                  </Box>
                )}
              />

              <Controller
                name="id_number"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ mb: 2 }}
                    label="ID number"
                    type="number"
                    error={!!errors.id_number}
                    helperText={errors?.id_number?.message}
                    disabled
                    variant="standard"
                    required
                    fullWidth
                  />
                )}
              />

              <Controller
                name="telephone_no"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ mb: 2 }}
                    inputProps={{ maxLength: 10 }}
                    label="Telephone number"
                    variant="standard"
                    error={!!errors.telephone_no}
                    helperText={
                      <Typography variant="caption" color="error">
                        {" "}
                        {errors?.telephone_no?.message}{" "}
                      </Typography>
                    }
                    required
                    fullWidth
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component="span"
                            sx={{ "& > img": { mr: 0, flexShrink: 0 } }}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              src={kenya_flag}
                              alt="kenya flag"
                            />
                            <span className="ml-4 font-medium">+254</span>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Controller
                name="personal_no"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ mb: 2 }}
                    label="Personal No"
                    type="number"
                    error={!!errors.personal_no}
                    helperText={errors?.personal_no?.message}
                    variant="standard"
                    required
                    fullWidth
                  />
                )}
              />

              {diplomat === true ? (
                <>
                  <Controller
                    name="diplomatic_passport_no"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={{ mb: 2 }}
                        inputProps={{ maxLength: 18 }}
                        label="Diplomatic Passport number"
                        error={!!errors.diplomatic_passport_no}
                        helperText={errors?.diplomatic_passport_no?.message}
                        variant="standard"
                        required
                        fullWidth
                      />
                    )}
                  />

                  <Controller
                    name="passport_no"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={{ mb: 2 }}
                        inputProps={{ maxLength: 18 }}
                        label="Passport number"
                        error={!!errors.passport_no}
                        helperText={errors?.passport_no?.message}
                        variant="standard"
                        required
                        fullWidth
                      />
                    )}
                  />
                </>
              ) : (
                <Controller
                  name="passport_no"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      inputProps={{ maxLength: 18 }}
                      label="Passport number"
                      error={!!errors.passport_no}
                      helperText={errors?.passport_no?.message}
                      variant="standard"
                      required
                      fullWidth
                    />
                  )}
                />
              )}

              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ mb: 2 }}
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    error={!!errors.password}
                    helperText={errors?.password?.message}
                    variant="standard"
                    required
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Controller
                name="re_password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ mb: 2 }}
                    label="Password Confirm"
                    type={showPassword ? "text" : "password"}
                    error={!!errors.re_password}
                    helperText={errors?.re_password?.message}
                    variant="standard"
                    required
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  my: 4,
                  width: "100%", // Ensure the container takes the full width
                }}
              >
                <Button
                  sx={{ mx: 2, flex: 1 }} // Use flex: 1 to make the button take available space
                  color="error"
                  variant="outlined"
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  sx={{ mx: 2, flex: 1 }} // Use flex: 1 to make the button take available space
                  variant="contained"
                  color="success"
                  aria-label="Update Profile"
                  onClick={() => {
                    setOpenMessage(false);
                    setLoading(Object.keys(errors).length > 0 ? false : true);
                  }}
                  type="submit"
                  size="large"
                >
                  {loading === true ? (
                    Object.keys(errors).length > 0 ? (
                      setLoading(false)
                    ) : (
                      <Loading />
                    )
                  ) : (
                    "Update Profile"
                  )}
                </Button>
              </Box>
            </form>
          )}
        </DialogContent>

        <DialogActions>
          {/* <Button
            variant="contained"
            color="primary"
            className=" w-full mt-16"
            aria-label="Sign up"
            // disabled={_.isEmpty(dirtyFields) || !isValid}
            onClick={() => {
              setOpenMessage(false);
              setLoading(Object.keys(errors).length === 0 ? false : Object.keys(errors).length > 0 ? false : true);
            }}
            type="submit"
            size="large"
          >
            {loading === true ? <Loading /> : "Create your account"}
          </Button> */}

          {/* <Button
            sx={{ mx: 2 }}
            variant="contained"
            color="primary"
            className=" w-full mt-16"
            aria-label="Sign up"
            //   disabled={_.isEmpty(dirtyFields) || !isValid}
            onClick={() => {
              setOpenMessage(false);
              setLoading(Object.keys(errors).length === 0 ? false : Object.keys(errors).length > 0 ? false : true);
            }}
            type="submit"
            size="large"
          >
            {loading === true ? <Loading /> : "Update"}
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};
