import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { memo } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function SummaryWidget(props) {
    const { this_year_trips } = props;

    return (
        this_year_trips !== undefined &&
        <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden">
            <div className="flex items-center justify-between px-8 pt-12">
                <Typography variant='p'
                    className="px-12 text-xs font-medium tracking-tight leading-6 truncate"
                    color="text.secondary"
                >
                    Summary
                </Typography>
                <IconButton aria-label="more" size="large">
                    <MoreVertIcon />
                </IconButton>
            </div>
            <div className="text-center mt-8">
                <Typography variant='p' className="text-5xl sm:text-6xl font-bold tracking-tight leading-none text-blue-500">
                   {this_year_trips}
                </Typography>
                <br />
                <Typography variant='p' className="text-md font-medium text-blue-600 dark:text-blue-500">
                    {this_year_trips.length > 1 ? 'Trips' : 'Trip'}
                </Typography>
            </div>

            <Typography
                variant='p'
                className="flex text-xs items-baseline justify-center w-full pt-12 pb-24"
                color="text.secondary"
            >
                <span className="truncate">
                   Travelled This Year
                </span>
            </Typography>
        </Paper>
    );
}

export default memo(SummaryWidget);
