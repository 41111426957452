import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function PreviousTripWidget(props) {
  const { previous_trip_cost } = props;

  return (
    previous_trip_cost !== undefined && (
      <Paper className="relative flex flex-col flex-auto p-24 pr-12 pb-12 rounded-2xl shadow overflow-hidden">
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <Typography
              variant="p"
              className="text-sm font-medium tracking-tight leading-6 truncate"
            >
              Previous Trip Cost
            </Typography>
            <Typography
              variant="p"
              className="text-green-600 font-medium text-xs"
            >
              {`Travelled to ${previous_trip_cost.country}`}
            </Typography>
          </div>
          <div className="-mt-8">
            <IconButton aria-label="more" size="large">
              <MoreVertIcon />
            </IconButton>
          </div>
        </div>

        <div className="flex flex-row flex-wrap mt-12 -mx-24 h-96">
          <div className="flex flex-col mx-24 my-12">
            <Typography
              variant="p"
              color="text.secondary"
              className="text-xs font-medium leading-none"
            >
              Total Cost
            </Typography>
            <Typography
              variant="p"
              className="mt-8 font-medium text-md leading-none"
            >
              <span className="pl-10 text-xs font-normal">
                {parseInt(previous_trip_cost.accomodation_cost) +
                  parseInt(previous_trip_cost.ticket_cost) +
                  parseInt(
                    previous_trip_cost.sponsor_cost === null
                      ? 0
                      : previous_trip_cost.sponsor_cost
                  )}
              </span>
            </Typography>
          </div>
          {/* <div className="flex flex-col mx-24 my-12">
                    <Typography variant='p' color="text.secondary" className="text-xs font-medium leading-none">
                        GOK Cost
                    </Typography>
                    <Typography variant='p' className="mt-8 font-medium text-sm leading-none">
                       $ {previous_trip_cost.gok_cost}
                    </Typography>
                </div> */}
          {/* <div className="flex flex-col mx-24 my-12">
                    <Typography variant='p' color="text.secondary" className="text-xs font-medium leading-none">
                        Minimum
                    </Typography>
                    <Typography variant='p' className="mt-8 font-medium text-sm leading-none">
                        KES 4899299
                    </Typography>
                </div> */}
        </div>

        <div className="text-8xl absolute bottom-0 right-0 rtl:left-0 w-96 h-96 -m-44">
          <CheckCircleOutlineIcon
            fontSize="inherit"
            className="opacity-25 text-green-500 dark:text-green-400"
          />
        </div>
      </Paper>
    )
  );
}

export default memo(PreviousTripWidget);
