import { Paper, Divider, Box, Button, TextField } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { useMediaQuery, useTheme } from "@mui/material";

import { FormHeaderDetail } from "../../forms/details/FormHeader";
import { PersonalDetails } from "../../forms/details/Personal";
import { TravelDetails } from "../../forms/details/Travel";
import { DepartureReturnDetails } from "../../forms/details/Departure_Return";
import { DelegationSizeDetails } from "../../forms/details/Delegation";
// import { ConfirmationDetails } from '../../forms/details/Confirmation';
// import { ConcurrenceDetails } from '../../forms/details/Concurrence';
// import { ApprovalDetails } from '../../forms/details/Approval';
import NavLinkAdapter from "../../components/NavLinkAdapter";
import TravelSnackbar from "../../components/TravelSnackbar";
import Loading from "../../components/Loading";
import { HistandStats } from "../../forms/details/HistandStats";
import { backend_url } from "../../components/Constants";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MFAConfirmation(props) {
  const { token, user, setOpen } = props;
  const { id } = useParams();
  const [trip, setTrip] = useState([]);
  const [value, setValue] = useState(0);

  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  const { trip_data, acting_data, delegate_data, depature_and_return_data } =
    trip;

  const [cs_confirm, setCS_confirm] = useState("");
  const [cs_reasons, setCS_reasons] = useState("");

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setValue(0);
    setOpen(false);
  }, [location, setOpen]);

  useEffect(() => {
    fetch(
      `${backend_url}/traveller/confirmation/?` +
        new URLSearchParams({
          trip: id,
        }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setTrip(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [token, id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const CSInfo = {
      trip_id: id,
      cs_confirm: cs_confirm,
      cs_reasons: cs_reasons,
    };

    let url = `${backend_url}/traveller/confirmation/`;
    axios
      .patch(url, CSInfo, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Confirmed Successfully!");
        setLoading(false);
        setCS_reasons("");
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.response.data?.details
            ? err.response.data?.details
            : err.response.data?.error
        );
        setLoading(false);
      });
  };

  return (
    <div className="relative flex flex-col flex-auto items-center px-4 sm:px-8 container mx-auto">
      <Paper
        className="w-full px-24 sm:px-32 py-32 pt-48 mt-64 mb-16"
        elevation={1}
      >
        <Button
          className="font-semibold"
          component={NavLinkAdapter}
          to="/"
          size="large"
          color="primary"
          onClick={() => (isMobile ? setOpen(false) : setOpen(true))}
          variant="contained"
          sx={{ my: 2 }}
        >
          <CloseIcon />
          <span className="mx-8">Close Application</span>
        </Button>

        {openMessage === true && (
          <TravelSnackbar
            openMessage={openMessage}
            severity={severity}
            message={message}
          />
        )}

        {trip_data && trip_data.length > 0 && (
          <div>
            {trip_data[0].user.id_number !== user.id_number && (
              <form
                name="CSForm"
                noValidate
                className="flex flex-col justify-center w-full "
                onSubmit={handleSubmit}
              >
                <TextField
                  label="Reasons for Concurring/Not Concurring and Return Application:"
                  id="approval"
                  variant="outlined"
                  sx={{ my: 4 }}
                  value={cs_reasons}
                  multiline
                  minRows={3}
                  fullWidth
                  onChange={(e) => setCS_reasons(e.target.value)}
                />

                <div className="flex items-center mt-24 sm:mt-0 sm:mx-8 space-x-12">
                  <Button
                    className="whitespace-nowrap"
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setCS_confirm("rejected");
                      setOpenMessage(false);
                    }}
                    startIcon={<DoDisturbIcon />}
                    type="submit"
                  >
                    {loading === true ? <Loading /> : "Not Concurred"}
                  </Button>

                  <Button
                    className="whitespace-nowrap"
                    variant="contained"
                    color="success"
                    onClick={() => {
                      setCS_confirm("accepted");
                      setOpenMessage(false);
                    }}
                    startIcon={<CheckCircleOutlineIcon />}
                    type="submit"
                  >
                    {loading === true ? <Loading /> : "Concurred"}
                  </Button>
                </div>
              </form>
            )}

            <Divider />

            <div>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab
                    label="Annextures and Personal Details"
                    {...a11yProps(0)}
                  />
                  <Tab label="Travel Details" {...a11yProps(1)} />
                  <Tab label="Departure and Return Details" {...a11yProps(2)} />
                  <Tab label="Costs and Size of Delegation" {...a11yProps(3)} />
                  <Tab
                    label="Travel History and Statistics"
                    {...a11yProps(4)}
                  />
                </Tabs>
              </Box>

              <CustomTabPanel value={value} index={0}>
                <FormHeaderDetail trip={trip_data[0]} cs_confirm={cs_confirm} />
                <PersonalDetails
                  trip={trip_data[0]}
                  actingOfficer={acting_data[0]}
                />
              </CustomTabPanel>

              <CustomTabPanel value={value} index={1}>
                <TravelDetails trip={trip_data[0]} />
              </CustomTabPanel>

              <CustomTabPanel value={value} index={2}>
                <DepartureReturnDetails
                  trip={trip_data[0]}
                  depRet={depature_and_return_data[0]}
                  token={token}
                />
              </CustomTabPanel>

              <CustomTabPanel value={value} index={3}>
                <DelegationSizeDetails
                  trip={trip_data[0]}
                  depRet={depature_and_return_data[0]}
                  delegates={delegate_data}
                />
              </CustomTabPanel>

              <CustomTabPanel value={value} index={4}>
                <HistandStats
                  trip={trip_data[0]}
                  // digitalsignature={digitalsignature}
                />
              </CustomTabPanel>
            </div>

            {/* <ConfirmationDetails trip={trip} />

  <Divider sx={{ py: 2, my: 2 }} />

  <ConcurrenceDetails trip={trip} />

  <Divider sx={{ py: 2, my: 2 }} />

  <ApprovalDetails trip={trip} /> */}
          </div>
        )}

        <Divider sx={{ my: 10 }} />
      </Paper>
    </div>
  );
}
