import { Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import format from "date-fns/format";

export const DepartureReturnDetails = (props) => {
  const { trip, depRet } = props;

  return (
    <div>
      <div className="py-14">
        <Typography
          component="h5"
          variant="p"
          className="text-md sm:text-lg font-semibold"
        >
          {" "}
          DEPARTURE AND RETURN DETAILS
        </Typography>
      </div>

      {depRet && trip && (
        <div>
          <Typography
            component="p"
            variant="p"
            className=" text-xs font-bold py-8"
          >
            (i) Date and time of departure:
          </Typography>

          <Typography
            component="p"
            variant="p"
            className=" pl-4 py-4 sm:pl-14 sm:py-8"
          >
            {format(new Date(depRet.date_of_dep), "dd-MMM-yyyy")}
            <span className="px-1">
              {" "}
              {format(new Date(depRet.date_of_dep), "HH:mm")}{" "}
            </span>
          </Typography>

          <Typography
            component="p"
            variant="p"
            className=" text-xs font-bold py-8"
          >
            Mode of travel departure:
          </Typography>
          <Typography
            component="p"
            variant="p"
            className=" pl-4 py-4 sm:pl-14 sm:py-8"
          >
            {depRet.mode_of_tran_dep}
          </Typography>
          <Divider className="py-2 my-2" />

          <Typography
            component="p"
            variant="p"
            className=" text-xs font-bold py-8"
          >
            (ii) Date and time of return:
          </Typography>

          <Typography
            component="p"
            variant="p"
            className=" pl-4 py-4 sm:pl-14 sm:py-8"
          >
            {format(new Date(depRet.date_of_return), "dd-MMM-yyyy")}
            <span className="px-1">
              {" "}
              {format(new Date(depRet.date_of_return), "HH:mm")}{" "}
            </span>
          </Typography>

          <Typography
            component="p"
            variant="p"
            className=" text-xs font-bold py-8"
          >
            (iii) Mode of travel return:
          </Typography>
          <Typography
            component="p"
            variant="p"
            className=" pl-4 py-4 sm:pl-14 sm:py-8"
          >
            {depRet.mode_of_tran_return}
          </Typography>
          <Divider className="py-2 my-2" />

          <Typography
            component="p"
            variant="p"
            className=" text-xs font-bold py-8"
          >
            (iv) indicate the class of the air ticket and the airline:
          </Typography>

          <Typography
            component="p"
            variant="p"
            className=" pl-4 py-4 sm:pl-14 sm:py-8"
          >
            {depRet.commutor_name === "None" ? "" : `${depRet.commutor_name}:   ${depRet.air_ticket_class}`}
          </Typography>
        </div>
      )}
    </div>
  );
};
