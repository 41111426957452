import React, { useState } from "react";
import MuiAppBar from "@mui/material/AppBar";
import {
  Box,
  Toolbar,
  Typography,
  Menu,
  Container,
  Link,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import logo1 from "../assets/coat.png";

function AppBar(props) {
  const { user, open, handleDrawerToggle } = props;
  const drawerWidth = 280;

  const [anchorElUser, setAnchorElUser] = useState(null);

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="fixed" open={open}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ ...(open && { display: "none" }) }}>
            <div className="flex flex-1 mx-4">
              <Link href="/" underline="none">
                <img src={logo1} className="font-bold h-32" alt="logo" />
              </Link>
            </div>
          </Box>

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, font: "bold", md: { display: "none" } }}
          ></Typography>

          <Typography
            variant="p"
            component="p"
            className="grow font-semibold text-20 hidden sm:inline"
          >
            FOTIMS
          </Typography>

          {/* <MenuItem onClick={handleOpenUserMenu}> */}

          <MenuItem>
            <Typography
              variant="h6"
              noWrap
              // component="a"
              // href="#"
              className="capitalize font-bold"
              sx={{
                color: "primary",
                textDecoration: "none",
              }}
            >
              {user
                ? user.designation === "HE"
                  ? "Mr. President"
                  : `${
                      user.first_name
                    } ${user.last_name}`
                : "Login"}
            </Typography>
          </MenuItem>

          <Box sx={{ flexGrow: 0 }}>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {user && user.first_name ? (
                <Link href="/sign-out" underline="none">
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography
                      textAlign="right"
                      sx={{
                        mr: 2,
                        fontWeight: 700,
                        color: "text.secondary",
                      }}
                    >
                      Sign Out
                    </Typography>
                  </MenuItem>
                </Link>
              ) : (
                <Link href="/signin" underline="none">
                  <MenuItem>
                    <Typography
                      textAlign="right"
                      variant="p"
                      sx={{
                        mr: 2,
                        // fontFamily: 'monospace',
                        fontWeight: 700,
                        fontSize: "20px",
                        color: "primary",
                        textDecoration: "none",
                      }}
                    >
                      Login
                    </Typography>
                  </MenuItem>
                </Link>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default AppBar;
