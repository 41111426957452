import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import format from "date-fns/format";
import Divider from "@mui/material/Divider";
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Box,
} from "@mui/material";
import NavLinkAdapter from "../../components/NavLinkAdapter";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import Pagination from "../../components/Pagination";
import { backend_url } from "../../components/Constants";

function MFAApplications(props) {
  const { token } = props;

  const [trips, setTrips] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = trips.count;
  const links = trips.links;
  // const itemsPerPage = trips && trips.results ? trips.results.trip_data.length : []
  const trip_data = trips && trips.results ? trips.results : [];
  const totalPages = Math.ceil(totalItems / 50);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/traveller/details/?` +
          new URLSearchParams({
            trc_p: currentPage,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setTrips(data));
    }

    fetchData();
  }, [token, currentPage]);

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
      className="flex flex-col flex-auto w-full max-h-full m-8"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="px-12 py-3 my-3">
          <List className="w-full m-0 p-0" component="nav">
            <motion.div variants={item}>
              <ListItem
                // sx={{ bgcolor: 'background.default' }}
                className="px-12 py-8 my-8"
              >
                <ListItemText
                  primary={
                    <Typography component="h5" variant="h5">
                      All Applications
                    </Typography>
                  }
                />
              </ListItem>

              {trip_data &&
                trip_data.map((trip_details) => {
                  return (
                    // trip_details.chief_confirm === 'accepted' &&
                    trip_details.active_trip === true &&
                    trip_details.trip_type === "public" && (
                      <div key={trip_details.trip_id} className="relative">
                        <ListItemButton
                          sx={{ bgcolor: "background.paper", py: 3, px: 4 }}
                          component={NavLinkAdapter}
                          to={`${trip_details.trip_id}`}
                        >
                          <ListItemIcon className="min-w-40 -ml-10 mr-8">
                            <IconButton sx={{ color: "secondary.dark" }}>
                              <ConnectingAirportsIcon fontSize="inherit" />
                            </IconButton>
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography component="h6" variant="h6">
                                {trip_details.reason_for_tr}
                              </Typography>
                            }
                          />

                          <div className="sm:flex items-center px-4">
                            <Typography
                              variant="p"
                              className="text-xs font-medium whitespace-nowrap"
                              color="secondary"
                            >
                              {trip_data[0].chief_confirm === "accepted" &&
                                "Approved to travel - Click to download approval letter"}
                            </Typography>
                          </div>

                          <div className="sm:flex items-center">
                            <Typography
                              className="text-12 whitespace-nowrap"
                              color="text.secondary"
                            >
                              {trip_details.created_at !== undefined &&
                                format(
                                  new Date(trip_details.created_at),
                                  "dd-MMM-yyyy"
                                )}
                            </Typography>
                          </div>
                        </ListItemButton>
                        <Divider />
                      </div>
                    )
                  );
                })}
            </motion.div>
          </List>
        </div>
      </Box>

      {trip_data && trip_data.length > 0 && (
        <div className="my-16 mx-8">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            previousPage={links.previous}
            nextPage={links.next}
          />
        </div>
      )}
    </motion.div>
  );
}

export default MFAApplications;
