import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

// "newVsReturning": {
//   "uniqueVisitors": 46085,
//   "series": [
//     80,
//     20
//   ],
//   "labels": [
//     "New",
//     "Returning"
//   ]
// }

function FrequentCountriesWidget(props) {
  const { frequently_visited_country } = props
  const labels = frequently_visited_country !== undefined && frequently_visited_country.map((option) => option.country)
  const series = frequently_visited_country !== undefined && frequently_visited_country.map((option) => option.count)

  const [awaitRender, setAwaitRender] = useState(true);
  const theme = useTheme();

  const chartOptions = {
    chart: {
      animations: {
        speed: 400,
        animateGradually: {
          enabled: false,
        },
      },
      fontFamily: 'inherit',
      foreColor: 'inherit',
      height: '100%',
      type: 'donut',
      sparkline: {
        enabled: true,
      },
    },
    colors: ['#DD6B20', '#F6AD55'],
    // colors: ['#319795', '#4FD1C5'],
    // colors: ['#3182CE', '#63B3ED'],
    labels,
    plotOptions: {
      pie: {
        customScale: 0.9,
        expandOnClick: false,
        donut: {
          size: '70%',
        },
      },
    },
    stroke: {
      colors: [theme.palette.background.paper],
    },
    series,
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: 'dark',
      // custom: ({ seriesIndex, w }) =>
      //   `<div class="flex items-center h-32 min-h-32 max-h-23 px-12">
      //       <div class="w-12 h-12 rounded-full" style="background-color: ${w.config.colors[seriesIndex]};"></div>
      //       <div class="ml-8 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
      //       <div class="ml-8 text-md font-bold leading-none">${w.config.series[seriesIndex]}%</div>
      //   </div>`,
    },
  };

  useEffect(() => {
    setAwaitRender(false);
  }, []);

  if (awaitRender) {
    return null;
  }
  return (
    <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden p-24">
      <div className="flex flex-col sm:flex-row items-start justify-between">
        <Typography className="text-lg font-medium tracking-tight leading-6 truncate">
         Frequently Visited Countries
        </Typography>
        <div className="ml-8">
          <Chip size="small" className="font-medium text-sm" label=" This Year" />
        </div>
      </div>

      <div className="flex flex-col flex-auto mt-12 h-96">
        <ReactApexChart
          className="flex flex-auto items-center justify-center w-full h-full"
          options={chartOptions}
          series={series}
          type={chartOptions.chart.type}
          height={chartOptions.chart.height}
        />
      </div>
      <div className="mt-32">
        <div className="-my-12 divide-y">
          {frequently_visited_country.map((dataset, i) => (
            <div className="grid grid-cols-3 py-12" key={i}>
              <div className="flex items-center">
                <Box
                  className="flex-0 w-8 h-8 rounded-full"
                  sx={{ backgroundColor: chartOptions.colors[i] }}
                />
                <Typography className="ml-12 truncate">{dataset.country}</Typography>
              </div>
              <Typography className="font-medium text-right">
              </Typography>
              <Typography className="text-right" color="text.secondary">
                {dataset.count}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </Paper>
  );
}

export default (FrequentCountriesWidget);
