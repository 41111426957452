import { Button } from '@mui/material';
import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange, previousPage, nextPage }) => {
    const getPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    return (
        <div className="pagination">
            {previousPage !== null && currentPage > 1 && (
                <Button onClick={() => onPageChange(currentPage - 1)}
                    variant='contained'
                    color="primary"
                >
                    &laquo; Prev
                </Button>
            )}
            {getPageNumbers().map((page) => (
                <Button
                    sx={{ m: 1 }}
                    variant='contained'
                    color={currentPage === page ? 'secondary' : 'inherit'}
                    key={page}
                    onClick={() => onPageChange(page)}
                >
                    {page}
                </Button>
            ))}
            {nextPage !== null && currentPage < totalPages && (
                <Button onClick={() => onPageChange(currentPage + 1)}
                    variant='contained'
                    color="primary"
                >
                    Next &raquo;
                </Button>
            )}
        </div>
    );
};
export default Pagination;