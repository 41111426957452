import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { memo } from 'react';
import format from 'date-fns/format';
import { Link } from '@mui/material';

function TravelRatesWidget() {

    return (
        <Link href="https://www.expedia.com/Flights" underline="none" target="_blank" rel="noreferrer">
        <Paper 
        className="relative flex flex-col flex-auto p-24 pr-12 pb-12 rounded-2xl shadow overflow-hidden">
            <div className="flex items-center justify-between" >
                <div className="flex flex-col">
                    <Typography variant='p' className="text-sm font-medium tracking-tight leading-6 truncate">
                        Current Travel Rates
                    </Typography>
                    <Typography variant='p' className="text-green-600 font-medium text-xs">
                        As of {format(new Date(), 'dd-MMM-yyyy')} 
                    </Typography>
                    <Typography variant='p' className="text-sm font-medium tracking-tight leading-6 truncate text-blue-700">
                        Click to view travel rates
                    </Typography>

                </div>
            </div>
        </Paper>
        </Link>
    );
}

export default memo(TravelRatesWidget);
