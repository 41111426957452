import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
// import _ from "../@lodash";
import axios from "axios";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {
  Container,
  FormHelperText,
} from "@mui/material";
import logo from "../assets/logo1.png";
import Loading from "../components/Loading";
import TravelSnackbar from "../components/TravelSnackbar";
import { backend_url } from "../components/Constants";

function Register() {
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Your email should end with .go.ke")
      .required("You must enter a valid email"),
    password: yup
      .string()
      .required("Please enter your password.")
      .min(8, "Password is too short - should be 8 chars minimum."),
    id_number: yup
      .number()
      .required("You must enter your ID number")
      .min(4, "Number is too short - should be 4 chars minimum")
      .nullable(true),
    passport_no: yup
      .string()
      .required("You must enter your passport number")
      .min(4, "Too short - should be 4 chars minimum")
      .nullable(true),
    re_password: yup
      .string()
      .required("Passwords must match")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    acceptTermsConditions: yup
      .boolean()
      .oneOf([true], "The terms and conditions must be accepted."),
  });

  const defaultValues = {
    email: "",
    password: "",
    id_number: "",
    re_password: "",
    passport_no: "",
    acceptTermsConditions: false,
  };

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    // isValid, dirtyFields,
    errors,
  } = formState;

  function submit({ email, passport_no, password, re_password, id_number }) {
    const user = {
      first_name: "John",
      last_name: "Doe",
      designation: "CEO",
      email,
      passport_no,
      password,
      re_password,
      id_number,
      job_group: "U",
    };
    axios
      .post(
        `${backend_url}/auth/user/register/`,
        user,
        {
          headers: { "Content-Type": "application/json" },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Signed Up Successfully!");
        setLoading(false);
        localStorage.clear();
        window.location.href = "/confirmation";
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.response.data?.details
            ? err.response.data?.details
            : err.response.data?.error
        );
        setLoading(false);
      });
  }

  return (
    <Container component="main" maxWidth="xl">
      <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-32 mt-56">
        <Paper className="flex w-full sm:w-auto min-h-full sm:min-h-auto md:w-full md:max-w-6xl rounded-0 sm:rounded-2xl sm:shadow overflow-hidden">
          <div className="w-full sm:w-auto py-32 px-16 sm:p-48 md:p-64 ltr:border-r-1 rtl:border-l-1">
            <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
              <img className="w-full sm:w-70" src={logo} alt="logo" />

              <br />

              {openMessage === true && (
                <TravelSnackbar
                  openMessage={openMessage}
                  severity={severity}
                  message={message}
                />
              )}

              <Typography
                variant="p"
                component="p"
                className="flex flex-col pt-16 sm:mt-32 text-xl sm:text-2xl font-bold sm:font-extrabold tracking-tight leading-tight"
              >
                Register
              </Typography>

              <div className="sm:flex items-baseline pt-6 text-xs sm:text-sm font-small sm:font-medium">
                <Typography variant="p">Already have an account?</Typography>
                <Link className="ml-4 font-semibold" to="/sign-in">
                  Sign in
                </Link>
              </div>

              <form
                name="registerForm"
                noValidate
                className="flex flex-col justify-center w-full mt-16 sm:mt-32"
                onSubmit={handleSubmit(submit)}
              >
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      label="Email"
                      type="email"
                      error={!!errors.email}
                      helperText={errors?.email?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />

                <Controller
                  name="id_number"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      label="ID number"
                      type="number"
                      error={!!errors.id_number}
                      helperText={errors?.id_number?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />

                <Controller
                  name="passport_no"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      label="Passport number"
                      error={!!errors.passport_no}
                      helperText={errors?.passport_no?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />

                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      label="Password"
                      type="password"
                      error={!!errors.password}
                      helperText={errors?.password?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />

                <Controller
                  name="re_password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      label="Password Confirm"
                      type="password"
                      error={!!errors.re_password}
                      helperText={errors?.re_password?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />

                <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
                  <Controller
                    name="acceptTermsConditions"
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        className="items-center"
                        error={!!errors.acceptTermsConditions}
                      >
                        <FormControlLabel
                          label={
                            <Link
                              to="/terms-and-conditions"
                              underline="none"
                              target="_blank"
                            >
                              {" "}
                              I agree to the Terms of Service and Privacy Policy
                            </Link>
                          }
                          control={<Checkbox size="small" {...field} />}
                        />
                        <FormHelperText>
                          {errors?.acceptTermsConditions?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  className=" w-full mt-16"
                  aria-label="Sign up"
                  // disabled={_.isEmpty(dirtyFields) || !isValid}
                  onClick={() => {
                    setOpenMessage(false);
                    setLoading(Object.keys(errors).length > 0 ? false : true);
                  }}
                  type="submit"
                  size="large"
                >
                  {loading === true ? Object.keys(errors).length > 0 ? (setLoading(false)) : <Loading /> : "Create your account"}
                </Button>
              </form>
            </div>
          </div>

          <Box
            className="relative hidden lg:flex flex-auto items-center justify-center min-h-full p-64 lg:px-112 overflow-hidden"
            sx={{ backgroundColor: "primary.dark" }}
          >
            <svg
              className="absolute inset-0 pointer-events-none"
              viewBox="0 0 960 540"
              width="100%"
              height="100%"
              preserveAspectRatio="xMidYMax slice"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Box
                component="g"
                sx={{ color: "primary.light" }}
                className="opacity-20"
                fill="none"
                stroke="currentColor"
                strokeWidth="100"
              >
                <circle r="234" cx="196" cy="23" />
                <circle r="234" cx="790" cy="491" />
              </Box>
            </svg>
            <Box
              component="svg"
              className="absolute -top-64 -right-64 opacity-20"
              sx={{ color: "primary.light" }}
              viewBox="0 0 220 192"
              width="220px"
              height="192px"
              fill="none"
            >
              <defs>
                <pattern
                  id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect x="0" y="0" width="4" height="4" fill="currentColor" />
                </pattern>
              </defs>
              <rect
                width="220"
                height="192"
                fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
              />
            </Box>

            <div className="z-10 relative w-full max-w-2xl">
              <div className="text-7xl font-bold leading-none text-white text-center">
                <div>Travel</div>
                <div>Clearance</div>
                <div>System</div>
              </div>
              {/* <div className="mt-24 text-lg tracking-tight leading-6 text-white">
                                Chief of Staff and Head of Public Service
                            </div> */}
            </div>
          </Box>
        </Paper>
      </div>
    </Container>
  );
}

export default Register;
