import EditForm from "../../forms/edit/Edit";

export const CheckingEdit = (props) => {
  const { token, user, setOpen } = props;
  const reviewer = true;

  return (
    <div>
      <EditForm
        token={token}
        user={user}
        setOpen={setOpen}
        reviewer={reviewer}
      />
    </div>
  );
};
