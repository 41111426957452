import * as React from "react";
import * as XLSX from "xlsx";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DownloadIcon from "@mui/icons-material/Download";
import { backend_url } from "../../components/Constants";
import Loading from "../../components/Loading";

function downloadReportAsExcel(reportData) {
  // Map the JSON data to match the required columns
  const mappedData = reportData.flatMap((entry) =>
    entry.trips.flatMap((trip) =>
      trip.trip_data.map((tripDetail) => ({
        "S/No": tripDetail.trip_id, // Assuming `trip_id` is the serial number
        "Departure Date": tripDetail.program_from,
        "Name of Applicant": entry.user_data[0].full_name,
        Designation: entry.user_data[0].designation,
        Ministry: entry.user_data[0].ministry,
        "State Department": entry.user_data[0].State_departments,
        "State Corporation/Agency": entry.user_data[0].institutions,
        "Number of Days": calculateDays(
          trip.depature_and_return_data &&
            trip.depature_and_return_data.length > 0
            ? trip.depature_and_return_data[0].date_of_dep
            : "",
          trip.depature_and_return_data &&
            trip.depature_and_return_data.length > 0
            ? trip.depature_and_return_data[0].date_of_return
            : ""
        ), // Calculate based on dates
        "Job Group": entry.user_data[0].job_group,
        Destination: tripDetail.country,
        Airline: tripDetail.host, // Assuming `host` represents the airline or organizer
        "Number of Delegates": trip.delegate_data.length,
        "Ticket Costs": tripDetail.ticket_cost,
        DSA: tripDetail.accomo_cost, // Assuming `accomo_cost` represents the DSA
        Sponsor: tripDetail.sponsor,
        Decision: tripDetail.chief_confirm, // Assuming `chairperson_confirm` is the decision
      }))
    )
  );

  // Helper function to calculate days between two dates
  function calculateDays(from, to) {
    const dateFrom = new Date(from);
    const dateTo = new Date(to);
    const timeDiff = dateTo - dateFrom;
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  }

  // Create a new workbook and worksheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(mappedData);

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "All_Trip_Reports");

  // Generate Excel file and download
  XLSX.writeFile(workbook, "All_Trip_Reports.xlsx");
}

export default function DownloadStats({ token }) {
  const [open, setOpen] = React.useState(false);
  const [report, setReport] = React.useState();

  const handleClickOpen = async () => {
    setOpen(true);

    // Fetch data when the button is clicked
    try {
      const response = await fetch(`${backend_url}/traveller/alldata/?`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setReport(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log("report", report);

  return (
    <React.Fragment>
      <Button
        className="whitespace-nowrap"
        variant="contained"
        color="secondary"
        aria-label="Download Stats"
        startIcon={<DownloadIcon size={20} />}
        onClick={handleClickOpen}
      >
        Download Report
      </Button>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Download Trip Reports</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To download, please filter to your desired liking.
          </DialogContentText> */}
        </DialogContent>
        <div className="flex justify-between p-16">
          <div className="flex items-left justify-left">
            <Button onClick={handleClose} variant="contained" color="error">
              Cancel
            </Button>
          </div>
          {report !== undefined ? (
            <div className="items-end justify-end">
              <Button
                onClick={() => downloadReportAsExcel(report)}
                type="submit"
                variant="contained"
                color="success"
              >
                Download Reports as a CSV
              </Button>
            </div>
          ) : (
            <Loading />
          )}
        </div>
      </Dialog>
    </React.Fragment>
  );
}
