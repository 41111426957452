import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { backend_url } from "../../components/Constants";
import Pagination from "../../components/Pagination";
// import Pagination from "../../components/Pagination";

export const HistandStats = (props) => {
  const { trip, token } = props;
  const [hist, setHist] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = hist.count;
  const links = hist.links;
  // const itemsPerPage = hist && hist.results ? hist.results.trip_data.length : []
  const trip_data = hist && hist.results ? hist.results : [];
  const totalPages = Math.ceil(totalItems / 50);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const user_id = trip.user.user_id;

  useEffect(() => {
    fetch(
      `${backend_url}/traveller/user_history/?` +
        new URLSearchParams({
          user_id,
        }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setHist(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [token, user_id]);

  return (
    <div className="py-14">
      <Typography
        variant="h5"
        className="text-lg font-bold"
        color="text.primary"
        gutterBottom
      >
        {`Travel history and statistics for ${
          trip.user.salutation !== undefined && trip.user.salutation
        }. ${trip.user.first_name} ${trip.user.last_name}`}
      </Typography>

      {trip_data &&
        trip_data.length > 0 &&
        trip_data.map((trip_details) => {
          return (
            <Card
              key={trip_details.reason_for_tr}
              sx={{ minWidth: 275, my: 2 }}
              variant="outlined"
            >
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {trip_details.trip_type === "public"
                    ? "Official Travel"
                    : "Private Travel"}
                </Typography>
                <Typography variant="h5" component="div">
                  {trip_details.reason_for_tr}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {`Total cost in Ksh ${(
                    parseInt(trip.accomo_cost) +
                    parseInt(trip.ticket_cost) +
                    parseInt(trip.participant_cost)
                  ).toLocaleString()} `}
                </Typography>
                {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {`Subsistence/Accommodation (Per Diem) ${
                    (trip_details.accomo_cost).toLocaleString()
                  } `}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {`Participation Fee ${
                    (trip_details.participant_cost).toLocaleString()
                  } Proposed ticket/ top up cost ${(trip_details.ticket_cost).toLocaleString()} `}
                </Typography> */}
                <Typography variant="body2">
                  {trip_details.country}
                  <br />
                  {`Host details: ${trip_details.host_country}, ${trip_details.host_city}, ${trip_details.host_zip_postal_code}`}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">
                  {`Virtual option: ${
                    trip_details.virtuality === false ? "NO" : "Yes"
                  }`}
                </Button>
              </CardActions>
            </Card>
          );
        })}

      {trip_data && trip_data.length > 0 && (
        <div className="my-16 mx-8">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            previousPage={links.previous}
            nextPage={links.next}
          />
        </div>
      )}
    </div>
  );
};
