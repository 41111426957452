import { Button, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import format from "date-fns/format";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import NavLinkAdapter from "../../components/NavLinkAdapter";
import { backend_url } from "../../components/Constants";

export const Private = (props) => {
  const { trip } = props;

  return (
    <div className="mt-16">
      <Typography
        variant="p"
        component="h6"
        className="text-md sm:text-lg  font-semibold"
      >
        Private Travel Details:
      </Typography>

      <Typography variant="p" component="h6" className="text-md  font-semibold">
        {trip.active_trip === false &&
          (trip.hops_checking_reasons !== null ||
            trip.hops_checking_recommendation !== null ||
            trip.chief_reason !== null) && (
            <span className="text-red-500">
              {" "}
              Aplication returned - Create a new application{" "}
            </span>
          )}
      </Typography>
      <div className="grid grid-cols-12 gap-x-4 my-16 text-xs sm:text-sm font-small ">
        <div className="col-span-12 " />

        <Typography
          variant="p"
          className="col-span-8 self-center tracking-tight"
        >
          {" "}
          Date application submitted:{" "}
        </Typography>
        <Typography
          variant="p"
          className="col-span-4 text-right text-xs font-semibold"
        >
          {format(new Date(trip.created_at), "dd-MMM-yyyy")}
          {/* {format(new Date(trip.program_from), 'dd-MMM-yyyy')} */}
        </Typography>

        <div className="col-span-12" />
        <Typography
          variant="p"
          className="col-span-10 self-center tracking-tight"
        >
          {" "}
          Time:{" "}
        </Typography>
        <Typography
          variant="p"
          className="col-span-2 text-right text-xs font-semibold"
        >
          {format(new Date(trip.created_at), "HH:mm")}
        </Typography>
      </div>
      <Divider sx={{ my: 2 }} />

      <div className=" my-2">
        <Typography
          variant="p"
          component="p"
          className="text-xs font-medium py-4"
        >
          Attached Documents
        </Typography>
        <div>
          <Button
            sx={{ m: 1 }}
            variant="contained"
            color="primary"
            component={NavLinkAdapter}
            to={`${backend_url}${trip.leave_upload}`}
            target="_blank"
            size="small"
            startIcon={<InsertInvitationIcon fontSize="small" />}
          >
            <span className="mx-8 truncate">View Leave Letter</span>
          </Button>
        </div>
      </div>

      <div className=" mb-4">
        <Typography
          component="p"
          variant="p"
          className="text-xs font-bold font-small py-8"
        >
          Trip details:
        </Typography>

        <Typography
          component="p"
          variant="p"
          className="pl-4 py-4 sm:pl-14 sm:py-8"
        >
          {/* {trip.reason_for_tr} */}
          <div
                      dangerouslySetInnerHTML={{
                        __html: trip.reason_for_tr,
                      }}
                    />
        </Typography>

        <Typography
          component="p"
          variant="p"
          className="text-xs font-bold font-small py-16"
        >
          From:
          <span style={{ fontWeight: "normal" }} className="px-4">
            {" "}
            {format(new Date(trip.program_from), "dd-MMM-yyyy")}
          </span>
          To:
          <span style={{ fontWeight: "normal" }} className="px-4">
            {" "}
            {format(new Date(trip.program_to), "dd-MMM-yyyy")}
          </span>
          <br />
        </Typography>

        <Typography
          component="p"
          variant="p"
          className="text-xs font-bold font-small py-8"
        >
          Country:{" "}
          <span style={{ fontWeight: "normal" }} className="px-2">
            {trip.country}
          </span>
        </Typography>
      </div>
    </div>
  );
};
