import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import {
  IconButton,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import NavLinkAdapter from "../../components/NavLinkAdapter";
import format from "date-fns/format";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
// import TravelRatesWidget from '../../components/widgets/TravelRatesWidget';
import { useLocation } from "react-router-dom";
import Pagination from "../../components/Pagination";
import { backend_url } from "../../components/Constants";

function CheckingApproval(props) {
  const { token } = props;
  const [trips, setTrips] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = trips.count;
  const links = trips.links;
  // const itemsPerPage = trips && trips.results ? trips.results.trip_data.length : []
  const trip_data = trips && trips.results ? trips.results.trip_data : [];

  const totalPages = Math.ceil(totalItems / 50);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const location = useLocation();

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/traveller/reviewer/?` +
          new URLSearchParams({
            trc_p: currentPage,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setTrips(data));
    }

    fetchData();
  }, [location, token, currentPage]);

  return (
    <div>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
        className="flex flex-col flex-auto w-full max-h-full m-8"
      >
        <List className="w-full m-0 p-0">
          <ListItem
            disableGutters
            className="px-12 "
            sx={{ bgcolor: "background.default" }}
          >
            <ListItemText
              primary={
                <Typography component="h5" variant="h5" className="px-0 py-2 ">
                  Forms Waiting For Editing
                </Typography>
              }
            />
          </ListItem>
          <Divider />

          {trip_data &&
            trip_data.length > 0 &&
            trip_data.map((trip) => {
              return trip.user.designation !== "CS" ? (
                <div key={trip.trip_id}>
                  {trip.active_trip === true &&
                    trip.chief_confirm === "return_application" && (
                      <div className="relative">
                        <ListItemButton
                          className="px-0 sm:-mx-8"
                          disableGutters
                          component={NavLinkAdapter}
                          to={`/checking/edit/${trip.trip_id}`}
                        >
                          <IconButton sx={{ color: "secondary.dark" }}>
                            <ConnectingAirportsIcon fontSize="inherit" />
                          </IconButton>
                          <ListItemText
                            className="px-2"
                            primary={
                              <div>
                                <div className="sm:flex sm:flex-row items-center space-x-12">
                                  <Typography
                                    component="h6"
                                    variant="h6"
                                    className="sm:flex sm:flex-auto sm:items-left sm:text-left"
                                  >
                                    {trip.trip_type === "public"
                                      ? trip.program_title
                                      : "Private Travel"}
                                  </Typography>
                                  <Typography
                                    className="flex py-6 sm:py-0 sm:flex-auto text-left sm:text-right text-8"
                                    variant="p"
                                  >
                                    {trip.program_from !== undefined &&
                                    trip.trip_type === "public"
                                      ? format(
                                          new Date(trip.program_from),
                                          "dd-MMM-yyyy"
                                        )
                                      : format(
                                          new Date(trip.program_from),
                                          "dd-MMM-yyyy"
                                        )}
                                  </Typography>
                                </div>
                                <Typography variant="p">
                                  {trip.user.first_name} {trip.user.last_name} -{" "}
                                  {trip.user.designation} -{" "}
                                  {trip.user.designation === "CEO"
                                    ? trip.user.institutions
                                        .toLowerCase()
                                        .split(" ")
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                        )
                                        .join(" ")
                                    : trip.user.designation === "CHAIRPERSON"
                                    ? trip.user.institutions
                                        .toLowerCase()
                                        .split(" ")
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                        )
                                        .join(" ")
                                    : trip.user.designation === "PS"
                                    ? trip.user.State_departments.toLowerCase()
                                        .split(" ")
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                        )
                                        .join(" ")
                                    : trip.user.designation === "CS" &&
                                      trip.user.ministry
                                        .toLowerCase()
                                        .split(" ")
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                        )
                                        .join(" ")}{" "}
                                </Typography>
                              </div>
                            }
                            secondary={
                              <Typography variant="p">
                                {trip.country}
                              </Typography>
                            }
                          />
                        </ListItemButton>

                        <Divider />
                      </div>
                    )}
                </div>
              ) : (
                <div key={trip.trip_id}>
                  {trip.active_trip === true &&
                    trip.he_confirm === "accepted" && (
                      <div className="relative">
                        <ListItemButton
                          className="px-0 sm:-mx-8"
                          disableGutters
                          component={NavLinkAdapter}
                          to={`/checking/edit/${trip.trip_id}`}
                        >
                          <IconButton sx={{ color: "secondary.dark" }}>
                            <ConnectingAirportsIcon fontSize="inherit" />
                          </IconButton>
                          <ListItemText
                            className="px-2"
                            primary={
                              <div>
                                <div className="sm:flex sm:flex-row items-center space-x-12">
                                  <Typography
                                    component="h6"
                                    variant="h6"
                                    className="sm:flex sm:flex-auto sm:items-left sm:text-left"
                                  >
                                    {trip.trip_type === "public"
                                      ? trip.program_title
                                      : "Private Travel"}
                                  </Typography>
                                  <Typography
                                    className="flex py-6 sm:py-0 sm:flex-auto text-left sm:text-right text-8"
                                    variant="p"
                                  >
                                    {trip.program_from !== undefined &&
                                    trip.trip_type === "public"
                                      ? format(
                                          new Date(trip.program_from),
                                          "dd-MMM-yyyy"
                                        )
                                      : format(
                                          new Date(trip.program_from),
                                          "dd-MMM-yyyy"
                                        )}
                                  </Typography>
                                </div>
                                <Typography variant="p">
                                  {trip.user.first_name} {trip.user.last_name} -{" "}
                                  {trip.user.designation} -{" "}
                                  {trip.user.designation === "CEO"
                                    ? trip.user.institutions
                                        .toLowerCase()
                                        .split(" ")
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                        )
                                        .join(" ")
                                    : trip.user.designation === "CHAIRPERSON"
                                    ? trip.user.institutions
                                        .toLowerCase()
                                        .split(" ")
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                        )
                                        .join(" ")
                                    : trip.user.designation === "PS"
                                    ? trip.user.State_departments.toLowerCase()
                                        .split(" ")
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                        )
                                        .join(" ")
                                    : trip.user.designation === "CS" &&
                                      trip.user.ministry
                                        .toLowerCase()
                                        .split(" ")
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                        )
                                        .join(" ")}{" "}
                                </Typography>
                              </div>
                            }
                            secondary={
                              <Typography variant="p">
                                {trip.country}
                              </Typography>
                            }
                          />
                        </ListItemButton>

                        <Divider />
                      </div>
                    )}
                </div>
              );
            })}

          <Divider />
          {trip_data && trip_data.length > 0 && (
            <div className="my-16 mx-8">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                previousPage={links.previous}
                nextPage={links.next}
              />
            </div>
          )}
        </List>
      </motion.div>
    </div>
  );
}

export default CheckingApproval;
