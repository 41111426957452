import { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "../@lodash";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import logo from "../assets/logo1.png";
import TravelSnackbar from "../components/TravelSnackbar";
import Loading from "../components/Loading";
import { backend_url } from "../components/Constants";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Your email should end with .go.ke")
    .required("You must enter a valid email"),
});

const defaultValues = {
  email: "",
};

export const SendOTP = () => {
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { control, formState, handleSubmit, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    setValue("email", "", { shouldDirty: true, shouldValidate: true });
  }, [setValue]);

  // hour to milliseconds;
  // const one_hour = 3600000

  function submit({ email }) {
    const user = {
      email,
    };

    axios
      .post(
        `${backend_url}/api/send-otp/`,
        user,
        {
          headers: { "Content-Type": "application/json" },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Signed In Successfully!");
        setLoading(false);
        localStorage.setItem("email", email);
        navigate(
          res.data.message === "You can login with your password"
            ? "/sign-in/password"
            : "/verify-otp"
        );
      })
      .catch((err) => {
        localStorage.setItem("email", email);
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.response.data.details ===
            "UserAccount matching query does not exist."
            ? "Please wait for approval. If you do not have an account please register"
            : err.response.data.details
        );
        setLoading(false);
      });
  }

  return (
    <Container component="main" maxWidth="xl">
      <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-32 mt-56">
        <Paper className="flex w-full sm:w-auto min-h-full sm:min-h-auto md:w-full md:max-w-6xl rounded-0 sm:rounded-2xl sm:shadow overflow-hidden">
          <div className="w-full sm:w-auto py-32 px-16 sm:p-48 md:p-64 ltr:border-r-1 rtl:border-l-1">
            <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
              {openMessage === true && (
                <TravelSnackbar
                  openMessage={openMessage}
                  severity={severity}
                  message={message}
                />
              )}
              <img className="w-full sm:w-70" src={logo} alt="logo" />

              <br />

              <Typography
                variant="p"
                component="p"
                className="flex flex-col pt-16 sm:mt-32 text-xl sm:text-2xl font-bold sm:font-extrabold tracking-tight leading-tight"
              >
                Sign in
              </Typography>

              <div className="sm:flex items-baseline pt-6 text-xs sm:text-sm font-small sm:font-medium">
                <Typography variant="p">Don't have an account?</Typography>
                <Link className="ml-4 font-semibold" to="/sign-up">
                  Register
                </Link>
              </div>
              <form
                name="otpForm"
                noValidate
                className="flex flex-col justify-center w-full mt-16 sm:mt-32"
                onSubmit={handleSubmit(submit)}
              >
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      label="Email"
                      autoFocus
                      type="email"
                      // error={!!errors.email}
                      helperText={
                        <Typography variant="caption" color="error">
                          {" "}
                          {errors?.email?.message}{" "}
                        </Typography>
                      }
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />

                <div className="sm:flex items-baseline py-12 text-xs font-small">
                  <Typography variant="p">Already have an OTP?</Typography>
                  <Link className="ml-4 font-semibold" to="/verify-otp">
                    Verify OTP
                  </Link>
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  className=" w-full mt-16"
                  aria-label="Sign in"
                  disabled={_.isEmpty(dirtyFields) || !isValid}
                  type="submit"
                  size="large"
                  onClick={() => {
                    setLoading(Object.keys(errors).length > 0 ? false : true);
                  }}
                >
                  {loading === true ? Object.keys(errors).length > 0 ? (setLoading(false)) : <Loading /> : "Sign in"}
                </Button>
              </form>
            </div>
            <div className="sm:flex items-baseline pt-16 text-xs sm:text-sm font-semibold sm:font-medium">
              <Link className="" to="/forgot-password">
                <Typography variant="p"> Forgot password?</Typography>
              </Link>
            </div>
          </div>

          <Box
            className="relative hidden lg:flex flex-auto items-center justify-center min-h-full p-64 lg:px-112 overflow-hidden"
            sx={{ backgroundColor: "primary.dark" }}
          >
            <svg
              className="absolute inset-0 pointer-events-none"
              viewBox="0 0 960 540"
              width="100%"
              height="100%"
              preserveAspectRatio="xMidYMax slice"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Box
                component="g"
                sx={{ color: "primary.light" }}
                className="opacity-20"
                fill="none"
                stroke="currentColor"
                strokeWidth="100"
              >
                <circle r="234" cx="196" cy="23" />
                <circle r="234" cx="790" cy="491" />
              </Box>
            </svg>
            <Box
              component="svg"
              className="absolute -top-64 -right-64 opacity-20"
              sx={{ color: "primary.light" }}
              viewBox="0 0 220 192"
              width="220px"
              height="192px"
              fill="none"
            >
              <defs>
                <pattern
                  id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect x="0" y="0" width="4" height="4" fill="currentColor" />
                </pattern>
              </defs>
              <rect
                width="220"
                height="192"
                fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
              />
            </Box>

            <div className="z-10 relative w-full max-w-2xl">
              <div className="text-7xl font-bold leading-none text-white text-center">
                <div>Travel</div>
                <div>Clearance</div>
                <div>System</div>
              </div>
              {/* <div className="mt-24 text-lg tracking-tight leading-6 text-white">
                            Chief of Staff and Head of Public Service
                        </div> */}
            </div>
          </Box>
        </Paper>
      </div>
    </Container>
  );
};
