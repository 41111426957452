import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import format from "date-fns/format";
import dayjs from "dayjs";
import Divider from "@mui/material/Divider";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
} from "@mui/material";
import NavLinkAdapter from "../../components/NavLinkAdapter";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import Pagination from "../../components/Pagination";
import { backend_url } from "../../components/Constants";

function CSApplications(props) {
  const { token } = props;
  const [trips, setTrips] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = trips.count;
  const links = trips.links;
  // const itemsPerPage = trips && trips.results ? trips.results.trip_data.length : []
  const trip_data = trips && trips.results ? trips.results : [];
  const totalPages = Math.ceil(totalItems / 50);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const todayAtMid = dayjs().set("hour", 0).startOf("hour");
  const tripDate = new Date(trip_data.length > 0 && trip_data[0].program_to);
  const todayDate = new Date(todayAtMid);
  const Difference_In_Time = todayDate.getTime() - tripDate.getTime();
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/traveller/details/?` +
          new URLSearchParams({
            trc_p: currentPage,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setTrips(data));
    }

    fetchData();
  }, [token, currentPage]);

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
      className="flex flex-col flex-auto w-full max-h-full m-8"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="sm:px-12 py-1 sm:py-3 sm:my-3">
          {trips && trip_data.length === 0 && (
            <div>
              <motion.div
                variants={item}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                className="flex flex-col flex-auto w-full max-h-full m-0 sm:m-8"
              >
                <List className="w-full m-0 p-0" disablePadding>
                  <ListItem className="px-12 py-6 my-6">
                    <ListItemText
                      primary={
                        <Typography
                          component="h5"
                          className="font-extrabold"
                          variant="h5"
                        >
                          No travel applications are available
                        </Typography>
                      }
                    />
                    <div className="flex items-center"></div>
                  </ListItem>
                </List>
              </motion.div>
            </div>
          )}
          {trips && trip_data.length > 0 && (
            <div>
              <motion.div
                variants={item}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                className="flex flex-col flex-auto w-full max-h-full m-0 sm:m-8"
              >
                <List className="w-full m-0 p-0" disablePadding>
                  {/* {Difference_In_Days >= 0 && (
                    <ListItem className="px-12 py-6 my-6">
                      <ListItemText
                        primary={
                          <Typography
                            component="h5"
                            className="text-red-700 font-extrabold"
                            variant="h5"
                          >
                            Please Upload Your After Trip Documents
                          </Typography>
                        }
                      />
                      <div className="flex items-center"></div>
                    </ListItem>
                  )} */}

                  <ListItem className="sm:px-12 py-3 my-3">
                    <ListItemText
                      classes={{
                        root: "m-0",
                        primary: "font-medium leading-5 truncate",
                      }}
                      primary={
                        <Typography component="h5" variant="h5">
                          {trips.count} Applications
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>

                <List
                  sx={{ bgcolor: "background.paper", px: 1 }}
                  disablePadding
                >
                  <ListItemButton
                    className="px-0 sm:-mx-8"
                    disableGutters
                    component={NavLinkAdapter}
                    to={
                      trip_data[0].active_trip === false &&
                      (trip_data[0].hops_checking_recommendation !== null ||
                        trip_data[0].hops_checking_recommendation !== "") &&
                      (trip_data[0].hops_checking_reasons !== null ||
                        trip_data[0].chief_reason !== null)
                        ? `/edit-form/${trip_data[0].trip_id}`
                        : trip_data[0].trip_type === "public"
                        ? trip_data[0].active_trip === false
                          ? `/form`
                          : `${trip_data[0].trip_id}`
                        : `${trip_data[0].trip_id}`
                    }
                  >
                    <IconButton sx={{ color: "secondary.dark" }}>
                      <ConnectingAirportsIcon fontSize="inherit" />
                    </IconButton>
                    <ListItemText
                      className="px-2"
                      primary={
                        <div>
                          <div className="sm:flex sm:flex-row items-center space-x-12">
                            <Typography
                              component="h6"
                              variant="h6"
                              className="sm:flex sm:flex-auto sm:items-left sm:text-left"
                            >
                              {trip_data[0].trip_type === "public"
                                ? trip_data[0].program_title
                                : `${trip_data[0].reason_for_tr} - Private Travel`}
                            </Typography>
                            {/* <Typography
                              className="flex py-6 sm:py-0 sm:flex-auto text-left sm:text-right text-8"
                              variant="p"
                            >
                              {trip_data[0].program_from !== undefined &&
                              trip_data[0].trip_type === "public"
                                ? format(
                                    new Date(trip_data[0].program_from),
                                    "dd-MMM-yyyy"
                                  )
                                : format(
                                    new Date(trip_data[0].program_from),
                                    "dd-MMM-yyyy"
                                  )}
                            </Typography> */}
                          </div>
                          <Typography variant="p">
                            {trip_data[0].user.salutation !== undefined &&
                              trip_data[0].user.salutation}
                            {". "}
                            {trip_data[0].user.first_name}{" "}
                            {trip_data[0].user.last_name} -{" "}
                            {trip_data[0].user.designation} -{" "}
                            {trip_data[0].user.designation === "CEO"
                              ? trip_data[0].user.institutions
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")
                              : trip_data[0].user.designation === "CHAIRPERSON"
                              ? trip_data[0].user.institutions
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")
                              : trip_data[0].user.designation === "PS"
                              ? trip_data[0].user.State_departments.toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")
                              : trip_data[0].user.designation === "CS" &&
                                trip_data[0].user.ministry
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}{" "}
                          </Typography>
                        </div>
                      }
                      secondary={
                        <Typography variant="p">
                          {trip_data[0].country}
                        </Typography>
                      }
                    />
                  </ListItemButton>

                  <div className="flex mb-4 px-24">
                    <Typography
                      variant="p"
                      className="text-8 font-medium"
                      color="secondary"
                    >
                      {/* {trip_data[0].trip_type === "private" ? (
                        <span className="text-green-500"> Private travel </span>
                      ) :  */}
                      {trip_data[0].chief_confirm === "rejected" ||
                      trip_data[0].he_confirm === "rejected" ? (
                        <span className="text-red-500">
                          {" "}
                          Application not approved{" "}
                        </span>
                      ) : trip_data[0].active_trip === false &&
                        (trip_data[0].hops_checking_recommendation !== null ||
                          trip_data[0].hops_checking_recommendation !== "") &&
                        (trip_data[0].hops_checking_reasons !== null ||
                          trip_data[0].chief_reason !== null) ? (
                        <span className="text-red-500">
                          {" "}
                          Application returned - Make an appeal or Edit to
                          resubmit or create a new application{" "}
                        </span>
                      ) : trip_data[0].active_trip === false ? (
                        <span className="text-green-500">
                          {" "}
                          Finish editing your application{" "}
                        </span>
                      ) : trip_data[0].reviewer === true ? (
                        <span className="text-green-500">
                          {" "}
                          Approved to travel - Click to download approval letter{" "}
                        </span>
                      ) : trip_data[0].chairperson_confirm ===
                        "not_confirmed" ? (
                        "Waiting for chairperson to concur"
                      ) : trip_data[0].chairperson_confirm === "rejected" ? (
                        <span className="text-black-800">
                          {" "}
                          Awaiting Approval{" "}
                        </span>
                      ) : trip_data[0].ps_confirm === "not_confirmed" ? (
                        "Waiting for PS to concur"
                      ) : trip_data[0].ps_confirm === "rejected" ? (
                        <span className="text-black-800">
                          {" "}
                          Awaiting Approval{" "}
                        </span>
                      ) : trip_data[0].cs_confirm === "not_confirmed" ? (
                        "Waiting for CS to concur"
                      ) : trip_data[0].chairperson_confirm === "rejected" ? (
                        <span className="text-black-800">
                          {" "}
                          Awaiting Approval{" "}
                        </span>
                      ) : trip_data[0].ps_confirm === "rejected" ? (
                        <span className="text-black-800">
                          {" "}
                          Awaiting Approval{" "}
                        </span>
                      ) : trip_data[0].cs_confirm === "rejected" ? (
                        <span className="text-black-800">
                          {" "}
                          Awaiting Approval{" "}
                        </span>
                      ) : trip_data[0].hops_checking_confirm ===
                        "not_confirmed" ? (
                        "Waiting for approval"
                      ) : trip_data[0].chairperson_confirm === "rejected" ? (
                        <span className="text-black-800">
                          {" "}
                          Awaiting Approval{" "}
                        </span>
                      ) : trip_data[0].ps_confirm === "rejected" ? (
                        <span className="text-black-800">
                          {" "}
                          Awaiting Approval{" "}
                        </span>
                      ) : trip_data[0].cs_confirm === "rejected" ? (
                        <span className="text-black-800">
                          {" "}
                          Awaiting Approval{" "}
                        </span>
                      ) : trip_data[0].hops_checking_confirm === "rejected" ? (
                        <span className="text-black-800">
                          {" "}
                          Awaiting Approval{" "}
                        </span>
                      ) : trip_data[0].hops_confrimation_confirm ===
                        "not_confirmed" ? (
                        "Waiting for approval"
                      ) : trip_data[0].chairperson_confirm === "rejected" ? (
                        <span className="text-black-800">
                          {" "}
                          Awaiting Approval{" "}
                        </span>
                      ) : trip_data[0].ps_confirm === "rejected" ? (
                        <span className="text-black-800">
                          {" "}
                          Awaiting Approval{" "}
                        </span>
                      ) : trip_data[0].cs_confirm === "rejected" ? (
                        <span className="text-black-800">
                          {" "}
                          Awaiting Approval{" "}
                        </span>
                      ) : trip_data[0].hops_confrimation_confirm ===
                        "rejected" ? (
                        <span className="text-black-800">
                          {" "}
                          Awaiting Approval{" "}
                        </span>
                      ) : trip_data[0].chief_confirm === "not_confirmed" ? (
                        "Waiting for approval"
                      ) : trip_data[0].reviewer === false ? (
                        "Waiting for approval"
                      ) : (
                        // trip_data[0].chairperson_confirm === "accepted" &&
                        // trip_data[0].cs_confirm === "accepted" &&
                        // trip_data[0].hops_confrimation_confirm === "accepted" &&
                        // trip_data[0].hops_checking_confirm === "accepted" &&
                        trip_data[0].reviewer === true && (
                          <span className="text-green-500">
                            {" "}
                            Approved to travel - Click to download approval
                            letter{" "}
                          </span>
                        )
                      )}
                    </Typography>
                  </div>
                </List>
                <Divider />
              </motion.div>

              <motion.div variants={item}>
                {trip_data.map((trip_details) => {
                  return (
                    trip_details.trip_id !== trip_data[0].trip_id && (
                      <List
                        key={trip_details.trip_id}
                        disablePadding
                        sx={{ bgcolor: "background.paper", px: 1, my: 2 }}
                      >
                        <ListItemButton
                          className="px-0 sm:-mx-8"
                          disableGutters
                          component={NavLinkAdapter}
                          to={`${trip_details.trip_id}`}
                        >
                          <IconButton sx={{ color: "secondary.dark" }}>
                            <ConnectingAirportsIcon fontSize="inherit" />
                          </IconButton>
                          <ListItemText
                            className="px-2"
                            primary={
                              <div>
                                <div className="sm:flex sm:flex-row items-center space-x-12">
                                  <Typography
                                    component="h6"
                                    variant="h6"
                                    className="sm:flex sm:flex-auto sm:items-left sm:text-left"
                                  >
                                    {trip_details.trip_type === "public"
                                      ? trip_details.program_title
                                      : `${trip_details.reason_for_tr} - Private Travel`}
                                  </Typography>
                                  <Typography
                                    className="flex py-6 sm:py-0 sm:flex-auto text-left sm:text-right text-8"
                                    variant="p"
                                  >
                                    {trip_details.program_from !== undefined &&
                                    trip_details.trip_type === "public"
                                      ? format(
                                          new Date(trip_details.program_from),
                                          "dd-MMM-yyyy"
                                        )
                                      : format(
                                          new Date(trip_details.program_from),
                                          "dd-MMM-yyyy"
                                        )}
                                  </Typography>
                                </div>
                                <Typography variant="p">
                                  {trip_details.user.salutation !== undefined &&
                                    trip_details.user.salutation}
                                  {". "}
                                  {trip_details.user.first_name}{" "}
                                  {trip_details.user.last_name} -{" "}
                                  {trip_details.user.designation} -{" "}
                                  {trip_details.user.designation === "CEO"
                                    ? trip_details.user.institutions
                                    : trip_details.user.designation ===
                                      "CHAIRPERSON"
                                    ? trip_details.user.institutions
                                    : trip_details.user.designation === "PS"
                                    ? trip_details.user.State_departments
                                    : trip_details.user.designation === "CS" &&
                                      trip_details.user.ministry}{" "}
                                </Typography>
                              </div>
                            }
                            secondary={
                              <Typography variant="p">
                                {trip_details.country}
                              </Typography>
                            }
                          />
                        </ListItemButton>

                        <div className="flex mb-4 px-24">
                          <Typography
                            variant="p"
                            className="text-8 font-medium"
                            color="secondary"
                          >
                            {/* {trip_details.trip_type === "private" ? (
                              <span className="text-green-500">
                                {" "}
                                Private travel{" "}
                              </span>
                            ) :  */}
                            {trip_details.chief_confirm === "rejected" ||
                            trip_details.he_confirm === "rejected" ? (
                              <span className="text-red-500">
                                {" "}
                                Application not approved{" "}
                              </span>
                            ) : trip_details.active_trip === false ? (
                              <span className="text-green-500">
                                {" "}
                                Unfinished application - Create a new one{" "}
                              </span>
                            ) : trip_details.reviewer === true ? (
                              <span className="text-green-500">
                                {" "}
                                Approved to travel - Click to download approval
                                letter{" "}
                              </span>
                            ) : trip_details.chairperson_confirm ===
                              "not_confirmed" ? (
                              "Waiting for chairperson to concur"
                            ) : trip_details.chairperson_confirm ===
                              "rejected" ? (
                              <span className="text-black-800">
                                {" "}
                                Awaiting Approval{" "}
                              </span>
                            ) : trip_details.ps_confirm === "not_confirmed" ? (
                              "Waiting for PS to concur"
                            ) : trip_details.ps_confirm === "rejected" ? (
                              <span className="text-black-800">
                                {" "}
                                Awaiting Approval{" "}
                              </span>
                            ) : trip_details.cs_confirm === "not_confirmed" ? (
                              "Waiting for CS to concur"
                            ) : trip_details.chairperson_confirm ===
                              "rejected" ? (
                              <span className="text-black-800">
                                {" "}
                                Awaiting Approval{" "}
                              </span>
                            ) : trip_details.ps_confirm === "rejected" ? (
                              <span className="text-black-800">
                                {" "}
                                Awaiting Approval{" "}
                              </span>
                            ) : trip_details.cs_confirm === "rejected" ? (
                              <span className="text-black-800">
                                {" "}
                                Awaiting Approval{" "}
                              </span>
                            ) : trip_details.hops_checking_confirm ===
                              "not_confirmed" ? (
                              "Waiting for approval"
                            ) : trip_details.chairperson_confirm ===
                              "rejected" ? (
                              <span className="text-black-800">
                                {" "}
                                Awaiting Approval{" "}
                              </span>
                            ) : trip_details.ps_confirm === "rejected" ? (
                              <span className="text-black-800">
                                {" "}
                                Awaiting Approval{" "}
                              </span>
                            ) : trip_details.cs_confirm === "rejected" ? (
                              <span className="text-black-800">
                                {" "}
                                Awaiting Approval{" "}
                              </span>
                            ) : trip_details.hops_checking_confirm ===
                              "rejected" ? (
                              <span className="text-black-800">
                                {" "}
                                Awaiting Approval{" "}
                              </span>
                            ) : trip_details.hops_confrimation_confirm ===
                              "not_confirmed" ? (
                              "Waiting for approval"
                            ) : trip_details.chairperson_confirm ===
                              "rejected" ? (
                              <span className="text-black-800">
                                {" "}
                                Awaiting Approval{" "}
                              </span>
                            ) : trip_details.ps_confirm === "rejected" ? (
                              <span className="text-black-800">
                                {" "}
                                Awaiting Approval{" "}
                              </span>
                            ) : trip_details.cs_confirm === "rejected" ? (
                              <span className="text-black-800">
                                {" "}
                                Awaiting Approval{" "}
                              </span>
                            ) : trip_details.hops_confrimation_confirm ===
                              "rejected" ? (
                              <span className="text-black-800">
                                {" "}
                                Awaiting Approval{" "}
                              </span>
                            ) : trip_details.chief_confirm ===
                              "not_confirmed" ? (
                              "Waiting for approval"
                            ) : trip_details.reviewer === false ? (
                              "Waiting for approval"
                            ) : (
                              // trip_details.chairperson_confirm === "accepted" &&
                              // trip_details.cs_confirm === "accepted" &&
                              // trip_details.hops_confrimation_confirm ===
                              //   "accepted" &&
                              // trip_details.hops_checking_confirm ===
                              //   "accepted" &&
                              trip_details.reviewer === true && (
                                <span className="text-green-500">
                                  {" "}
                                  Approved to travel - Click to download
                                  approval letter{" "}
                                </span>
                              )
                            )}
                          </Typography>
                        </div>
                        <Divider />
                      </List>
                    )
                  );
                })}
              </motion.div>
            </div>
          )}

          {trip_data && trip_data.length > 0 && (
            <div className="my-16 mx-8">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                previousPage={links.previous}
                nextPage={links.next}
              />
            </div>
          )}
        </div>
      </Box>
    </motion.div>
  );
}

export default CSApplications;
