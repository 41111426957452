import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

export const PersonalDetails = (props) => {
  const { trip, actingOfficer } = props;

  return (
    <div>
      <div className="py-8">
        <Typography
          component="h5"
          variant="p"
          className="text-md sm:text-lg font-semibold"
        >
          FILLED BY THE APPLICANT:
        </Typography>
        <Typography
          component="h5"
          variant="p"
          className="text-md sm:text-lg font-semibold"
        >
          PART I - PERSONAL DETAILS
        </Typography>
      </div>

      <div>
        <Typography
          component="p"
          variant="p"
          className="text-xs font-medium py-8 "
        >
          Officer Travelling Details:
        </Typography>

        {trip.user && (
          <>
            <Typography
              component="p"
              variant="p"
              className=" text-xs font-bold py-4 sm:py-8 "
            >
              Name:
              {trip.user.salutation !== undefined && (
                <span className="pl-4 sm:pl-10 text-xs font-normal">
                  {" "}
                  {trip.user.salutation}.
                </span>
              )}
              <span className="px-1 text-xs font-normal">
                {" "}
                {trip.user.first_name}
              </span>
              <span className="px-1 text-xs font-normal">
                {" "}
                {trip.user.last_name}{" "}
              </span>
            </Typography>

            <Typography
              component="p"
              variant="p"
              className=" text-xs font-bold py-4 sm:py-8 "
            >
              Personal No:
              <span className="pl-4 sm:pl-10 text-xs font-normal">
                {" "}
                {trip.user.personal_no}
              </span>
            </Typography>

            <Typography
              component="p"
              variant="p"
              className=" text-xs font-bold py-4 sm:py-8 "
            >
              Designation:
              <span className="pl-4 sm:pl-10 text-xs font-normal">
                {trip.user.designation}
              </span>
            </Typography>

            <Typography
              component="p"
              variant="p"
              className=" text-xs font-bold py-4 sm:py-8 "
            >
              Ministry/Corporation:
              <span className="pl-4 sm:pl-10 text-xs font-normal">
                {" "}
                {trip.user.ministry}
              </span>
            </Typography>

            <Typography
              component="p"
              variant="p"
              className=" text-xs font-bold py-4 sm:py-8 "
            >
              Passport Number:
              <span className="pl-4 sm:pl-10 text-xs font-normal">
                {" "}
                {trip.user.passport_no}
              </span>
            </Typography>
          </>
        )}

        <Divider className="py-2 my-2" />
        {actingOfficer && (
          <>
            <Typography
              component="p"
              variant="p"
              className="text-xs font-medium py-8 "
            >
              Acting Officer
              <span className="text-xs font-medium py-8">
                (To exercise delegated authority when applicant is away){" "}
              </span>
            </Typography>

            <Typography
              component="p"
              variant="p"
              className=" text-xs font-bold py-4 sm:py-8 "
            >
              Name:
              <span className="pl-4 sm:pl-10 text-xs font-normal">
                {" "}
                {actingOfficer.full_name}
              </span>
            </Typography>

            <Typography
              component="p"
              variant="p"
              className=" text-xs font-bold py-4 sm:py-8 "
            >
              Personal No:
              <span className="pl-4 sm:pl-10 text-xs font-normal">
                {" "}
                {actingOfficer.personal_no}
              </span>
            </Typography>

            <Typography
              component="p"
              variant="p"
              className=" text-xs font-bold py-4 sm:py-8 "
            >
              Designation:
              <span className="pl-4 sm:pl-10 text-xs font-normal">
                {actingOfficer.designation === "Other"
                  ? actingOfficer.designation_new
                  : actingOfficer.designation}
              </span>
            </Typography>

            <Typography
              component="p"
              variant="p"
              className=" text-xs font-bold py-4 sm:py-8 "
            >
              Ministry/Corporation:
              <span className="pl-4 sm:pl-10 text-xs font-normal">
                {" "}
                {actingOfficer.ministry}
              </span>
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};
