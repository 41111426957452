import React from "react";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import his_excellency from "../../assets/his_excellency.jpg";

function HEHeader(props) {
  const { user } = props;

  return (
    user && (
      <div className="flex flex-col w-full px-8 sm:px-32">
        <div className="flex flex-col md:flex-row flex-auto md:items-center min-w-0 mb-24 mt-48 sm:mt-64 sm:mb-16">
          <div className="flex flex-auto items-center min-w-0">
            <Avatar
              className="flex-0 w-64 h-64"
              alt="user photo"
              src={his_excellency}
            >
              Dr William Samoei Ruto
            </Avatar>
            <div className="flex flex-col min-w-0 ml-16">
              <Typography
                variant="p"
                className="text-2xl font-semibold tracking-tight leading-7 md:leading-snug "
              >
                Welcome back Mr. President!
              </Typography>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default HEHeader;
