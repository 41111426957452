export const countries = [
  {
    Serial_number: 1,
    Country: "Afghanistan",
  },
  {
    Serial_number: 2,
    Country: "Albania",
  },
  {
    Serial_number: 3,
    Country: "Algeria",
  },
  {
    Serial_number: 4,
    Country: "Angola",
  },
  {
    Serial_number: 5,
    Country: "Antigua & Barbuda",
  },
  {
    Serial_number: 6,
    Country: "Argentina",
  },
  {
    Serial_number: 7,
    Country: "Armenia",
  },
  {
    Serial_number: 8,
    Country: "Aruba",
  },
  {
    Serial_number: 9,
    Country: "Australia",
  },
  {
    Serial_number: 10,
    Country: "Austria",
  },
  {
    Serial_number: 11,
    Country: "Azerbaijan",
  },
  {
    Serial_number: 12,
    Country: "Bahamas",
  },
  {
    Serial_number: 13,
    Country: "Bahrain",
  },
  {
    Serial_number: 14,
    Country: "Bangladesh",
  },
  {
    Serial_number: 15,
    Country: "Barbados",
  },
  {
    Serial_number: 16,
    Country: "Belarus",
  },
  {
    Serial_number: 17,
    Country: "Belgium",
  },
  {
    Serial_number: 18,
    Country: "Belize",
  },
  {
    Serial_number: 19,
    Country: "Benin",
  },
  {
    Serial_number: 20,
    Country: "Bermuda",
  },
  {
    Serial_number: 21,
    Country: "Bhutan",
  },
  {
    Serial_number: 22,
    Country: "Bolivia",
  },
  {
    Serial_number: 23,
    Country: "Bosnia & Herzegovina",
  },
  {
    Serial_number: 24,
    Country: "Botswana",
  },
  {
    Serial_number: 25,
    Country: "Brazil",
  },
  {
    Serial_number: 26,
    Country: "Brunei",
  },
  {
    Serial_number: 27,
    Country: "Bulgaria",
  },
  {
    Serial_number: 28,
    Country: "Burkina Faso",
  },
  {
    Serial_number: 29,
    Country: "Burundi",
  },
  {
    Serial_number: 30,
    Country: "Cambodia",
  },
  {
    Serial_number: 31,
    Country: "Cameroon",
  },
  {
    Serial_number: 32,
    Country: "Canada",
  },
  {
    Serial_number: 33,
    Country: "Cape Verde",
  },
  {
    Serial_number: 34,
    Country: "Central African Republic",
  },
  {
    Serial_number: 35,
    Country: "Chad",
  },
  {
    Serial_number: 36,
    Country: "Chile",
  },
  {
    Serial_number: 37,
    Country: "China",
  },
  {
    Serial_number: 38,
    Country: "Colombia",
  },
  {
    Serial_number: 39,
    Country: "Comoros",
  },
  {
    Serial_number: 40,
    Country: "Congo",
  },
  {
    Serial_number: 41,
    Country: "Costa Rica",
  },
  {
    Serial_number: 42,
    Country: "Cote d'Ivoire",
  },
  {
    Serial_number: 43,
    Country: "Croatia",
  },
  {
    Serial_number: 44,
    Country: "Cuba",
  },
  {
    Serial_number: 45,
    Country: "Cyprus",
  },
  {
    Serial_number: 46,
    Country: "Czech Republic",
  },
  {
    Serial_number: 47,
    Country: "Denmark",
  },
  {
    Serial_number: 48,
    Country: "Djibouti",
  },
  {
    Serial_number: 49,
    Country: "Dominica",
  },
  {
    Serial_number: 50,
    Country: "Dominican Republic",
  },
  {
    Serial_number: 51,
    Country: "DRC Congo",
  },
  {
    Serial_number: 52,
    Country: "Ecuador",
  },
  {
    Serial_number: 53,
    Country: "Egypt",
  },
  {
    Serial_number: 54,
    Country: "El Salvador",
  },
  {
    Serial_number: 55,
    Country: "Equatorial Guinea",
  },
  {
    Serial_number: 56,
    Country: "Eritrea",
  },
  {
    Serial_number: 57,
    Country: "Estonia",
  },
  {
    Serial_number: 58,
    Country: "Ethiopia",
  },
  {
    Serial_number: 59,
    Country: "Fiji",
  },
  {
    Serial_number: 60,
    Country: "Finland",
  },
  {
    Serial_number: 61,
    Country: "France",
  },
  {
    Serial_number: 62,
    Country: "French Guiana",
  },
  {
    Serial_number: 63,
    Country: "Gabon",
  },
  {
    Serial_number: 64,
    Country: "Gambia",
  },
  {
    Serial_number: 65,
    Country: "Georgia",
  },
  {
    Serial_number: 66,
    Country: "Germany",
  },
  {
    Serial_number: 67,
    Country: "Ghana",
  },
  {
    Serial_number: 68,
    Country: "Greece",
  },
  {
    Serial_number: 69,
    Country: "Grenada",
  },
  {
    Serial_number: 70,
    Country: "Guadeloupe",
  },
  {
    Serial_number: 71,
    Country: "Guatemala",
  },
  {
    Serial_number: 72,
    Country: "Guinea",
  },
  {
    Serial_number: 73,
    Country: "Guinea Bissau",
  },
  {
    Serial_number: 74,
    Country: "Guyana",
  },
  {
    Serial_number: 75,
    Country: "Haiti",
  },
  {
    Serial_number: 76,
    Country: "Honduras",
  },
  {
    Serial_number: 77,
    Country: "Hungary",
  },
  {
    Serial_number: 78,
    Country: "Iceland",
  },
  {
    Serial_number: 79,
    Country: "India",
  },
  {
    Serial_number: 80,
    Country: "Indonesia",
  },
  {
    Serial_number: 81,
    Country: "Iran",
  },
  {
    Serial_number: 82,
    Country: "Iraq",
  },
  {
    Serial_number: 83,
    Country: "Ireland",
  },
  {
    Serial_number: 84,
    Country: "Israel",
  },
  {
    Serial_number: 85,
    Country: "Italy",
  },
  {
    Serial_number: 86,
    Country: "Jamaica",
  },
  {
    Serial_number: 87,
    Country: "Japan",
  },
  {
    Serial_number: 88,
    Country: "Jordan",
  },
  {
    Serial_number: 89,
    Country: "Kazakhstan",
  },
  {
    Serial_number: 90,
    Country: "Kiribati",
  },
  {
    Serial_number: 91,
    Country: "Korea",
  },
  {
    Serial_number: 92,
    Country: "Kosovo",
  },
  {
    Serial_number: 93,
    Country: "Kuwait",
  },
  {
    Serial_number: 94,
    Country: "Kyrgyzstan",
  },
  {
    Serial_number: 95,
    Country: "Laos",
  },
  {
    Serial_number: 96,
    Country: "Latvia",
  },
  {
    Serial_number: 97,
    Country: "Lebanon",
  },
  {
    Serial_number: 98,
    Country: "Lesotho",
  },
  {
    Serial_number: 99,
    Country: "Liberia",
  },
  {
    Serial_number: 100,
    Country: "Libya",
  },
  {
    Serial_number: 101,
    Country: "Liechtenstein",
  },
  {
    Serial_number: 102,
    Country: "Lithuania",
  },
  {
    Serial_number: 103,
    Country: "Luxembourg",
  },
  {
    Serial_number: 104,
    Country: "Macedonia",
  },
  {
    Serial_number: 105,
    Country: "Madagascar",
  },
  {
    Serial_number: 106,
    Country: "Malawi",
  },
  {
    Serial_number: 107,
    Country: "Malaysia",
  },
  {
    Serial_number: 108,
    Country: "Maldives",
  },
  {
    Serial_number: 109,
    Country: "Mali",
  },
  {
    Serial_number: 110,
    Country: "Malta",
  },
  {
    Serial_number: 111,
    Country: "Marshall Islands",
  },
  {
    Serial_number: 112,
    Country: "Mauritania",
  },
  {
    Serial_number: 113,
    Country: "Mauritius",
  },
  {
    Serial_number: 114,
    Country: "Mexico",
  },
  {
    Serial_number: 115,
    Country: "Micronesia",
  },
  {
    Serial_number: 116,
    Country: "Moldova",
  },
  {
    Serial_number: 117,
    Country: "Monaco",
  },
  {
    Serial_number: 118,
    Country: "Mongolia",
  },
  {
    Serial_number: 119,
    Country: "Montenegro",
  },
  {
    Serial_number: 120,
    Country: "Morocco",
  },
  {
    Serial_number: 121,
    Country: "Mozambique",
  },
  {
    Serial_number: 122,
    Country: "Myanmar",
  },
  {
    Serial_number: 123,
    Country: "Namibia",
  },
  {
    Serial_number: 124,
    Country: "Nauru",
  },
  {
    Serial_number: 125,
    Country: "Nepal",
  },
  {
    Serial_number: 126,
    Country: "Netherlands",
  },
  {
    Serial_number: 127,
    Country: "New Zealand",
  },
  {
    Serial_number: 128,
    Country: "Nicaragua",
  },
  {
    Serial_number: 129,
    Country: "Niger",
  },
  {
    Serial_number: 130,
    Country: "Nigeria",
  },
  {
    Serial_number: 131,
    Country: "North Korea",
  },
  {
    Serial_number: 132,
    Country: "Norway",
  },
  {
    Serial_number: 133,
    Country: "Oman",
  },
  {
    Serial_number: 134,
    Country: "Pakistan",
  },
  {
    Serial_number: 135,
    Country: "Palau",
  },
  {
    Serial_number: 136,
    Country: "Panama",
  },
  {
    Serial_number: 137,
    Country: "Papua New Guinea",
  },
  {
    Serial_number: 138,
    Country: "Paraguay",
  },
  {
    Serial_number: 139,
    Country: "Peru",
  },
  {
    Serial_number: 140,
    Country: "Philippines",
  },
  {
    Serial_number: 141,
    Country: "Poland",
  },
  {
    Serial_number: 142,
    Country: "Portugal",
  },
  {
    Serial_number: 143,
    Country: "Puerto Rico",
  },
  {
    Serial_number: 144,
    Country: "Qatar",
  },
  {
    Serial_number: 145,
    Country: "Reunion",
  },
  {
    Serial_number: 146,
    Country: "Romania",
  },
  {
    Serial_number: 147,
    Country: "Russia",
  },
  {
    Serial_number: 148,
    Country: "Rwanda",
  },
  {
    Serial_number: 149,
    Country: "Samoa",
  },
  {
    Serial_number: 150,
    Country: "San Marino",
  },
  {
    Serial_number: 151,
    Country: "Sao Tome & Principe",
  },
  {
    Serial_number: 152,
    Country: "Saudi Arabia",
  },
  {
    Serial_number: 153,
    Country: "Senegal",
  },
  {
    Serial_number: 154,
    Country: "Serbia",
  },
  {
    Serial_number: 155,
    Country: "Seychelles",
  },
  {
    Serial_number: 156,
    Country: "Sierra Leone",
  },
  {
    Serial_number: 157,
    Country: "Singapore",
  },
  {
    Serial_number: 158,
    Country: "Slovakia",
  },
  {
    Serial_number: 159,
    Country: "Slovenia",
  },
  {
    Serial_number: 160,
    Country: "Solomon Islands",
  },
  {
    Serial_number: 161,
    Country: "Somalia",
  },
  {
    Serial_number: 162,
    Country: "South Africa",
  },
  {
    Serial_number: 163,
    Country: "South Sudan",
  },
  {
    Serial_number: 164,
    Country: "Spain",
  },
  {
    Serial_number: 165,
    Country: "Sri Lanka",
  },
  {
    Serial_number: 166,
    Country: "St. Kitts and Nevis",
  },
  {
    Serial_number: 167,
    Country: "St. Lucia",
  },
  {
    Serial_number: 168,
    Country: "St. Vincent and Grenadines",
  },
  {
    Serial_number: 169,
    Country: "Sudan",
  },
  {
    Serial_number: 170,
    Country: "Surinam",
  },
  {
    Serial_number: 171,
    Country: "Swaziland",
  },
  {
    Serial_number: 172,
    Country: "Sweden",
  },
  {
    Serial_number: 173,
    Country: "Switzerland",
  },
  {
    Serial_number: 174,
    Country: "Syria",
  },
  {
    Serial_number: 175,
    Country: "Tajikistan",
  },
  {
    Serial_number: 176,
    Country: "Tanzania",
  },
  {
    Serial_number: 177,
    Country: "Thailand",
  },
  {
    Serial_number: 178,
    Country: "Timor - Leste",
  },
  {
    Serial_number: 179,
    Country: "Tobago",
  },
  {
    Serial_number: 180,
    Country: "Togo",
  },
  {
    Serial_number: 181,
    Country: "Tonoa",
  },
  {
    Serial_number: 182,
    Country: "Trinidad",
  },
  {
    Serial_number: 183,
    Country: "Tunisia",
  },
  {
    Serial_number: 184,
    Country: "Turkey",
  },
  {
    Serial_number: 185,
    Country: "Turkmenistan",
  },
  {
    Serial_number: 186,
    Country: "Tuvalu",
  },
  {
    Serial_number: 187,
    Country: "Uganda",
  },
  {
    Serial_number: 188,
    Country: "Ukraine",
  },
  {
    Serial_number: 189,
    Country: "United Arab Emirates",
  },
  {
    Serial_number: 190,
    Country: "United Kingdom",
  },
  {
    Serial_number: 191,
    Country: "Uruguay",
  },
  {
    Serial_number: 192,
    Country: "USA",
  },
  {
    Serial_number: 193,
    Country: "Uzbekistan",
  },
  {
    Serial_number: 194,
    Country: "Vanuatu",
  },
  {
    Serial_number: 195,
    Country: "Venezuela",
  },
  {
    Serial_number: 196,
    Country: "Vietnam",
  },
  {
    Serial_number: 197,
    Country: "Yemen",
  },
  {
    Serial_number: 198,
    Country: "Zambia",
  },
  {
    Serial_number: 199,
    Country: "Zimbabwe",
  },
];



export const countries_with_Kenya = [
  {
    Serial_number: 1,
    Country: "Afghanistan",
  },
  {
    Serial_number: 2,
    Country: "Albania",
  },
  {
    Serial_number: 3,
    Country: "Algeria",
  },
  {
    Serial_number: 4,
    Country: "Angola",
  },
  {
    Serial_number: 5,
    Country: "Antigua & Barbuda",
  },
  {
    Serial_number: 6,
    Country: "Argentina",
  },
  {
    Serial_number: 7,
    Country: "Armenia",
  },
  {
    Serial_number: 8,
    Country: "Aruba",
  },
  {
    Serial_number: 9,
    Country: "Australia",
  },
  {
    Serial_number: 10,
    Country: "Austria",
  },
  {
    Serial_number: 11,
    Country: "Azerbaijan",
  },
  {
    Serial_number: 12,
    Country: "Bahamas",
  },
  {
    Serial_number: 13,
    Country: "Bahrain",
  },
  {
    Serial_number: 14,
    Country: "Bangladesh",
  },
  {
    Serial_number: 15,
    Country: "Barbados",
  },
  {
    Serial_number: 16,
    Country: "Belarus",
  },
  {
    Serial_number: 17,
    Country: "Belgium",
  },
  {
    Serial_number: 18,
    Country: "Belize",
  },
  {
    Serial_number: 19,
    Country: "Benin",
  },
  {
    Serial_number: 20,
    Country: "Bermuda",
  },
  {
    Serial_number: 21,
    Country: "Bhutan",
  },
  {
    Serial_number: 22,
    Country: "Bolivia",
  },
  {
    Serial_number: 23,
    Country: "Bosnia & Herzegovina",
  },
  {
    Serial_number: 24,
    Country: "Botswana",
  },
  {
    Serial_number: 25,
    Country: "Brazil",
  },
  {
    Serial_number: 26,
    Country: "Brunei",
  },
  {
    Serial_number: 27,
    Country: "Bulgaria",
  },
  {
    Serial_number: 28,
    Country: "Burkina Faso",
  },
  {
    Serial_number: 29,
    Country: "Burundi",
  },
  {
    Serial_number: 30,
    Country: "Cambodia",
  },
  {
    Serial_number: 31,
    Country: "Cameroon",
  },
  {
    Serial_number: 32,
    Country: "Canada",
  },
  {
    Serial_number: 33,
    Country: "Cape Verde",
  },
  {
    Serial_number: 34,
    Country: "Central African Republic",
  },
  {
    Serial_number: 35,
    Country: "Chad",
  },
  {
    Serial_number: 36,
    Country: "Chile",
  },
  {
    Serial_number: 37,
    Country: "China",
  },
  {
    Serial_number: 38,
    Country: "Colombia",
  },
  {
    Serial_number: 39,
    Country: "Comoros",
  },
  {
    Serial_number: 40,
    Country: "Congo",
  },
  {
    Serial_number: 41,
    Country: "Costa Rica",
  },
  {
    Serial_number: 42,
    Country: "Cote d'Ivoire",
  },
  {
    Serial_number: 43,
    Country: "Croatia",
  },
  {
    Serial_number: 44,
    Country: "Cuba",
  },
  {
    Serial_number: 45,
    Country: "Cyprus",
  },
  {
    Serial_number: 46,
    Country: "Czech Republic",
  },
  {
    Serial_number: 47,
    Country: "Denmark",
  },
  {
    Serial_number: 48,
    Country: "Djibouti",
  },
  {
    Serial_number: 49,
    Country: "Dominica",
  },
  {
    Serial_number: 50,
    Country: "Dominican Republic",
  },
  {
    Serial_number: 51,
    Country: "DRC Congo",
  },
  {
    Serial_number: 52,
    Country: "Ecuador",
  },
  {
    Serial_number: 53,
    Country: "Egypt",
  },
  {
    Serial_number: 54,
    Country: "El Salvador",
  },
  {
    Serial_number: 55,
    Country: "Equatorial Guinea",
  },
  {
    Serial_number: 56,
    Country: "Eritrea",
  },
  {
    Serial_number: 57,
    Country: "Estonia",
  },
  {
    Serial_number: 58,
    Country: "Ethiopia",
  },
  {
    Serial_number: 59,
    Country: "Fiji",
  },
  {
    Serial_number: 60,
    Country: "Finland",
  },
  {
    Serial_number: 61,
    Country: "France",
  },
  {
    Serial_number: 62,
    Country: "French Guiana",
  },
  {
    Serial_number: 63,
    Country: "Gabon",
  },
  {
    Serial_number: 64,
    Country: "Gambia",
  },
  {
    Serial_number: 65,
    Country: "Georgia",
  },
  {
    Serial_number: 66,
    Country: "Germany",
  },
  {
    Serial_number: 67,
    Country: "Ghana",
  },
  {
    Serial_number: 68,
    Country: "Greece",
  },
  {
    Serial_number: 69,
    Country: "Grenada",
  },
  {
    Serial_number: 70,
    Country: "Guadeloupe",
  },
  {
    Serial_number: 71,
    Country: "Guatemala",
  },
  {
    Serial_number: 72,
    Country: "Guinea",
  },
  {
    Serial_number: 73,
    Country: "Guinea Bissau",
  },
  {
    Serial_number: 74,
    Country: "Guyana",
  },
  {
    Serial_number: 75,
    Country: "Haiti",
  },
  {
    Serial_number: 76,
    Country: "Honduras",
  },
  {
    Serial_number: 77,
    Country: "Hungary",
  },
  {
    Serial_number: 78,
    Country: "Iceland",
  },
  {
    Serial_number: 79,
    Country: "India",
  },
  {
    Serial_number: 80,
    Country: "Indonesia",
  },
  {
    Serial_number: 81,
    Country: "Iran",
  },
  {
    Serial_number: 82,
    Country: "Iraq",
  },
  {
    Serial_number: 83,
    Country: "Ireland",
  },
  {
    Serial_number: 84,
    Country: "Israel",
  },
  {
    Serial_number: 85,
    Country: "Italy",
  },
  {
    Serial_number: 86,
    Country: "Jamaica",
  },
  {
    Serial_number: 87,
    Country: "Japan",
  },
  {
    Serial_number: 88,
    Country: "Jordan",
  },
  {
    Serial_number: 89,
    Country: "Kazakhstan",
  },
  {
    Serial_number: 90,
    Country: "Kiribati",
  },
  {
    Serial_number: 91,
    Country: "Korea",
  },
  {
    Serial_number: 92,
    Country: "Kosovo",
  },
  {
    Serial_number: 93,
    Country: "Kuwait",
  },
  {
    Serial_number: 94,
    Country: "Kyrgyzstan",
  },
  {
    Serial_number: 95,
    Country: "Laos",
  },
  {
    Serial_number: 96,
    Country: "Latvia",
  },
  {
    Serial_number: 97,
    Country: "Lebanon",
  },
  {
    Serial_number: 98,
    Country: "Lesotho",
  },
  {
    Serial_number: 99,
    Country: "Liberia",
  },
  {
    Serial_number: 100,
    Country: "Libya",
  },
  {
    Serial_number: 101,
    Country: "Liechtenstein",
  },
  {
    Serial_number: 102,
    Country: "Lithuania",
  },
  {
    Serial_number: 103,
    Country: "Luxembourg",
  },
  {
    Serial_number: 104,
    Country: "Macedonia",
  },
  {
    Serial_number: 105,
    Country: "Madagascar",
  },
  {
    Serial_number: 106,
    Country: "Malawi",
  },
  {
    Serial_number: 107,
    Country: "Malaysia",
  },
  {
    Serial_number: 108,
    Country: "Maldives",
  },
  {
    Serial_number: 109,
    Country: "Mali",
  },
  {
    Serial_number: 110,
    Country: "Malta",
  },
  {
    Serial_number: 111,
    Country: "Marshall Islands",
  },
  {
    Serial_number: 112,
    Country: "Mauritania",
  },
  {
    Serial_number: 113,
    Country: "Mauritius",
  },
  {
    Serial_number: 114,
    Country: "Mexico",
  },
  {
    Serial_number: 115,
    Country: "Micronesia",
  },
  {
    Serial_number: 116,
    Country: "Moldova",
  },
  {
    Serial_number: 117,
    Country: "Monaco",
  },
  {
    Serial_number: 118,
    Country: "Mongolia",
  },
  {
    Serial_number: 119,
    Country: "Montenegro",
  },
  {
    Serial_number: 120,
    Country: "Morocco",
  },
  {
    Serial_number: 121,
    Country: "Mozambique",
  },
  {
    Serial_number: 122,
    Country: "Myanmar",
  },
  {
    Serial_number: 123,
    Country: "Namibia",
  },
  {
    Serial_number: 124,
    Country: "Nauru",
  },
  {
    Serial_number: 125,
    Country: "Nepal",
  },
  {
    Serial_number: 126,
    Country: "Netherlands",
  },
  {
    Serial_number: 127,
    Country: "New Zealand",
  },
  {
    Serial_number: 128,
    Country: "Nicaragua",
  },
  {
    Serial_number: 129,
    Country: "Niger",
  },
  {
    Serial_number: 130,
    Country: "Nigeria",
  },
  {
    Serial_number: 131,
    Country: "North Korea",
  },
  {
    Serial_number: 132,
    Country: "Norway",
  },
  {
    Serial_number: 133,
    Country: "Oman",
  },
  {
    Serial_number: 134,
    Country: "Pakistan",
  },
  {
    Serial_number: 135,
    Country: "Palau",
  },
  {
    Serial_number: 136,
    Country: "Panama",
  },
  {
    Serial_number: 137,
    Country: "Papua New Guinea",
  },
  {
    Serial_number: 138,
    Country: "Paraguay",
  },
  {
    Serial_number: 139,
    Country: "Peru",
  },
  {
    Serial_number: 140,
    Country: "Philippines",
  },
  {
    Serial_number: 141,
    Country: "Poland",
  },
  {
    Serial_number: 142,
    Country: "Portugal",
  },
  {
    Serial_number: 143,
    Country: "Puerto Rico",
  },
  {
    Serial_number: 144,
    Country: "Qatar",
  },
  {
    Serial_number: 145,
    Country: "Reunion",
  },
  {
    Serial_number: 146,
    Country: "Romania",
  },
  {
    Serial_number: 147,
    Country: "Russia",
  },
  {
    Serial_number: 148,
    Country: "Rwanda",
  },
  {
    Serial_number: 149,
    Country: "Samoa",
  },
  {
    Serial_number: 150,
    Country: "San Marino",
  },
  {
    Serial_number: 151,
    Country: "Sao Tome & Principe",
  },
  {
    Serial_number: 152,
    Country: "Saudi Arabia",
  },
  {
    Serial_number: 153,
    Country: "Senegal",
  },
  {
    Serial_number: 154,
    Country: "Serbia",
  },
  {
    Serial_number: 155,
    Country: "Seychelles",
  },
  {
    Serial_number: 156,
    Country: "Sierra Leone",
  },
  {
    Serial_number: 157,
    Country: "Singapore",
  },
  {
    Serial_number: 158,
    Country: "Slovakia",
  },
  {
    Serial_number: 159,
    Country: "Slovenia",
  },
  {
    Serial_number: 160,
    Country: "Solomon Islands",
  },
  {
    Serial_number: 161,
    Country: "Somalia",
  },
  {
    Serial_number: 162,
    Country: "South Africa",
  },
  {
    Serial_number: 163,
    Country: "South Sudan",
  },
  {
    Serial_number: 164,
    Country: "Spain",
  },
  {
    Serial_number: 165,
    Country: "Sri Lanka",
  },
  {
    Serial_number: 166,
    Country: "St. Kitts and Nevis",
  },
  {
    Serial_number: 167,
    Country: "St. Lucia",
  },
  {
    Serial_number: 168,
    Country: "St. Vincent and Grenadines",
  },
  {
    Serial_number: 169,
    Country: "Sudan",
  },
  {
    Serial_number: 170,
    Country: "Surinam",
  },
  {
    Serial_number: 171,
    Country: "Swaziland",
  },
  {
    Serial_number: 172,
    Country: "Sweden",
  },
  {
    Serial_number: 173,
    Country: "Switzerland",
  },
  {
    Serial_number: 174,
    Country: "Syria",
  },
  {
    Serial_number: 175,
    Country: "Tajikistan",
  },
  {
    Serial_number: 176,
    Country: "Tanzania",
  },
  {
    Serial_number: 177,
    Country: "Thailand",
  },
  {
    Serial_number: 178,
    Country: "Timor - Leste",
  },
  {
    Serial_number: 179,
    Country: "Tobago",
  },
  {
    Serial_number: 180,
    Country: "Togo",
  },
  {
    Serial_number: 181,
    Country: "Tonoa",
  },
  {
    Serial_number: 182,
    Country: "Trinidad",
  },
  {
    Serial_number: 183,
    Country: "Tunisia",
  },
  {
    Serial_number: 184,
    Country: "Turkey",
  },
  {
    Serial_number: 185,
    Country: "Turkmenistan",
  },
  {
    Serial_number: 186,
    Country: "Tuvalu",
  },
  {
    Serial_number: 187,
    Country: "Uganda",
  },
  {
    Serial_number: 188,
    Country: "Ukraine",
  },
  {
    Serial_number: 189,
    Country: "United Arab Emirates",
  },
  {
    Serial_number: 190,
    Country: "United Kingdom",
  },
  {
    Serial_number: 191,
    Country: "Uruguay",
  },
  {
    Serial_number: 192,
    Country: "USA",
  },
  {
    Serial_number: 193,
    Country: "Uzbekistan",
  },
  {
    Serial_number: 194,
    Country: "Vanuatu",
  },
  {
    Serial_number: 195,
    Country: "Venezuela",
  },
  {
    Serial_number: 196,
    Country: "Vietnam",
  },
  {
    Serial_number: 197,
    Country: "Yemen",
  },
  {
    Serial_number: 198,
    Country: "Zambia",
  },
  {
    Serial_number: 199,
    Country: "Zimbabwe",
  },
  // {
  //   Serial_number: 200,
  //   Country: "Kenya",
  // },
];
