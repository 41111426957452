import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Avatar,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import * as yup from "yup";
// import _ from "../../@lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { lighten } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Controller, useForm } from "react-hook-form";
import TravelSnackbar from "../../components/TravelSnackbar";
import Loading from "../../components/Loading";
import kenya_flag from "../../assets/kenya_flag.png";
import { backend_url } from "../../components/Constants";
import DraftWysiwygEditor from "../editor/TextEditor";

export default function Part4B(props) {
  const {
    token,
    handleNext,
    handleBack,
    activeStep,
    steps,
    trip_data,
    delegate_data,
    depature_and_return_data,
  } = props;
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingIcon, setLoadingIcon] = useState(false);
  const [open, setOpen] = useState(delegate_data.length > 0 ? true : false);
  const [choices, setChoices] = useState([]);
  // const [dollar_ksh, setDollarKsh] = useState(
  //   trip_data.sponsor_status === "not_sponsored" ? 0 : null
  // );
  const [newdesignation, setNewdesignation] = useState(false);
  const [srcrate, setSrcrate] = useState("");
  const [job_group, setJob_group] = useState("");

  const ministries =
    choices &&
    choices.ministry !== undefined &&
    choices.ministry.map((ministries) => ministries[0]);

  const country = trip_data.country;
  const days =
    (new Date(depature_and_return_data.date_of_return).getTime() -
      new Date(depature_and_return_data.date_of_dep).getTime()) /
    (1000 * 3600 * 24);
  const days_rounded =
    days.toString().charAt(1) === "."
      ? parseInt(days.toString().charAt(0)) + 1
      : days;
  // const src_rate = trip_data.gok_cost;
  const src_rate = job_group && srcrate[country][job_group.toLowerCase()];
  // const per_diem =
  //   dollar_ksh !== null ? src_rate * days_rounded * dollar_ksh : "__";

  const per_diem = src_rate * days_rounded;

  useEffect(() => {
    fetch(`${backend_url}/traveller/choices/`)
      .then((res) => res.json())
      .then((data) => {
        setChoices(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const schema = yup.object().shape({
    // dollar_ksh: yup.string().required("This field is required"),
    full_name: yup.string().required("You must enter full name"),
    personal_no: yup.number().required("You must enter personal number"),
    designation: yup.string().required("Please enter the designation"),
    prof_qualification_prefix: yup.string().required("This field is required"),
    designation_new:
      newdesignation === false
        ? yup.string().notRequired("Please type in the designation")
        : yup.string().required("Please type in the designation"),
    prof_qualification: yup
      .string()
      .required("You must enter professional qualification"),
    ministry: yup
      .string()
      .required()
      .oneOf(
        ministries !== false ? ministries : [],
        "Please select the ministry"
      ),
    // job_group: yup
    //   .string()
    //   .notRequired()
    //   .oneOf(
    //     [
    //       "F4",
    //       "U",
    //       "V",
    //       "E5",
    //       "F1",
    //       "F2",
    //       "E3",
    //       "E4",
    //       "S",
    //       "T",
    //       "D5",
    //       "E1",
    //       "E2",
    //       "P",
    //       "Q",
    //       "R",
    //       "D1",
    //       "D2",
    //       "D3",
    //       "K",
    //       "L",
    //       "M",
    //       "N",
    //       "F",
    //       "G",
    //       "H",
    //       "I",
    //       "J",
    //       "A",
    //       "B",
    //       "C",
    //       "D",
    //       "E",
    //       "New",
    //     ],
    //     "Please select the job group"
    //   ),
    passport_no: yup
      .string()
      .required("You must enter your passport number")
      .min(4, "Number is too short - should be 4 chars minimum")
      .nullable(true),
    email_address: yup
      .string()
      .email("The email should end with .go.ke")
      .required("You must enter a valid email"),
    id_number: yup
      .string()
      .required("You must enter your ID number")
      .min(4, "Number is too short - should be 4 chars minimum")
      .nullable(true),
    telephone_no: yup
      .string()
      .required("You must enter your telephone number")
      .min(4, "Number is too short - should be 4 chars minimum")
      .nullable(true),
    role: yup.string().required("You must enter role"),
    ticket_cost: yup.number().required("This field is required"),
    participant_cost: yup.number().required("This field is required"),
    delegate_justification: (
      trip_data.user !== undefined && trip_data.user.designation === "CS"
        ? delegate_data.length >= 3
        : trip_data.user.designation === "CEO"
        ? delegate_data.length >= 0
        : delegate_data.length >= 2
    )
      ? yup.mixed().required("Please justify why you have more delegates")
      : yup.mixed().notRequired(),
  });

  const defaultValues = {
    delegate_justification: null,
    full_name: "",
    personal_no: "",
    designation: "",
    prof_qualification_prefix: "",
    designation_new: "",
    prof_qualification: "",
    ministry: "",
    email_address: "",
    id_number: "",
    telephone_no: "",
    // job_group: "",
    role: "",
    passport_no: "",
    accomo_cost: per_diem,
    ticket_cost: 0,
    participant_cost: 0,
  };

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    // isValid, dirtyFields,
    errors,
  } = formState;

  useEffect(() => {
    fetch(
      `${backend_url}/traveller/gokcost/?` +
        new URLSearchParams({
          country: country,
        }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setSrcrate(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [token, country]);

  function submit({
    full_name,
    personal_no,
    designation,
    designation_new,
    prof_qualification_prefix,
    prof_qualification,
    ministry,
    email_address,
    id_number,
    telephone_no,
    // job_group,
    role,
    passport_no,
    // accomo_cost,
    ticket_cost,
    participant_cost,
    delegate_justification,
  }) {
    const delegateInfo = {
      full_name,
      personal_no,
      designation,
      designation_new,
      prof_qualification: `${prof_qualification_prefix}: ${prof_qualification}`,
      ministry,
      email_address,
      id_number,
      telephone_no,
      job_group,
      role,
      passport_no,
      accomo_cost: per_diem,
      ticket_cost,
      participant_cost,
      delegate_justification,
      trip: trip_data.trip_id,
    };

    axios
      .put(
        `${backend_url}/traveller/delegates/`,
        delegateInfo,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        delegate_data.push(res.data);
        setOpenMessage(true);
        setSeverity("success");
        setMessage(
          "Delegate Created Successfully! You can add another delegate!"
        );
        setLoading(false);
        setLoadingIcon(false);
        reset(defaultValues);
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.code === "ERR_NETWORK" && "Network error, please try again later."
        );
        setMessage(
          err.response.data?.details
            ? err.response.data?.details
            : err.response.data
        );
        setLoading(false);
        setLoadingIcon(false);
      });
  }

  return (
    <form
      name="delegateInfo"
      noValidate
      className="flex flex-col justify-center w-full mt-8 sm:mt-32"
      onSubmit={
        handleSubmit(submit)
        // handleSubmit(submitTrip);
      }
    >
      {openMessage === true && (
        <TravelSnackbar
          openMessage={openMessage}
          severity={severity}
          message={message}
        />
      )}

      <Typography
        component="h5"
        variant="p"
        className="px-4 py-16 text-md font-semibold md:text-lg "
      >
        Costs and Size of Delegation
      </Typography>

      {delegate_data.length <= 0 && (
        <div>
          <Typography
            component="p"
            variant="p"
            className="p-4 sm:p-12 text-xs font-semibold"
          >
            Do you have delegates accompanying you?
          </Typography>

          <div className="flex items-center mt-8 sm:mt-24 sm:mx-8 space-x-12">
            <Button
              className="whitespace-nowrap"
              variant="contained"
              color="error"
              size="large"
              onClick={() => {
                setOpen(false);
                handleNext();
              }}
            >
              No
            </Button>

            <Button
              className="whitespace-nowrap"
              variant="contained"
              color="success"
              size="large"
              onClick={() => setOpen(true)}
            >
              Yes
            </Button>
          </div>
        </div>
      )}

      {delegate_data.length > 0 && (
        <List className="w-full m-0 p-0">
          <ListItem className="px-12 mt-6">
            <ListItemText
              primary={
                <Typography
                  className="font-semibold"
                  component="h5"
                  variant="h5"
                >
                  Current Delegates
                </Typography>
              }
            />
          </ListItem>

          {delegate_data.map((delegate) => (
            <div key={delegate.personal_no}>
              <ListItemButton
                className="px-0"
                disableGutters
                sx={{ bgcolor: "background.paper" }}
              >
                <Avatar sx={{ mx: 2 }} />
                <ListItemText
                  className="px-2"
                  primary={
                    <div className="flex flex-row items-center space-x-6">
                      <Typography component="h6" variant="h6">
                        {delegate.full_name},
                      </Typography>
                      <span></span>
                      <Typography
                        className="flex text-right text-8"
                        variant="p"
                      >
                        {delegate.designation === "Other"
                          ? delegate.designation_new
                          : delegate.designation}
                      </Typography>
                    </div>
                  }
                  secondary={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: delegate.role,
                      }}
                    />
                  }
                />
              </ListItemButton>
              <Divider />
            </div>
          ))}
        </List>
      )}

      {open === true && (
        <div>
          <Typography
            component="p"
            variant="p"
            className="pt-16 pb-8 sm:pt-32 sm:pb-16 text-xs font-semibold"
          >
            (This should include all ministerial/state corporation/agency
            officers attending the same event, meeting, conference, seminar,
            workshop etc)
          </Typography>

          <div>
            <Typography
              component="p"
              variant="p"
              className="p-6 sm:p-12 text-xs font-semibold"
            >
              Cost of the Trip and Sponsorship for DELEGATION:
            </Typography>
          </div>

          <div className="sm:flex m-4">
            <Controller
              name="full_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ sm: { m: 1 }, m: 1 }}
                  label="Delegate (name):"
                  id="full_name"
                  type="text"
                  error={!!errors.full_name}
                  helperText={errors?.full_name?.message}
                  variant="standard"
                  required
                  fullWidth
                />
              )}
            />

            <Controller
              name="personal_no"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ sm: { m: 1 }, m: 1 }}
                  label="Personal/Employment number"
                  id="personal_no"
                  type="number"
                  error={!!errors.personal_no}
                  helperText={errors?.personal_no?.message}
                  variant="standard"
                  required
                  fullWidth
                />
              )}
            />
          </div>

          <div className="sm:flex m-4">
            <div className="flex w-full">
              <Controller
                name="designation"
                control={control}
                render={({ field }) => (
                  <FormControl
                    sx={{ m: 1 }}
                    fullWidth
                    error={!!errors.designation}
                  >
                    <InputLabel id="designation1">Designation</InputLabel>
                    <Select
                      labelId="designation1"
                      id="designation"
                      label="Designation"
                      variant="standard"
                      {...field}
                    >
                      <MenuItem
                        value={"Other"}
                        onClick={() => setNewdesignation(true)}
                      >
                        Other
                      </MenuItem>
                      <MenuItem
                        value={"CHAIRPERSON"}
                        onClick={() => setNewdesignation(false)}
                      >
                        Chairperson
                      </MenuItem>
                      <MenuItem
                        value={"CEO"}
                        onClick={() => setNewdesignation(false)}
                      >
                        CEO
                      </MenuItem>
                      <MenuItem
                        value={"PS"}
                        onClick={() => setNewdesignation(false)}
                      >
                        PS
                      </MenuItem>
                      <MenuItem
                        value={"CS"}
                        onClick={() => setNewdesignation(false)}
                      >
                        CS
                      </MenuItem>
                    </Select>
                    <FormHelperText>
                      {errors?.designation?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </div>

            <br />

            {newdesignation === true && (
              <Controller
                name="designation_new"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ m: 1 }}
                    label="Designation Name:"
                    id="designation_new"
                    type="text"
                    error={!!errors.designation_new}
                    helperText={errors?.designation_new?.message}
                    variant="standard"
                    required
                    fullWidth
                  />
                )}
              />
            )}
          </div>

          <div className="sm:flex m-4">
            <Controller
              name="id_number"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ m: 1 }}
                  label="ID number"
                  type="number"
                  error={!!errors.id_number}
                  helperText={errors?.id_number?.message}
                  variant="standard"
                  required
                  fullWidth
                />
              )}
            />

            <div className="flex w-full">
              <div className="flex w-128">
                <Controller
                  name="prof_qualification_prefix"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      sx={{ m: 1 }}
                      fullWidth
                      error={!!errors.prof_qualification_prefix}
                    >
                      <InputLabel id="prof_qualification_prefix">
                        Professional Qualification
                      </InputLabel>
                      <Select
                        labelId="prof_qualification_prefix"
                        id="prof_qualification_prefix"
                        label="Professional Qualification"
                        variant="standard"
                        {...field}
                      >
                        <MenuItem value={"Certificate"}>Certificate</MenuItem>
                        <MenuItem value={"Diploma"}>Diploma</MenuItem>
                        <MenuItem value={"Bsc"}>Bsc</MenuItem>
                        <MenuItem value={"Msc"}>Msc</MenuItem>
                        <MenuItem value={"Phd"}>Phd</MenuItem>
                      </Select>
                      <FormHelperText>
                        {errors?.prof_qualification_prefix?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </div>

              <div className="flex w-full">
                <Controller
                  name="prof_qualification"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ sm: { m: 1 }, m: 1 }}
                      label="Highest Professional Qualification Certification:"
                      id="prof_qualification"
                      type="text"
                      error={!!errors.prof_qualification}
                      helperText={errors?.prof_qualification?.message}
                      variant="standard"
                      required
                      fullWidth
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="sm:flex m-4">
            <Controller
              name="email_address"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ sm: { m: 1 }, m: 1 }}
                  label="Email"
                  id="email"
                  type="email"
                  error={!!errors.email_address}
                  helperText={errors?.email_address?.message}
                  variant="standard"
                  required
                  fullWidth
                />
              )}
            />

            <Controller
              name="telephone_no"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ sm: { m: 1 }, m: 1 }}
                  label="Telephone number"
                  variant="standard"
                  error={!!errors.telephone_no}
                  helperText={
                    <Typography variant="caption" color="error">
                      {" "}
                      {errors?.telephone_no?.message}{" "}
                    </Typography>
                  }
                  id="telephone_no"
                  required
                  fullWidth
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          component="span"
                          sx={{ "& > img": { mr: 0, flexShrink: 0 } }}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            src={kenya_flag}
                            alt="kenya flag"
                          />
                          <span className="ml-4 font-medium">+254</span>
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>

          <div className="sm:flex m-4">
            <Controller
              name="passport_no"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ sm: { m: 1 }, m: 1 }}
                  label="Passport number"
                  id="passport_no"
                  error={!!errors.passport_no}
                  helperText={errors?.passport_no?.message}
                  variant="standard"
                  required
                  fullWidth
                />
              )}
            />

            {choices && choices.ministry !== undefined && (
              <Controller
                name="ministry"
                control={control}
                defaultValue={[]}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    sx={{ m: 1 }}
                    // disablePortal
                    id="ministry"
                    fullWidth
                    options={ministries}
                    value={value}
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Ministry"
                        variant="standard"
                      />
                    )}
                  />
                )}
              />
            )}
          </div>

          <div className="sm:flex m-4">
            <Controller
              name="job_group"
              control={control}
              render={({ field }) => (
                <FormControl
                  sx={{ sm: { m: 1 }, m: 1 }}
                  fullWidth
                  error={!!errors.job_group}
                >
                  <InputLabel id="job_group1">Job group</InputLabel>
                  <Select
                    labelId="job_group1"
                    id="job_group"
                    label="Job group"
                    variant="standard"
                    {...field}
                    value={job_group}
                    onChange={(e) => setJob_group(e.target.value)}
                  >
                    <MenuItem value={"F4"}>F4</MenuItem>
                    <MenuItem value={"U"}>U</MenuItem>
                    <MenuItem value={"V"}>V</MenuItem>
                    <MenuItem value={"E5"}>E5</MenuItem>
                    <MenuItem value={"F1"}>F1</MenuItem>
                    <MenuItem value={"F2"}>F2</MenuItem>
                    <MenuItem value={"E3"}>E3</MenuItem>
                    <MenuItem value={"E4"}>E4</MenuItem>
                    <MenuItem value={"S"}>S</MenuItem>
                    <MenuItem value={"T"}>T</MenuItem>
                    <MenuItem value={"D5"}>D5</MenuItem>
                    <MenuItem value={"E1"}>E1</MenuItem>
                    <MenuItem value={"E2"}>E2</MenuItem>
                    <MenuItem value={"P"}>P</MenuItem>
                    <MenuItem value={"Q"}>Q</MenuItem>
                    <MenuItem value={"R"}>R</MenuItem>
                    <MenuItem value={"D1"}>D1</MenuItem>
                    <MenuItem value={"D2"}>D2</MenuItem>
                    <MenuItem value={"D3"}>D3</MenuItem>
                    <MenuItem value={"K"}>K</MenuItem>
                    <MenuItem value={"L"}>L</MenuItem>
                    <MenuItem value={"M"}>M</MenuItem>
                    <MenuItem value={"N"}>N</MenuItem>
                    <MenuItem value={"F"}>F</MenuItem>
                    <MenuItem value={"G"}>G</MenuItem>
                    <MenuItem value={"H"}>H</MenuItem>
                    <MenuItem value={"I"}>I</MenuItem>
                    <MenuItem value={"J"}>J</MenuItem>
                    <MenuItem value={"A"}>A</MenuItem>
                    <MenuItem value={"B"}>B</MenuItem>
                    <MenuItem value={"C"}>C</MenuItem>
                    <MenuItem value={"D"}>D</MenuItem>
                    <MenuItem value={"E"}>E</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </div>

          <div className="sm:flex m-4">
            <DraftWysiwygEditor
              control={control}
              name="role"
              label="Role to be played during travel"
              errors={errors}
            />

            {/* <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ sm: { m: 1 }, m: 1 }}
                  label="Role to be played during travel"
                  type="text"
                  id="role"
                  error={!!errors.role}
                  helperText={errors?.role?.message}
                  variant="outlined"
                  multiline
                  minRows={3}
                  required
                  fullWidth
                />
              )}
            /> */}
          </div>

          <div className="sm:flex m-4">
            {trip_data !== undefined &&
              (trip_data.user.designation === "CS"
                ? delegate_data.length >= 3
                : trip_data.user.designation === "CEO"
                ? delegate_data.length >= 0
                : delegate_data.length >= 2) && (
                <Controller
                  control={control}
                  name="delegate_justification"
                  render={({ field: { onChange, value } }) => (
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? lighten(theme.palette.background.default, 1)
                            : lighten(theme.palette.background.default, 0.02),
                      }}
                      component="label"
                      htmlFor="button_delegate_justification"
                      className="flex items-center justify-center relative w-82 h-64 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
                    >
                      <input
                        accept=".jpeg, .jpg, .png, .pdf,"
                        className="hidden"
                        id="button_delegate_justification"
                        type="file"
                        onChange={(e) => onChange(e.target.files[0])}
                      />
                      <IconButton>
                        <FileUploadIcon sx={{ color: "action" }} />
                      </IconButton>

                      <Typography
                        variant="p"
                        component="p"
                        className="text-xs font-small pl-8 pr-16"
                      >
                        {value === null ? (
                          <span> Delegate Justification Letter Upload</span>
                        ) : (
                          value.name
                        )}
                      </Typography>
                      <FormHelperText>
                        {" "}
                        <span className="flex flex-col text-red-500">
                          {errors?.delegate_justification?.message}
                        </span>
                      </FormHelperText>
                    </Box>
                  )}
                />
              )}
          </div>

          <div className="py-8"></div>

          {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-12 w-full min-w-0 py-16 pb-32">
            <CBKRatesWidget />
            <TravelRatesWidget />
          </div> */}

          <TableContainer
            component={Paper}
            elevation={1}
            sx={{ paddingBottom: 0, marginBottom: 3 }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ backgroundColor: "primary.light" }}>
                  {/* <TableCell>
                    U.S Dollar to Ksh as per today CBK rates
                  </TableCell> */}
                  <TableCell align="right">Number of days travelling</TableCell>
                  <TableCell align="right">Country</TableCell>
                  <TableCell align="right">Job group</TableCell>
                  <TableCell align="right">
                    Subsistence/Accommodation (Per Diem) in &nbsp;(U.S $)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {/* <TableCell component="th" scope="row">
                    <TextField
                      disabled={
                        trip_data.sponsor_status === "not_sponsored"
                          ? true
                          : false
                      }
                      value={dollar_ksh}
                      onChange={(e) => {
                        setDollarKsh(e.target.value);
                      }}
                      label="U.S Dollar to Ksh rate"
                      id="dollar_ksh"
                      error={!!errors.dollar_ksh}
                      helperText={errors?.dollar_ksh?.message}
                      variant="outlined"
                      fullWidth
                    />
                  </TableCell> */}
                  <TableCell align="right">{days_rounded}</TableCell>
                  <TableCell align="right">{country}</TableCell>
                  <TableCell align="right">{job_group}</TableCell>
                  <TableCell align="right">
                    <b> {per_diem.toLocaleString()}</b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <div className="sm:flex m-4 py-16">
            <Controller
              name="ticket_cost"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={
                    trip_data.sponsor_status === "not_sponsored" ? true : false
                  }
                  label="b) Proposed ticket/ top up cost in Ksh"
                  id="ticket_cost"
                  error={!!errors.ticket_cost}
                  helperText={errors?.ticket_cost?.message}
                  variant="standard"
                  sx={{ sm: { m: 1 }, m: 1 }}
                  fullWidth
                />
              )}
            />

            <Controller
              name="participant_cost"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={
                    trip_data.sponsor_status === "not_sponsored" ? true : false
                  }
                  label="f) Participation Fee (where applicable) in Ksh:"
                  id="participant_cost"
                  error={!!errors.participant_cost}
                  helperText={errors?.participant_cost?.message}
                  variant="standard"
                  sx={{ sm: { m: 1 }, m: 1 }}
                  fullWidth
                />
              )}
            />
          </div>

          <Button
            sx={{ mt: 6 }}
            variant="contained"
            color="success"
            disabled={loading === true ? true : false}
            // disabled={_.isEmpty(dirtyFields) || !isValid}
            onClick={() => {
              setOpenMessage(false);
              setLoadingIcon(Object.keys(errors).length > 0 ? false : true);
              setLoading(
                Object.keys(errors).length === 0
                  ? false
                  : Object.keys(errors).length > 0
                  ? false
                  : true
              );
            }}
            type="submit"
          >
            {loadingIcon === true && Object.keys(errors).length <= 0 && (
              <Loading />
            )}
            {loading === true ? <Loading /> : "Add delegate and add another?"}
          </Button>
        </div>
      )}

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 6 }}>
        <Button
          color="inherit"
          disabled
          // disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
          variant="contained"
        >
          Back
        </Button>

        <Box sx={{ flex: "1 1 auto" }} />
        <Button onClick={handleNext} variant="contained" color="secondary">
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </Box>
    </form>
  );
}
