import {
  Paper,
  Divider,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { useMediaQuery, useTheme } from "@mui/material";
import { FormHeaderDetail } from "../../forms/details/FormHeader";
import { PersonalDetails } from "../../forms/details/Personal";
import { TravelDetails } from "../../forms/details/Travel";
import { DepartureReturnDetails } from "../../forms/details/Departure_Return";
import { DelegationSizeDetails } from "../../forms/details/Delegation";
// import { ConcurrenceDetails } from '../../forms/details/Concurrence';
// import { ApprovalDetails } from '../../forms/details/Approval';
import NavLinkAdapter from "../../components/NavLinkAdapter";
import TravelSnackbar from "../../components/TravelSnackbar";
import Loading from "../../components/Loading";
// import { ConfirmationDetails } from "../../forms/details/Confirmation";
import { Private } from "../../forms/details/Private";
import { HistandStats } from "../../forms/details/HistandStats";
import { backend_url } from "../../components/Constants";
import ApprovedForm from "../../components/ApprovedForm";
import PSSummary from "./PSSummary";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PSConfirmation(props) {
  const { token, setOpen, user } = props;
  const { id } = useParams();
  const [trip, setTrip] = useState([]);
  const [value, setValue] = useState(0);

  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });
  const {
    cs_first_name,
    cs_last_name,
    trip_data,
    acting_data,
    delegate_data,
    depature_and_return_data,
    digitalsignature,
  } = trip;

  const [ps_confirm, setPSConfirm] = useState(undefined);
  const [ps_reasons, setPSReason] = useState("");

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setValue(0);
    setOpen(false);
  }, [location, setOpen]);

  useEffect(() => {
    fetch(
      `${backend_url}/traveller/approvals/?` +
        new URLSearchParams({
          trip: id,
        }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setTrip(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [token, id]);

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();

    const PSInfo = {
      trip_id: id,
      ps_confirm: ps_confirm,
      ps_reasons: ps_reasons,
      // ps_reasons: `I ${user.first_name} ${user.last_name} ${user.designation} of ${user.ministry} confirm
      //  that this form has has been reviewed. ${ps_reasons}.`,
    };

    let url = `${backend_url}/traveller/confirmation/`;
    axios
      .patch(url, PSInfo, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Confirmed Successfully!");
        setLoading(false);
        setPSReason("");
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.response.data?.details
            ? err.response.data?.details
            : err.response.data?.error
        );
        setLoading(false);
      });
  };

  return (
    <div className="relative flex flex-col flex-auto items-center px-4 sm:px-8 container mx-auto">
      <Paper
        className="w-full px-24 sm:px-32 py-32 pt-48 mt-64 mb-16"
        elevation={1}
      >
        {openMessage === true && (
          <TravelSnackbar
            openMessage={openMessage}
            severity={severity}
            message={message}
            confirmation={true}
            isMobile={isMobile}
          />
        )}

        {/* <Button
          className="font-semibold" 
          component={NavLinkAdapter}
          to="/"
          size="large"
          color="primary"
          variant="contained"
          onClick={() => (isMobile ? setOpen(false) : setOpen(true))}
          sx={{ my: 2 }}
        >
          <CloseIcon />
          <span className="mx-8">Close Application</span>
        </Button> */}

        {trip_data && trip_data.length > 0 && (
          <div>
            {trip_data[0].trip_type === "private" ? (
              <div className="flex flex-col justify-center w-full pt-16 mt-12 mb-16">
                <Button
                  className="font-semibold"
                  component={NavLinkAdapter}
                  to="/"
                  size="large"
                  onClick={() => (isMobile ? setOpen(false) : setOpen(true))}
                  color="primary"
                  variant="contained"
                >
                  <CloseIcon />
                  <span className="mx-8">Close Application</span>
                </Button>

                <div>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      textColor="secondary"
                      indicatorColor="secondary"
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      {trip_data[0].reviewer === true && (
                        <Tab
                          className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                          disableRipple
                          label="Approval Letter"
                          {...a11yProps(0)}
                        />
                      )}

                      <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                        disableRipple
                        label="Private Trip Details"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 0 + 1 : 0
                        )}
                      />
                    </Tabs>
                  </Box>
                  {trip_data[0].reviewer === true && (
                    <CustomTabPanel value={value} index={0}>
                        <ApprovedForm
                          privateTrip={true}
                          cs_first_name={cs_first_name}
                          cs_last_name={cs_last_name}
                          digitalsignature={digitalsignature}
                          trip={trip_data[0]}
                          // depRet={depature_and_return_data[0]}
                          // delegates={delegate_data}
                        />
                    </CustomTabPanel>
                  )}
                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 0 + 1 : 0}
                  >
                    <Private editForm={true}  trip={trip_data[0]} />
                  </CustomTabPanel>
                </div>

                {user && user.email !== trip_data[0].user.email && (
                  <form
                    name="PSForm"
                    noValidate
                    className="flex flex-col justify-center w-full "
                    onSubmit={handleSubmit}
                  >
                    {trip_data[0].ps_confirm !== "accepted" && (
                      <>
                        {trip_data[0].chairperson_confirm === "accepted" && (
                          <Typography
                            component="p"
                            variant="p"
                            className="text-xs font-bold py-12"
                          >
                            Chairperson comments:{" "}
                            <span className="pl-10 text-xs font-normal">
                              {trip_data[0].chairperson_reason}
                            </span>
                          </Typography>
                        )}

                        <TextField
                          label="Reasons for Concurring/Not Concurring and Return Application:"
                          id="approval"
                          variant="outlined"
                          sx={{ my: 4 }}
                          value={ps_reasons}
                          multiline
                          minRows={3}
                          fullWidth
                          onChange={(e) => setPSReason(e.target.value)}
                        />

                        <div className="flex items-center mt-24 mb-16 sm:mt-0 sm:mx-8 space-x-12">
                          <Button
                            className="whitespace-nowrap"
                            variant="contained"
                            color="error"
                            onClick={() => {
                              setPSConfirm("rejected");
                              setOpenMessage(false);
                            }}
                            startIcon={<DoDisturbIcon />}
                            type="submit"
                          >
                            {loading === true ? <Loading /> : "Not Concurred"}
                          </Button>

                          <Button
                            className="whitespace-nowrap"
                            variant="contained"
                            color="success"
                            onClick={() => {
                              setPSConfirm("accepted");
                              setOpenMessage(false);
                            }}
                            startIcon={<CheckCircleOutlineIcon />}
                            type="submit"
                          >
                            {loading === true ? <Loading /> : "Concurred"}
                          </Button>
                        </div>
                      </>
                    )}
                  </form>
                )}
              </div>
            ) : (
              <div>
                <div className="flex flex-col justify-center w-full pt-8 mt-6 mb-16">
                  <Button
                    className="font-semibold"
                    component={NavLinkAdapter}
                    to="/"
                    size="large"
                    onClick={() => (isMobile ? setOpen(false) : setOpen(true))}
                    color="primary"
                    variant="contained"
                  >
                    <CloseIcon />
                    <span className="mx-8">Close Application</span>
                  </Button>
                </div>

                <Divider />

                <div>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      textColor="secondary"
                      indicatorColor="secondary"
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      {trip_data[0].reviewer === true && (
                        <Tab
                          className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                          disableRipple
                          label="Approval Letter"
                          {...a11yProps(0)}
                        />
                      )}

                      <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                        disableRipple
                        label="Travel Summary"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 0 + 1 : 0
                        )}
                      />

                      <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                        disableRipple
                        label="Annextures and Personal Details"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 1 + 1 : 1
                        )}
                      />
                      <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                        disableRipple
                        label="Travel Details"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 2 + 1 : 2
                        )}
                      />
                      <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                        disableRipple
                        label="Departure and Return Details"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 3 + 1 : 3
                        )}
                      />
                      <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                        disableRipple
                        label="Costs and Size of Delegation"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 4 + 1 : 4
                        )}
                      />

                      <Tab
                        label="Travel History and Statistics"
                        {...a11yProps(
                          trip_data[0].reviewer === true ? 5 + 1 : 5
                        )}
                      />
                    </Tabs>
                  </Box>
                  {trip_data[0].reviewer === true && (
                    <CustomTabPanel value={value} index={0}>
                      {depature_and_return_data[0] !== undefined && (
                        <ApprovedForm
                          cs_first_name={cs_first_name}
                          cs_last_name={cs_last_name}
                          digitalsignature={digitalsignature}
                          trip={trip_data[0]}
                          depRet={depature_and_return_data[0]}
                          delegates={delegate_data}
                        />
                      )}
                    </CustomTabPanel>
                  )}
                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 0 + 1 : 0}
                  >
                    {depature_and_return_data[0] !== undefined && (
                      <PSSummary
                        user={user}
                        token={token}
                        setOpen={setOpen}
                        id={id}
                        setPSConfirm={setPSConfirm}
                        ps_confirm={ps_confirm}
                        trip={trip_data[0]}
                        depRet={depature_and_return_data[0]}
                        delegate_data={delegate_data}
                      />
                    )}
                  </CustomTabPanel>
                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 1 + 1 : 1}
                  >
                    <FormHeaderDetail
                      trip={trip_data[0]}
                      ps_confirm={ps_confirm}
                    />
                    <PersonalDetails
                      trip={trip_data[0]}
                      actingOfficer={acting_data[0]}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 2 + 1 : 2}
                  >
                    <TravelDetails trip={trip_data[0]} />
                  </CustomTabPanel>
                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 3 + 1 : 3}
                  >
                    {depature_and_return_data[0] !== undefined && (
                      <DepartureReturnDetails
                        trip={trip_data[0]}
                        depRet={depature_and_return_data[0]}
                        token={token}
                      />
                    )}
                  </CustomTabPanel>
                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 4 + 1 : 4}
                  >
                    {depature_and_return_data[0] !== undefined && (
                      <DelegationSizeDetails
                        trip={trip_data[0]}
                        delegates={delegate_data}
                        depRet={depature_and_return_data[0]}
                      />
                    )}
                  </CustomTabPanel>
                  {/* <CustomTabPanel value={value} index={4}>
                    <ConfirmationDetails
                      trip={trip_data[0]}
                      digitalsignature={digitalsignature}
                    />
                  </CustomTabPanel> */}
                  <CustomTabPanel
                    value={value}
                    index={trip_data[0].reviewer === true ? 5 + 1 : 5}
                  >
                    <HistandStats
                      token={token}
                      user={user}
                      trip={trip_data[0]}
                      digitalsignature={digitalsignature}
                    />
                  </CustomTabPanel>
                </div>

                {/* <ConfirmationDetails trip={trip} />

  <Divider sx={{ py: 2, my: 2 }} />

  <ConcurrenceDetails trip={trip} />

  <Divider sx={{ py: 2, my: 2 }} />

  <ApprovalDetails trip={trip} /> */}
              </div>
            )}
          </div>
        )}

        <Divider sx={{ my: 10 }} />
      </Paper>
    </div>
  );
}
