import { Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import format from "date-fns/format";

export const TravelDetails = (props) => {
  const { trip } = props;

  return (
    <div>
      <div className="py-14">
        <Typography
          component="h5"
          variant="p"
          className="text-md sm:text-lg font-semibold"
        >
          TRAVEL DETAILS
        </Typography>
      </div>

      {trip && (
        <div>
          <Typography
            component="p"
            variant="p"
            className="text-xs font-bold font-small py-8"
          >
            Reason(s) for travel:
          </Typography>

          <Typography
            component="p"
            variant="p"
            className="pl-4 py-4 sm:pl-14 sm:py-8"
          >
            <div dangerouslySetInnerHTML={{ __html: trip.reason_for_tr }} />
            {/* {trip.reason_for_tr} */}
          </Typography>

          <Divider className="py-2 my-2" />

          <Typography
            component="p"
            variant="p"
            className="text-xs font-bold font-small py-8"
          >
            Country:
          </Typography>

          <Typography
            component="p"
            variant="p"
            className="pl-4 py-4 sm:pl-14 sm:py-8"
          >
            {trip.country}
          </Typography>
          <Divider className="py-2 my-2" />

          <Typography
            component="p"
            variant="p"
            className="text-xs font-bold font-small py-8"
          >
            If attending Conference/ Seminar/ Workshop, meeting/ benchmarking/
            assignment/ formal government to government engagement etc. Please
            indicate its title-
          </Typography>

          <Typography
            component="p"
            variant="p"
            className="text-xs font-bold font-small py-8"
          >
            Program Type
          </Typography>

          <Typography
            component="p"
            variant="p"
            className="pl-4 py-4 sm:pl-14 sm:py-8"
          >
            {trip.program_type}
          </Typography>

          {trip.program_other && (
            <Typography
              component="p"
              variant="p"
              className="pl-4 py-4 sm:pl-14 sm:py-8"
            >
              {trip.program_other}
            </Typography>
          )}

          <Typography
            component="p"
            variant="p"
            className="text-xs font-bold font-small py-8"
          >
            Program Title
          </Typography>

          <Typography
            component="p"
            variant="p"
            className="pl-4 py-4 sm:pl-14 sm:py-8"
          >
            {trip.program_title}
          </Typography>
          <Divider className="py-2 my-2" />

          <Typography
            component="p"
            variant="p"
            className="text-xs font-bold font-small py-16"
          >
            From:
            <span className="px-10 ">
              {" "}
              {format(new Date(trip.program_from), "dd-MMM-yyyy")}
            </span>
            To:
            <span className="px-10 text-sm">
              {" "}
              {format(new Date(trip.program_to), "dd-MMM-yyyy")}
            </span>
            <br />
          </Typography>
          <Divider className="py-2 my-2" />

          <Typography
            component="p"
            variant="p"
            className="text-xs font-bold font-small py-8"
          >
            Please indicate if you have attended a similar or related conference
            on the same subject matter in the last five (5) years-
          </Typography>

          {trip.program_similar === "" ? (
            <Typography
              component="p"
              variant="p"
              className="pl-4 py-4 sm:pl-14 sm:py-8"
            >
              No
            </Typography>
          ) : (
            <div>
              <Typography
                component="p"
                variant="p"
                className="pl-4 py-4 sm:pl-14 sm:py-8"
              >
                <div
                  dangerouslySetInnerHTML={{ __html: trip.program_similar }}
                />
              </Typography>

              <Divider className="py-2 my-2" />

              <Typography
                component="p"
                variant="p"
                className="text-xs font-bold font-small py-16"
              >
                From:
                <span className="px-10 ">
                  {format(new Date(trip.prog_sm_from), "dd-MMM-yyyy")}
                </span>
                To:
                <span className="px-10 text-sm">
                  {format(new Date(trip.prog_sm_to), "dd-MMM-yyyy")}
                </span>
                <br />
              </Typography>
            </div>
          )}

          <Divider className="py-2 my-2" />

          {trip.meeting_up && (
            <>
              <Typography
                component="p"
                variant="p"
                className="text-xs font-bold font-small py-8"
              >
                If attending a meeting/ benchmarking/ assignment/ formal
                government to government engagement etc Please indicate schedule
                of activities:
              </Typography>

              <Typography
                component="p"
                variant="p"
                className="text-xs font-bold py-8"
              >
                Check attachments at the first tab
              </Typography>

              <Typography
                component="p"
                variant="body2"
                className="text-xs font-small py-8"
              >
                (Annex programme. For benchmark visits, annex copy of Gazette
                Notice of Taskforce/Working Committee to which the travel is
                related)
              </Typography>
              <Divider className="py-2 my-2" />
            </>
          )}

          <Typography
            component="p"
            variant="p"
            className="text-xs font-bold font-small py-8"
          >
            Person(s)/ Organization/ Institution hosting the Meeting/
            Engagement/ Conference/ Seminar/ Workshop, etc.
          </Typography>

          <Typography
            component="p"
            variant="p"
            className="pl-4 py-4 sm:pl-14 sm:py-8"
          >
            {trip.host}
          </Typography>

          <Typography
            component="p"
            variant="p"
            className="text-xs font-bold font-small py-8"
          >
            Host Address:
          </Typography>

          <Typography
            component="p"
            variant="p"
            className="pl-4 py-4 sm:pl-14 sm:py-8"
          >
            {trip.host_country}, <br /> {trip.host_city},<br />{" "}
            {trip.host_zip_postal_code}
          </Typography>

          <Divider className="py-2 my-2" />

          <Typography
            component="p"
            variant="p"
            className="text-xs font-bold font-small py-8"
          >
            Sponsor(s) of the Meeting/ Engagement/ Conference/ Seminar/ Workshop
            Etc.
          </Typography>

          {trip.sponsor_status === "not_sponsored" && (
            <Typography
              component="p"
              variant="p"
              className="pl-4 py-4 sm:pl-14 sm:py-8"
            >
              None
            </Typography>
          )}

          {trip.sponsor_status !== "not_sponsored" && (
            <Typography
              component="p"
              variant="p"
              className="pl-4 py-4 sm:pl-14 sm:py-8"
            >
              {trip.sponsor}
            </Typography>
          )}
          <Divider className="py-2 my-2" />

          <Typography
            component="p"
            variant="p"
            className="text-xs font-bold font-small py-8"
          >
            Objective(s) of the Meeting/ Conference/ Seminar/ Workshop, etc:
          </Typography>

          <Typography
            component="p"
            variant="p"
            className="pl-4 py-4 sm:pl-14 sm:py-8"
          >
            <div dangerouslySetInnerHTML={{ __html: trip.object_meeting }} />
          </Typography>

          <Divider className="py-2 my-2" />

          <Typography
            component="p"
            variant="p"
            className="text-xs font-bold font-small py-8"
          >
            Expected activity/ event/ intervention to be executed upon return in
            the Ministry/ Department/ Corporation of the participant derived
            from the Meeting/ Engagement/ Conference/ Seminar/ Workshop, etc.
          </Typography>

          <Typography
            component="p"
            variant="p"
            className="pl-4 py-4 sm:pl-14 sm:py-8"
          >
            <div dangerouslySetInnerHTML={{ __html: trip.exp_activity }} />
          </Typography>

          <Divider className="py-2 my-2" />

          <Typography
            component="p"
            variant="p"
            className="text-xs font-bold font-small py-8"
          >
            Where the reason for travel is a conference, workshop, academic
            meeting, confirm that the option of virtual participation is
            unavailable, and that physical attendance is mandatory.
          </Typography>

          <Typography
            component="p"
            variant="p"
            className="pl-4 py-4 sm:pl-14 sm:py-8"
          >
            {trip.virtuality === false
              ? "Virtual participation is unavailable"
              : "Virtual participation is available"}
          </Typography>

          {trip.virtuality === true && (
            <div>
              <Typography
                component="p"
                variant="p"
                className="text-xs font-bold font-small py-8"
              >
                Reason
              </Typography>

              <Typography
                component="p"
                variant="p"
                className="pl-4 py-4 sm:pl-14 sm:py-8"
              >
                {trip.virtuality_reason}
              </Typography>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
