import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import * as yup from "yup";
// import _ from "../../@lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import TravelSnackbar from "../../components/TravelSnackbar";
import Loading from "../../components/Loading";
import kenya_flag from "../../assets/kenya_flag.png";
import { backend_url } from "../../components/Constants";

export default function Part2A(props) {
  const { token } = props;
  const [trip, setTrip] = useState([]);
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingIcon, setLoadingIcon] = useState(false);
  const [newdesignation, setNewdesignation] = useState(false);

  const schema = yup.object().shape({
    full_name: yup.string().required("You must enter full name"),
    personal_no: yup.number().required("You must enter personal number"),
    id_number: yup
      .number()
      .required("You must enter your ID number")
      .min(4, "Number is too short - should be 4 chars minimum")
      .nullable(true),
    designation: yup.string().required("Please type the designation"),
    designation_new:
      newdesignation === false
        ? yup.string().notRequired("Please type in the designation")
        : yup.string().required("Please type in the designation"),
    email_address: yup
      .string()
      .email("The email should end with .ke")
      .required("You must enter a valid email"),
    telephone_no: yup
      .string()
      .required("You must enter your telephone number")
      .min(4, "Number is too short - should be 4 chars minimum")
      .nullable(true),
  });

  const defaultValues = {
    full_name: "",
    personal_no: "",
    id_number: "",
    designation: "",
    email_address: "",
    telephone_no: "",
    designation_new: "",
  };

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    //  isValid, dirtyFields,
    errors,
  } = formState;

  useEffect(() => {
    fetch(`${backend_url}/traveller/details/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTrip(data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  function submit({
    full_name,
    personal_no,
    id_number,
    designation,
    email_address,
    telephone_no,
    // job_group,
    designation_new,
  }) {
    const actingOfficer = {
      full_name,
      personal_no,
      id_number,
      designation,
      ministry: trip[0].user.ministry,
      email_address,
      telephone_no,
      // job_group,
      designation_new,
      trip: trip[0].trip_id,
      user: trip[0].user.user_id,
      job_group: "Other",
      // role: "Please remove me!",
    };
    axios
      .put(
        `${backend_url}/traveller/actingofficer/`,
        actingOfficer,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Acting Officer Created Successfully!");
        setLoading(false);
        setLoadingIcon(false);
        window.location.reload();
        // handleNext();
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(err.code === "ERR_NETWORK" && "Network error, please try again later.");
        setMessage(err.response.data?.details);
        setLoadingIcon(false);
        setLoading(false);
      });
  }

  return (
    <div>
      <form
        name="actingOfficer"
        noValidate
        className="flex flex-col justify-center w-full mt-8 sm:mt-32"
        onSubmit={handleSubmit(submit)}
      >
        {openMessage === true && (
          <TravelSnackbar
            openMessage={openMessage}
            severity={severity}
            message={message}
          />
        )}
        <Typography
          component="p"
          variant="p"
          className="p-12 text-xs font-semibold"
        >
          Acting Officer (To exercise delegated authority when applicant is
          away)
        </Typography>

        <div className="sm:flex m-4">
          <Controller
            name="full_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ m: 1 }}
                label="Full Name:"
                id="full_name"
                type="text"
                error={!!errors.full_name}
                helperText={errors?.full_name?.message}
                variant="standard"
                required
                fullWidth
              />
            )}
          />

          <Controller
            name="personal_no"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ m: 1 }}
                label="Personal/Employment number"
                id="personal_no"
                type="text"
                error={!!errors.personal_no}
                helperText={errors?.personal_no?.message}
                variant="standard"
                required
                fullWidth
              />
            )}
          />
        </div>

        <div className="sm:flex m-4">
          <div className="flex w-full">
            <Controller
              name="designation"
              control={control}
              render={({ field }) => (
                <FormControl
                  sx={{ m: 1 }}
                  fullWidth
                  error={!!errors.designation}
                >
                  <InputLabel id="designation1">Designation</InputLabel>
                  <Select
                    labelId="designation1"
                    id="designation"
                    label="Designation"
                    variant="standard"
                    {...field}
                  >
                    {trip[0] !== undefined &&
                      trip[0].user.designation === "CHAIRPERSON" && (
                        <MenuItem
                          value={"CEO"}
                          onClick={() => setNewdesignation(false)}
                        >
                          CEO
                        </MenuItem>
                      )}

                    {trip[0] !== undefined &&
                      trip[0].user.designation === "CEO" && (
                        <MenuItem
                          value={"Other"}
                          onClick={() => setNewdesignation(true)}
                        >
                          Other
                        </MenuItem>
                      )}

                    {trip[0] !== undefined &&
                      trip[0].user.designation === "PS" && (
                        <MenuItem
                          value={"Other"}
                          onClick={() => setNewdesignation(true)}
                        >
                          Other
                        </MenuItem>
                      )}

                    {trip[0] !== undefined &&
                      trip[0].user.designation === "PS" && (
                        <MenuItem
                          value={"CHAIRPERSON"}
                          onClick={() => setNewdesignation(false)}
                        >
                          Chairperson
                        </MenuItem>
                      )}

                    {trip[0] !== undefined &&
                      trip[0].user.designation === "CS" && (
                        <MenuItem
                          value={"PS"}
                          onClick={() => setNewdesignation(false)}
                        >
                          PS
                        </MenuItem>
                      )}
                  </Select>
                  <FormHelperText>
                    {errors?.designation?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />

            <br />

            {newdesignation === true && (
              <Controller
                name="designation_new"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ m: 1 }}
                    label="Designation Name:"
                    id="designation_new"
                    type="text"
                    error={!!errors.designation_new}
                    helperText={errors?.designation_new?.message}
                    variant="standard"
                    required
                    fullWidth
                  />
                )}
              />
            )}
          </div>

          {trip && trip[0] !== undefined && (
            <TextField
              label="Ministry"
              id="ministry"
              variant="filled"
              sx={{ p: 1 }}
              InputProps={{
                readOnly: true,
              }}
              defaultValue={trip[0].user.ministry}
              fullWidth
            />
          )}
        </div>

        <div className="sm:flex m-4">
          <Controller
            name="email_address"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ m: 1 }}
                label="Email"
                id="email"
                type="email"
                error={!!errors.email_address}
                helperText={errors?.email_address?.message}
                variant="standard"
                required
                fullWidth
              />
            )}
          />

          <Controller
            name="telephone_no"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ m: 1 }}
                label="Telephone number"
                variant="standard"
                error={!!errors.telephone_no}
                helperText={
                  <Typography variant="caption" color="error">
                    {" "}
                    {errors?.telephone_no?.message}{" "}
                  </Typography>
                }
                id="telephone_no"
                required
                fullWidth
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box
                        component="span"
                        sx={{ "& > img": { mr: 0, flexShrink: 0 } }}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={kenya_flag}
                          alt="kenya flag"
                        />
                        <span className="ml-4 font-medium">+254</span>
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>

        <div className="sm:flex m-4">
          <Controller
            name="id_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ m: 1 }}
                label="ID number"
                type="number"
                error={!!errors.id_number}
                helperText={errors?.id_number?.message}
                variant="standard"
                required
                fullWidth
              />
            )}
          />
        </div>

        <Button
          sx={{ mt: 8 }}
          variant="contained"
          color="success"
          disabled={loading === true ? true : false}
          // disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={() => {
            setOpenMessage(false);
            setLoadingIcon(
              Object.keys(errors).length > 0
              ? false
              : true
          );
            setLoading(
              Object.keys(errors).length === 0
                ? false
                : Object.keys(errors).length > 0
                ? false
                : true
            );
          }}
          type="submit"
        >
          {loadingIcon === true && Object.keys(errors).length <= 0 && <Loading />}
          {loading === true ? (
            <Loading />
          ) : Object.keys(errors).length > 0 ? (
            "Complete All Fields"
          ) : (
            "Next"
          )}
        </Button>
        {/* 
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt: 6 }}>
                <Button
                    color="inherit"
                    disabled
                    // disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    variant='contained'
                >
                    Back
                </Button>

                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleNext} variant='contained' color='secondary'>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
            </Box> */}
      </form>
    </div>
  );
}
