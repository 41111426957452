import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import DashLayout from '../../components/Layout/DashLayout';
import MFAHome from './MFAHome';
import MFAHeader from "./MFAHeader";
// import CSForms from "./CSForms";
import MFASidebarContent from "./MFASidebarContent";
import MFAApplications from './MFAApplications';
import { useMediaQuery, useTheme } from '@mui/material';

const Root = styled(DashLayout)(({ theme }) => ({
    '& .DashLayout-header': {
        backgroundColor: theme.palette.background.paper,
        boxShadow: `inset 0 0 0 1px  ${theme.palette.divider}`,
    },
}));

function MFADash(props) {
    const { token, user } = props;
    const [tabValue, setTabValue] = useState(0);

    const pageLayout = useRef(null);
    const routeParams = useParams();
    const [rightSidebarOpen, setRightSidebarOpen] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
        noSsr: true
    });

    useEffect(() => {
        setRightSidebarOpen(Boolean(routeParams.id));
    }, [routeParams]);

    function handleChangeTab(event, value) {
        setTabValue(value);
    }

    return (
        <Root
            ref={pageLayout}
            header={
                <MFAHeader pageLayout={pageLayout} token={token} user={user} />}
            content={
                <div className="w-full p-2 py-4 sm:py-8 lg:ltr:pr-0 lg:rtl:pl-0">
                    <Tabs
                        value={tabValue}
                        onChange={handleChangeTab}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="scrollable"
                        scrollButtons={false}
                        className="w-full px-24 -mx-4 min-h-40"
                        classes={{ indicator: 'flex justify-center bg-transparent w-full h-full' }}
                        TabIndicatorProps={{
                            children: (
                                <Box
                                    sx={{ bgcolor: 'text.disabled' }}
                                    className="w-full h-full rounded-full opacity-20"
                                />
                            ),
                        }}
                    >
                        <Tab
                            className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                            disableRipple
                            label="Travel Applications"
                        />
                        {/* <Tab
                            className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                            disableRipple
                            label="Confirm Travel Forms"
                        /> */}
                    </Tabs>
                    {tabValue === 0 && <MFAApplications token={token} />}
                    {/* {tabValue === 2 && <CSForms token={token} user={user} />} */}
                </div>
            }
            rightSidebarContent={<MFASidebarContent />}
            rightSidebarOpen={rightSidebarOpen}
            rightSidebarOnClose={() => setRightSidebarOpen(false)}
            rightSidebarWidth={isMobile ? 760 : window.innerWidth / 1}
            scroll={isMobile ? 'normal' : 'content'}
        />
    );
}

export default (MFADash);
