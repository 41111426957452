import { useEffect } from "react";
import { motion } from "framer-motion";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { backend_url } from "../../components/Constants";

function HECSHistory(props) {
  const { token, setCSHistory, csHistory, trip_user } = props;

  const user_id = trip_user;

  useEffect(() => {
    fetch(
      `${backend_url}/traveller/user_history/?` +
        new URLSearchParams({
          user_id,
        }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCSHistory(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [token, user_id, setCSHistory]);

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
      className="flex flex-col flex-auto w-full max-h-full my-8"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="py-1 sm:py-3 sm:my-3">
          {csHistory && csHistory.count > 0 && (
            <div>
              {" "}
              <Typography paragraph variant="body2" sx={{ fontWeight: "bold" }}>
                Total Travelled Trips: {csHistory.results[0].accepted}
              </Typography>
              {csHistory.results[0].rejected > 1 && (
                <Typography
                  paragraph
                  variant="body2"
                  sx={{ fontWeight: "bold" }}
                >
                  Rejected Trips: {csHistory.results[0].rejected}
                </Typography>
              )}
              {/* <Typography
                paragraph
                variant="body2"
                // color="text.secondary"
              >
                {trip.trip_data.chief_reason}
              </Typography> */}
            </div>
          )}
        </div>
      </Box>
    </motion.div>
  );
}

export default HECSHistory;
