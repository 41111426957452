import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import logo from "../assets/logo1.png";

function Confirmation() {
    return (
        <Container component="main" maxWidth="xl">
            <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-32 mt-56">
                <Paper className="flex w-full sm:w-auto min-h-full sm:min-h-auto md:w-full md:max-w-6xl rounded-0 sm:rounded-2xl sm:shadow overflow-hidden">
                    <div className="w-full sm:w-auto py-32 px-16 sm:p-48 md:p-64 ltr:border-r-1 rtl:border-l-1">
                        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
                            <img className="w-full sm:w-70" src={logo} alt="logo" />

                            <Typography variant='p' component='p' className="flex flex-col pt-16 sm:mt-32 text-xl sm:text-2xl font-bold sm:font-extrabold tracking-tight leading-tight">
                                Wait for approval.
                            </Typography>

                            <div className="sm:flex items-baseline pt-8 text-xs sm:text-sm font-small sm:font-medium">
                                Your account has been created successfully. Please wait for approval.
                                A confirmation mail with instructions will be sent to your email address after approval.
                                {/* Follow those instructions to confirm your email address and activate your account. */}

                            </div>

                            <Typography variant='p' component='p' className="pt-16 sm:pt-32 text-xs font-medium" color="text.secondary">
                                <span>Return to</span>
                                <Link className="ml-4 font-bold text-primary-500 hover:underline" to="/sign-in">
                                    Sign In
                                </Link>
                            </Typography>
                        </div>
                    </div>

                    <Box
                        className="relative hidden lg:flex flex-auto items-center justify-center min-h-full p-64 lg:px-112 overflow-hidden"
                        sx={{ backgroundColor: 'primary.dark' }}
                    >
                        <svg
                            className="absolute inset-0 pointer-events-none"
                            viewBox="0 0 960 540"
                            width="100%"
                            height="100%"
                            preserveAspectRatio="xMidYMax slice"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <Box
                                component="g"
                                sx={{ color: 'primary.light' }}
                                className="opacity-20"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="100"
                            >
                                <circle r="234" cx="196" cy="23" />
                                <circle r="234" cx="790" cy="491" />
                            </Box>
                        </svg>
                        <Box
                            component="svg"
                            className="absolute -top-64 -right-64 opacity-20"
                            sx={{ color: 'primary.light' }}
                            viewBox="0 0 220 192"
                            width="220px"
                            height="192px"
                            fill="none"
                        >
                            <defs>
                                <pattern
                                    id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                                    x="0"
                                    y="0"
                                    width="20"
                                    height="20"
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x="0" y="0" width="4" height="4" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
                        </Box>

                        <div className="z-10 relative w-full max-w-2xl">
                            <div
                                className="text-7xl font-bold leading-none text-white text-center">
                                <div>Travel</div>
                                <div>Clearance</div>
                                <div>System</div>
                            </div>
                        </div>
                    </Box>
                </Paper>
            </div>
        </Container>
    );
}

export default Confirmation;
