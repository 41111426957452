import { ThemeProvider, useTheme } from '@mui/material/styles';
import clsx from 'clsx';
// import DashScrollbars from './DashScrollbars';

function DashLayoutSidebarContent(props) {
  const theme = useTheme();

  return (
    // <DashScrollbars enable={props.innerScroll}>
    <div>
      {props.header && (
        <ThemeProvider theme={theme}>
          <div className={clsx('DashLayout-sidebarHeader', props.variant)}>{props.header}</div>
        </ThemeProvider> 
      )}

      {props.content && <div className="DashLayout-sidebarContent">{props.content}</div>}
    </div>
  );
}

export default DashLayoutSidebarContent;
