import { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "../@lodash";
import { lighten } from "@mui/material/styles";
import TravelSnackbar from "./TravelSnackbar";
import Loading from "./Loading";
import { backend_url } from "./Constants";

const schema = yup.object().shape({
  ticket_up: yup.mixed().required(),
  passport_up: yup.mixed().required(),
  report_up: yup.mixed().required(),
  whats_achieved: yup.string().notRequired(),
  accomo_cost: yup.number().required(),
  ticket_cost: yup.number().required(),
  // sponsor_cost: yup.number().required(),
  // gok_cost: yup.number().required(),
  participant_cost: yup.number().required(),
});

const defaultValues = {
  ticket_up: null,
  passport_up: null,
  report_up: null,
  whats_achieved: null,
  accomo_cost: null,
  ticket_cost: null,
  // sponsor_cost: null,
  // gok_cost: null,
  participant_cost: null,
};

export default function Uploads(props) {
  const { token } = props;
  const [trips, setTrips] = useState([]);
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const publicTrips =
    trips !== undefined && trips.filter((item) => item.trip_type === "public");


  const publicTrips1 =
    publicTrips !== undefined &&
    publicTrips.filter((item) => item.active_trip === true);

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    fetch(`${backend_url}/traveller/details/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTrips(data.results);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [token]);

  function submit({
    ticket_up,
    passport_up,
    report_up,
    whats_achieved,
    accomo_cost,
    ticket_cost,
    // sponsor_cost,
    // gok_cost,
    participant_cost,
  }) {
    const uploadInfo = {
      ticket_up,
      passport_up,
      report_up,
      whats_achieved,
      accomo_cost,
      ticket_cost,
      // sponsor_cost,
      // gok_cost,
      participant_cost,
      trip: publicTrips1[0].trip_id,
    };
    axios
      .put(
        `${backend_url}/traveller/after_trip/`,
        uploadInfo,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Uploaded Successfully.");
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(err.code === "ERR_NETWORK" && "Network error, please try again later.");
        setMessage(err.response.data?.details);
        setLoading(false);
      });
  }

  return (
    <div className="py-4 w-full max-w-3xl">
      <Typography
        component="h5"
        variant="p"
        className="px-4 py-16 text-lg font-bold"
      >
        Please Upload your documents from your last trip
      </Typography>

      <form
        name="uploadInfo"
        noValidate
        className="flex flex-col justify-center w-full mt-32"
        onSubmit={handleSubmit(submit)}
      >
        {openMessage === true && (
          <TravelSnackbar
            openMessage={openMessage}
            severity={severity}
            message={message}
            uploads={true}
          />
        )}

        <div className="sm:flex -mx-4">
          <Controller
            control={control}
            name="ticket_up"
            render={({ field: { onChange, value } }) => (
              <Box
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? lighten(theme.palette.background.default, 1)
                      : lighten(theme.palette.background.default, 0.02),
                }}
                component="label"
                htmlFor="button_ticket_up"
                className="flex items-center justify-center relative w-82 h-64 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
              >
                <input
                  accept=".jpeg, .jpg, .png, .pdf,"
                  className="hidden"
                  id="button_ticket_up"
                  type="file"
                  onChange={(e) => onChange(e.target.files[0])}
                />
                <IconButton>
                  <FileUploadIcon sx={{ color: "action" }} />
                </IconButton>

                <Typography
                  variant="p"
                  component="p"
                  className="text-xs font-small pl-8 pr-16"
                >
                  {value === null ? (
                    <span> Actual ticket costs</span>
                  ) : (
                    value.name
                  )}
                </Typography>
              </Box>
            )}
          />

          <Controller
            control={control}
            name="passport_up"
            render={({ field: { onChange, value } }) => (
              <Box
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? lighten(theme.palette.background.default, 1)
                      : lighten(theme.palette.background.default, 0.02),
                }}
                component="label"
                htmlFor="button_passport_up"
                className="flex items-center justify-center relative w-82 h-64 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
              >
                <input
                  accept=".jpeg, .jpg, .png, .pdf,"
                  className="hidden"
                  id="button_passport_up"
                  type="file"
                  onChange={(e) => onChange(e.target.files[0])}
                />
                <IconButton>
                  <FileUploadIcon sx={{ color: "action" }} />
                </IconButton>

                <Typography
                  variant="p"
                  component="p"
                  className="text-xs font-small pl-8 pr-16"
                >
                  {value === null ? <span> Passport Upload</span> : value.name}
                </Typography>
              </Box>
            )}
          />

          <Controller
            control={control}
            name="report_up"
            render={({ field: { onChange, value } }) => (
              <Box
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? lighten(theme.palette.background.default, 1)
                      : lighten(theme.palette.background.default, 0.02),
                }}
                component="label"
                htmlFor="button_report_up"
                className="flex items-center justify-center relative  w-82 h-64  rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
              >
                <input
                  accept=".jpeg, .jpg, .png, .pdf,"
                  className="hidden"
                  id="button_report_up"
                  type="file"
                  onChange={(e) => onChange(e.target.files[0])}
                />
                <IconButton>
                  <FileUploadIcon sx={{ color: "action" }} />
                </IconButton>

                <Typography
                  variant="p"
                  component="p"
                  className="text-xs font-small pl-8 pr-16"
                >
                  {value === null ? (
                    <span> A detailed report Upload</span>
                  ) : (
                    value.name
                  )}
                </Typography>
              </Box>
            )}
          />
        </div>

        {/* <Tooltip title="What was achieved">
          <Controller
            name="whats_achieved"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="What was achieved/ learned /  during the trip, what impact will it have?"
                id="whats_achieved"
                variant="outlined"
                error={!!errors.whats_achieved}
                helperText={errors?.whats_achieved?.message}
                sx={{ m: 1 }}
                fullWidth
                multiline
                minRows={3}
              />
            )}
          />
        </Tooltip> */}

        <div className="sm:flex m-4">
          <Controller
            name="accomo_cost"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Actual accommodation and subsistence costs"
                id="accomo_cost"
                error={!!errors.accomo_cost}
                helperText={errors?.accomo_cost?.message}
                variant="standard"
                sx={{ p: 1 }}
                fullWidth
              />
            )}
          />

          <Controller
            name="ticket_cost"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Actual ticket costs"
                id="applicant_role"
                error={!!errors.ticket_cost}
                helperText={errors?.ticket_cost?.message}
                variant="standard"
                sx={{ p: 1 }}
                fullWidth
              />
            )}
          />
        </div>

        {/* <div className="sm:flex m-4">
          <Controller
            name="sponsor_cost"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="d) Sponsor contribution in Ksh:"
                id="sponsor_cost"
                error={!!errors.sponsor_cost}
                helperText={errors?.sponsor_cost?.message}
                variant="standard"
                sx={{ p: 1 }}
                fullWidth
              />
            )}
          />

          <Controller
            name="gok_cost"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="e) GoK contribution: Ksh:"
                id="gok_cost"
                error={!!errors.gok_cost}
                helperText={errors?.gok_cost?.message}
                variant="standard"
                sx={{ p: 1 }}
                fullWidth
              />
            )}
          />
        </div> */}

        <div className="sm:flex m-4">
          <Controller
            name="participant_cost"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Participation fee"
                id="participant_cost"
                error={!!errors.participant_cost}
                helperText={errors?.participant_cost?.message}
                variant="standard"
                sx={{ p: 1 }}
                fullWidth
              />
            )}
          />
        </div>

        <Button
          sx={{ mt: 8 }}
          variant="contained"
          color="success"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={() => {
            setOpenMessage(false);
            setLoading(Object.keys(errors).length > 0 ? false : true);
          }}
          type="submit"
        >
          {loading === true ? <Loading /> : Object.keys(errors).length > 0 ? "Complete All Fields" : Object.keys(errors).length > 0 ? (setLoading(false)) : "Submit"}
        </Button>
      </form>
    </div>
  );
}
