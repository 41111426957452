import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Button, Container, Divider, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import Part1 from "./Part1";
// import Part2 from './new/Part2';
import Part3 from "./Part3";
import Part4 from "./Part4";
// import Part5 from './new/Part5';
import Part2A from "./Part2A";
import { backend_url } from "../../components/Constants";
import Appeal from "./Appeal";

export default function EditForm(props) {
  const { token, user, setOpen, reviewer } = props;
  const { id } = useParams();
  const [trip, setTrip] = useState([]);
  const [edit, setEdit] = useState(false);
  const [edit1, setEdit1] = useState(false);
  const [appeal_open, setAppealOpen] = useState(false);

  const handleClose = () => {
    setAppealOpen(false);
  };

  const { trip_data, acting_data, delegate_data, depature_and_return_data } =
    trip;

  const preview = window.location.hash === "#preview" ? true : false;

  useEffect(() => {
    fetch(
      `${backend_url}/traveller/approvals/?` +
        new URLSearchParams({
          trip: id,
        }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setTrip(data);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, id, setOpen]);

  return (
    trip &&
    trip_data && (
      // (reviewer === true) &&
      <div className="pb-64">
        <Paper elevation={0}>
          <Container component="main" maxWidth="lg">
            <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 pt-16 sm:pt-32 sm:pb-32">
              <div className="flex flex-auto items-center min-w-0">
                <div className="flex flex-col min-w-0 px-2 sm:px-16 pt-32 sm:pb-16 pb-4">
                  <Typography
                    variant="p"
                    component="h5"
                    className="text-md md:text-lg font-semibold  "
                  >
                    TRAVEL CLEARANCE FORM FOR CABINET SECRETARIES, CHIEF
                    ADMINISTRATIVE SECRETARIES, PRINCIPAL SECRETARIES,
                    CHAIRPERSONS AND CHIEF EXECUTIVES OF STATE CORPORATIONS
                  </Typography>
                </div>
              </div>
            </div>
          </Container>
        </Paper>

        <Container component="main" maxWidth="lg">
          <Paper className="sm:px-16 px-4" elevation={0}>
            {window.location.hash === "#preview" ? (
              <Typography
                component="p"
                variant="p"
                className="text-md md:text-lg font-semibold  sm:pt-16 pt-8"
              >
                Preview Application - You will be able to edit your application
                after all steps are complete
              </Typography>
            ) : reviewer === true ? (
              <div>
                <Typography
                  component="p"
                  variant="p"
                  className="text-md  font-semibold py-8"
                >
                  {`HOPS Reviewer Comments:`}
                </Typography>
                <div
                  dangerouslySetInnerHTML={{
                    __html: trip_data[0].hops_checking_reasons,
                  }}
                />
                <Divider />

                <Divider />
                <Typography
                  component="p"
                  variant="p"
                  className="text-md font-semibold py-16"
                >
                  {`HOPS Comments:`}
                </Typography>
                <div
                  dangerouslySetInnerHTML={{
                    __html: trip_data[0].chief_reason,
                  }}
                />
              </div>
            ) : trip_data[0].chairperson_reason !== null ||
              trip_data[0].ps_reasons !== null ||
              trip_data[0].cs_reasons !== null ||
              trip_data[0].chief_reason !== null ? (
              <div>
                <Typography
                  component="p"
                  variant="p"
                  className="text-md md:text-lg font-semibold sm:py-16 py-8 text-red-500"
                >
                  Application returned
                </Typography>
                <div className="sm:flex pb-16 justify-between items-center">
                  <Typography
                    component="p"
                    variant="p"
                    className="text-md md:text-lg font-semibold py-4"
                  >
                    Re-edit application or
                  </Typography>

                  <div className="py-2">
                    <Button
                      onClick={() => setAppealOpen(true)}
                      size="large"
                      variant="contained"
                      color="secondary"
                    >
                      Make an appeal
                    </Button>
                  </div>
                </div>

                {appeal_open === true && (
                  <Appeal
                    setAppealOpen={setAppealOpen}
                    appeal_open={appeal_open}
                    handleClose={handleClose}
                    token={token}
                    id={id}
                    trip={trip_data[0]}
                  />
                )}

                {trip_data[0].chairperson_reason !== null && (
                  <Typography
                    component="p"
                    variant="p"
                    className="text-xs font-bold py-6"
                  >
                    Chairperson comments:{" "}
                    <span className="pl-10 text-xs font-normal">
                      {trip_data[0].chairperson_reason}
                    </span>
                  </Typography>
                )}
                <Divider />
                {trip_data[0].ps_reasons !== null && (
                  <Typography
                    component="p"
                    variant="p"
                    className="text-xs font-bold py-6"
                  >
                    PS comments:{" "}
                    <span className="pl-10 text-xs font-normal">
                      {trip_data[0].ps_reasons}
                    </span>
                  </Typography>
                )}
                <Divider />

                {trip_data[0].cs_reasons !== null && (
                  <Typography
                    component="p"
                    variant="p"
                    className="text-xs font-bold py-6"
                  >
                    CS comments:{" "}
                    <span className="pl-10 text-xs font-normal">
                      {trip_data[0].cs_reasons}
                    </span>
                  </Typography>
                )}
                <Divider />

                {trip_data[0].chief_reason !== null && (
                  <Typography
                    component="p"
                    variant="p"
                    className="text-xs font-bold py-6"
                  >
                    HOPS comments:{" "}
                    <span className="pl-10 text-xs font-normal">
                      {trip_data[0].chief_reason}
                    </span>
                  </Typography>
                )}
                <Divider />
              </div>
            ) : (
              <Typography
                component="p"
                variant="p"
                className="text-md md:text-lg font-semibold  sm:pt-16 pt-8"
              >
                All steps completed - Edit Application
              </Typography>
            )}

            <div>
              {user !== undefined && trip_data !== undefined && (
                <Part1
                  edit1={edit1}
                  edit={edit}
                  setEdit={setEdit}
                  user={user}
                  token={token}
                  trip_data={trip_data[0]}
                  depature_and_return_data={depature_and_return_data[0]}
                  reviewer={reviewer}
                  preview={preview}
                />
              )}
            </div>

            <div>
              {depature_and_return_data !== undefined && (
                <Part3
                  edit1={edit1}
                  setEdit1={setEdit1}
                  user={user}
                  token={token}
                  depature_and_return_data={depature_and_return_data[0]}
                  reviewer={reviewer}
                  preview={preview}
                />
              )}
            </div>

            <div>
              {user.designation !== "CHAIRPERSON" &&
                acting_data !== undefined && (
                  <Part2A
                    edit={edit}
                    setEdit={setEdit}
                    user={user}
                    token={token}
                    trip_data={trip_data[0]}
                    acting_data={acting_data[0]}
                    reviewer={reviewer}
                    preview={preview}
                  />
                )}
            </div>

            <div>
              {delegate_data !== undefined && (
                <>
                  <Typography
                    component="h5"
                    variant="p"
                    className="px-4 pt-32 text-md font-semibold md:text-lg md:font-bold"
                  >
                    Costs and Size of Delegation
                  </Typography>
                  {delegate_data.map((delegate) => (
                    <Part4
                      edit={edit}
                      setEdit={setEdit}
                      key={delegate.delegate_id}
                      trip_data={trip_data[0]}
                      depature_and_return_data={depature_and_return_data[0]}
                      user={user}
                      token={token}
                      // job_group={delegate.job_group}
                      delegate_data={delegate}
                      setOpen={setOpen}
                      reviewer={reviewer}
                      preview={preview}
                    />
                  ))}

                  <Typography
                    component="h6"
                    variant="p"
                    className="px-4 pt-32 text-md font-semibold md:text-lg md:font-bold"
                  >
                    Add another delegate?
                  </Typography>

                  {delegate_data && (
                    <Part4
                      edit={edit}
                      setEdit={setEdit}
                      trip_data={trip_data[0]}
                      depature_and_return_data={depature_and_return_data[0]}
                      user={user}
                      token={token}
                      delegate_data_len={delegate_data}
                      setOpen={setOpen}
                    />
                  )}
                </>
              )}
            </div>
          </Paper>
        </Container>
      </div>
    )
  );
}
